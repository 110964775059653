import { parse, stringify } from "qs";
import React, { CSSProperties, FC, Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AntLayout, AntMenu } from "../../../../../../../../common";
import { findbyId } from "../../../../../page/api";
import { IHref } from "../../../../common/url-config";

interface ITitle {
    label?: string;
    hidden?: boolean;
    fontSize?: number;
    color?: string;
}

interface IMenu {
    label?: string;
    iconCls?: string;
    href?: IHref;
    children?: IMenu[]
}

interface IGeneral {
    color?: string;
    bgColor?: string;
    height?: string;
}

export interface GiaTriProps {
    title?: ITitle;
    menus?: IMenu[];
    general?: IGeneral;
}

{/* verified | unconfirmed | always | hidden */ }
const CLink: FC<{ href?: IHref; style?: CSSProperties }> = ({ href, children, style }) => {
    const { pathname, search, hash } = useLocation();
    const [to, setTo] = useState<string>("");
    const getPageById = async (id: number) => {
        const res = await findbyId(id);
        if (res.status) {
            const page = res.result;
            setTo(page?.page.url || "");
        }

    }
    useEffect(() => {
        switch (href?.type) {
            case "string":
                setTo(href.path || "");
                break;
            case "object":
                const fullPath = {
                    pathname: href.pathname || pathname,
                    search: stringify({ ...(href.extendSearch ? parse(search, { ignoreQueryPrefix: true }) : {}), ...parse(href.search || "") }),
                    hash: href.hash
                }
                setTo(`${fullPath.pathname}${fullPath.search ? `?${fullPath.search}` : ""}${fullPath.hash ? `#${fullPath.hash}` : ""}`);
                break;
            case "page":
                href.pageId && getPageById(href.pageId);
                break;
        }
    }, [href])
    return <Link to={to} target={href?.openNewTab ? "_blank" : undefined} replace={href?.action === "replace"} style={style}>
        {children}
    </Link>
}
const DesignPage = ({ giaTri = {} }: any) => {
    const location = useLocation();
    const history = useHistory();
    const { pathname, search, hash } = location;
    const _giaTri: GiaTriProps = giaTri || {};
    const { menus, general } = _giaTri
    const getStringUrl = (href?: IHref) => {
        if (href?.type === "object") {
            const fullPath = {
                pathname: href.pathname || pathname,
                search: stringify({ ...(href.extendSearch ? parse(search, { ignoreQueryPrefix: true }) : {}), ...parse(href.search || "") }),
                hash: href.hash
            }
            return `${fullPath.pathname}${fullPath.search ? `?${fullPath.search}` : ""}${fullPath.hash ? `#${fullPath.hash}` : ""}`
        }
        else {
            return href?.path || "#";
        }
    }
    return <Fragment>
        <AntLayout.Header
            style={{
                color: general?.color,
                backgroundColor: general?.bgColor,
                height: general?.height,
                lineHeight: general?.height,
            }}
        >
            {menus && menus?.length > 0 && <AntMenu mode="horizontal" className="header-menu-none-border-bottom" style={{ background: "none", textAlign: "right" }}>
                {menus?.map((menu, i) => menu.children && menu.children?.length > 0 ?
                    <AntMenu.SubMenu
                        title={<span style={{ color: general?.color }}>{menu.label}</span>}
                        icon={menu.iconCls && <i className={menu.iconCls} style={{ color: general?.color }} />}
                        style={{ color: general?.color, margin: "0 10px" }}
                    >
                        {
                            menu.children.map((m, j) => <AntMenu.Item
                                key={j}
                                icon={m.iconCls && <i className={m.iconCls} />}
                            >
                                <CLink href={m.href}>
                                    {m.label}
                                </CLink>
                            </AntMenu.Item>)
                        }
                    </AntMenu.SubMenu> :
                    <AntMenu.Item
                        key={i}
                        icon={menu.iconCls && <i className={menu.iconCls} />}
                        style={{ color: general?.color, margin: "0 10px" }}
                    >
                        <CLink
                            style={{ color: general?.color }}
                            href={menu.href}
                        >
                            {menu.label}
                        </CLink>
                    </AntMenu.Item>)}
            </AntMenu>}
        </AntLayout.Header>
    </Fragment >
}

export default DesignPage;