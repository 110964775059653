export default {
    tenCoSo: "Tên cơ sở",
    ngayBaoCao: "",
    kyBaoCao: "",
    data: {
        columns: [
            {
                title: "Chỉ tiêu",
                dataIndex: "c1",
                bold: true,
            },
            {
                title: "ĐVT",
                dataIndex: "c2",
                width: 100,
                align: "center",
                bold: true,
            },
            {
                title: "Mã số",
                dataIndex: "c3",
                width: 100,
                align: "center",
                bold: true,
            },
            {
                title: "Doanh nghiệp",
                dataIndex: "c4",
                width: 150,
                align: "left",
                bold: true,
            },
            {
                title: "Địa phương",
                dataIndex: "c5",
                width: 100,
                align: "left",
                bold: true,
            },
            {
                title: "Trung ương",
                dataIndex: "c6",
                width: 100,
                align: "left",
                bold: true,
            },
            {
                title: "Số vốn nước ngoài",
                dataIndex: "c7",
                width: 150,
                align: "left",
                bold: true,
            },
            {
                title: "Ước số thực hiện năm tới",
                dataIndex: "c8",
                width: 200,
                align: "left",
                bold: true,
            },
        ],
        body: [
            {
                c1: "1.Chi tiêu cho công tác thông tin, quảng bá, xúc tiến du lịch",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "Hoạt động quảng bá,xúc tiến du lịch",
                c2: "Triệu đồng",
                c3: "01",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Tổ chức sự kiện",
                c2: "Triệu đồng",
                c3: "02",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input", }
            },
            {
                c1: "Điều tra, khảo sát",
                c2: "Triệu đồng",
                c3: "03",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: " Khác",
                c2: "Triệu đồng",
                c3: "04",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "2. Số vốn đầu tư vào tài sản phục vụ cho hoạt động du lịch",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: " Đường nội bộ, cảnh quan trong khu, điểm du lịch",
                c2: "Triệu đồng",
                c3: "05",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Cơ sở lưu trú",
                c2: "Triệu đồng",
                c3: "06",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Cơ sở ăn uống",
                c2: "Triệu đồng",
                c3: "07",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Vận tải hành khách",
                c2: "Triệu đồng",
                c3: "08",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Cơ sở vui chơi, thể thao giải trí",
                c2: "Triệu đồng",
                c3: "09",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: " Cơ sở bán hàng hóa, vật phẩm lưu niệm",
                c2: "Triệu đồng",
                c3: "10",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Khác",
                c2: "Triệu đồng",
                c3: "11",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "3. Số dự án đầu tư du lịch mới",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "Số dự án đầu tư 100% vốn trong nước",
                c2: "Dự án",
                c3: "12",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Số dự án đầu tư có vốn nước ngoài",
                c2: "Dự án",
                c3: "13",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "4. Số vốn đầu tư dự án du lịch mới",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "Số vốn các dự án đầu tư trong nước",
                c2: "Triệu đồng",
                c3: "14",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
            {
                c1: "Số vốn các dự án đầu tư có vốn nước ngoài",
                c2: "Triệu đồng",
                c3: "15",
                colType: { c4: "input", c5: "input", c6: "input", c7: "input", c8: "input" }
            },
        ]
    }
}