import React, { FC, Fragment, useEffect, useState } from "react";
import { Input, InputProps } from "antd"
import { AntDropdown } from ".";
import { SketchPicker } from 'react-color';
interface InputColorProps extends Omit<InputProps, "onChange"> {
    onChange: (value?: string) => void
}
const AntInputColor: FC<InputColorProps> = ({ onChange, value, defaultValue, className, onFocus, ...props }) => {
    const color = (defaultValue || value) as string;
    const [visible, setVisible] = useState<boolean>(false);

    return <Fragment>
        <Input
            className={`${className || ""} ant-input-color-custom`}
            prefix={<AntDropdown
                trigger={["click"]}
                visible={visible}
                onVisibleChange={(v) => {
                    setVisible(v);
                }}
                overlay={<SketchPicker color={color} onChange={({ hex }) => onChange && onChange(hex)} />}
            >
                <span style={{ backgroundColor: color, width: 30, height: "100%", cursor: "pointer" }} />
            </AntDropdown>}
            value={color}
            onChange={(e) => onChange && onChange(e.target.value)}
            onFocus={(e => {
                setVisible(true)
                onFocus && onFocus(e)
            })}
            {...props}
        />
    </Fragment>
}
export default AntInputColor;