export default {
    tenCoSo: "Teen Cow sowr",
    ngayBaoCao: "",
    kyBaoCao: "",
    data: {
        columns: [
            {
                title: "Chỉ tiêu",
                dataIndex: "c1",
            },
            {
                title: "ĐVT",
                dataIndex: "c2",
                width: 100,
                align: "center"
            },
            {
                title: "Mã số",
                dataIndex: "c3",
                width: 150,
                align: "center"
            },
            {
                title: "Số thực hiện kỳ báo cáo tháng",
                dataIndex: "c4",
                width: 150,
                align: "center"
            },
            {
                title: "Số ước thực hiện kỳ tới tháng 1 năm sau",
                dataIndex: "c5",
                width: 150,
                align: "center"
            },
            {
                title: "Số ước thực hiện cả năm (nhập số liệu trước 10/10 hàng năm)",
                dataIndex: "c6",
                width: 150,
                align: "center"
            }
        ],
        body: [
            {
                c1: "1. Khách do cơ sở lưu trú du lịch phục vụ(khách nghỉ qua đêm tại cơ sở)",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "1.1. Số lượt khách phục vụ",
                c2: "Lượt",
                c3: "01",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-2", "c4-3"],
                    c5: ["c5-2", "c5-3"],
                    c6: ["c6-2", "c6-3"]
                }
            },
            {
                c1: "- Khách quốc tế đến",
                c2: "Lượt",
                c3: "02",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khách du lịch nội địa",
                c2: "Lượt",
                c3: "03",
                colType: { c4: "input", c5: "input", c6: "input" }
            },///
            {
                c1: "1.2. Tổng số ngày lưu trú của Khách ( số đêm lưu trú )",
                c2: "Đêm",
                c3: "04",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-5", "c4-6",],
                    c5: ["c5-5", "c5-6"],
                    c6: ["c6-5", "c6-6"]
                }
            },
            {
                c1: "- Khách quốc tế đến",
                c2: "Đêm",
                c3: "05",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khách du lịch nội địa",
                c2: "Đêm",
                c3: "06",
                colType: { c4: "input", c5: "input", c6: "input" }
            },///
            {
                c1: "2. Công suất sử dụng phòng (07)=(08)/(09)x100",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "2.1. Số lượt khách phục vụ",
                c2: "%",
                c3: "07",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-9", "c4-10"],
                    c5: ["c5-9", "c5-10"],
                    c6: ["c6-9", "c6-10"]
                }
            },
            {
                c1: "Tổng số ngày phòng đã bán",
                c2: "Đêm",
                c3: "08",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "Tổng số ngày phòng sẵn có để bán",
                c2: "Đêm",
                c3: "09",
                colType: { c4: "input", c5: "input", c6: "input" }
            },//
            {
                c1: "3.Doanh thu",
                c2: "Triệu đồng",
                c3: "",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-12", "c4-13", "c4-14"],
                    c5: ["c5-12", "c5-13", "c5-14"],
                    c6: ["c6-12", "c6-13", "c6-14"]
                }
            },
            {
                c1: "Dịch vụ lưu trú",
                c2: "Triệu đồng",
                c3: "10",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "Dịch vụ ăn uống",
                c2: "Triệu đồng",
                c3: "11",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "Thu khác",
                c2: "Triệu đồng",
                c3: "12",
                colType: { c4: "input", c5: "input", c6: "input" }
            },//
            {
                c1: "4. Số lao động trực tiếp hoạt động du lịch",
                c2: "Người",
                c3: "13",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-16", "c4-17", "c4-18"],
                    c5: ["c5-16", "c5-17", "c5-18"],
                    c6: ["c6-16", "c6-17", "c6-18"]
                }
            },
            {
                c1: "Ăn uống",
                c2: "Người",
                c3: "14",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "Lưu trú",
                c2: "Người",
                c3: "15",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- khác",
                c2: "Người",
                c3: "16",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            //
            {
                c1: "5. Số lượng khách của 10 thị trường hàng đầu (theo quốc tịch khách quốc tế đến)",
                c2: "Người",
                c3: "",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-20", "c4-21", "c4-22", "c4-23", "c4-24", "c4-25", "c4-26", "c4-27", "c4-28", "c4-29"],
                    c5: ["c5-20", "c5-21", "c5-22", "c5-23", "c5-24", "c5-25", "c5-26", "c5-27", "c5-28", "c5-29"],
                    c6: ["c6-20", "c6-21", "c6-22", "c6-23", "c6-24", "c6-25", "c6-26", "c6-27", "c6-28", "c6-29"]
                }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "Doanh thu",
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "6. Nộp ngân sách",
                c2: "Triệu đồng",
                bold: true,
                c3: "28",
                colType: { c4: "input" }
            },
            //end///////
        ]
    }
}