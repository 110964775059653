import { useEffect } from 'react';
import { useLocation, } from "react-router-dom";
import { parse } from "qs";
import { useMap } from "react-leaflet";
const MapView = () => {
    const map = useMap();
    const { search } = useLocation();
    const qs = parse(search, { ignoreQueryPrefix: true });
    const zoom = !isNaN(parseFloat(qs.zoom as string)) ? parseFloat(qs.zoom as string) : 13;
    const lat: number | undefined = qs.lat ? parseFloat(qs.lat as string) : 14;
    const lng: number | undefined = qs.lng ? parseFloat(qs.lng as string) : 108;


    useEffect(() => {
        const _lat = typeof lat === "number" && lat !== map.getCenter().lat ? lat : map.getCenter().lat;
        const _lng = typeof lng === "number" && lng !== map.getCenter().lng ? lng : map.getCenter().lng;
        const _zoom = typeof zoom === "number" && zoom !== map.getZoom() ? zoom : map.getZoom();
        map.setView({ lat: _lat, lng: _lng }, _zoom);
    }, [lat, lng, zoom])

    return null;
};

export default MapView;