import { parse } from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { create, getDataReferenceByDataIdWithAvatarByDataId } from '../../../../../../data-form-builder/api';
import { AntAvatar, AntButton, AntComment, AntDivider, AntEmpty, AntList, AntRate, useList } from '../../../../../../../../common';

const Rate = ({ name_database, itemKeyDanhGia, df, page, check, formData, setTongDanhGia, folder_name }) => {
    const [sl, setSL] = useState(0)
    const [rate, setRate] = useState(false)
    const [r, setR] = useState(0)
    const [data, setData] = useState([])
    const username = useSelector((state) => state.auth?.accountCurrent?.name)
    const auth = useSelector((state) => state.auth?.invalid)
    const location = useLocation();
    const list = useList();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const keyUrl = check === "hardConfig" ? formData?.hardConfig : (formData?.dynamicConfig && qs[formData?.dynamicConfig]);
    const localizations = useSelector((state) => state.config.localizations.data).map((el) => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";
    const local = [...localizations].find((el) => { if (el.default) return el; })
    const [pages, setPages] = useState(1)
    const rateBaiViet = async (e) => {
        setR(e)
        const data = {
            client: "portal",
            tinh_trang: 1,
            created_date: new Date(),
            nguoi_tao: username,
            trang_thai: 1,
            form_id: itemKeyDanhGia?.formDanhGia?.toString(),
            localizations: [local],
            folder_name,
            name_database,
            field_text: [itemKeyDanhGia?.danhGia?.toString()],
            data_formbuilder: {
                [`${localizationSearch}_${itemKeyDanhGia?.baiViet}`]: df,
                [`${itemKeyDanhGia?.danhGia}`]: e.toString(),
                [`${localizationSearch}_${itemKeyDanhGia?.danhGia}`]: e.toString(),
                [`${itemKeyDanhGia?.baiViet}`]: df
            }
        }
        list.onLoading()
        const res = await create(data);
        if (res.status) {
            setRate(true);
            setTongDanhGia(true)
            list.onSuccess();
        } else {
            list.onError(res.msg)
        }
    }
    const onclick = () => {
        setPages(pages + 1)
    }
    useEffect(() => {
        itemKeyDanhGia?.formDanhGia && localizationSearch && onFetchAllDataFormRate();
    }, [itemKeyDanhGia?.formDanhGia, pages, page, localizationSearch, auth])

    const onFetchAllDataFormRate = async () => {
        let res = {}
        let data = {
            dataId: keyUrl,
            formId: itemKeyDanhGia?.formDanhGia,
            formFiledId: itemKeyDanhGia?.baiViet,
            pagination: {
                currentPage: pages || 1,
                pageSize: page || 5,
            },
            localization: localizationSearch,
            trangThai: "1",
            sort: {}
        }
        list.onLoading();
        res = await getDataReferenceByDataIdWithAvatarByDataId(data);
        if (res.status) {
            setData(res.result.data);
            setSL(res.result.pagination.total)
            let account = {}
            account = (res.result.data.find((item) => item.nguoi_tao === username))
            if (!auth && account) {
                setR(account.data_formbuilder[`${itemKeyDanhGia?.danhGia}`])
                setRate(true)
            } else {
                setR(0)
                setRate(false)
            }
            list.onSuccess();
        } else {
            list.onError(res.msg);
        }
    }
    return <Fragment>
        {!auth && <Fragment>
            <div style={{ textAlign: "center" }}>
                <AntRate disabled={rate} allowHalf value={Number(r)} onChange={(e) => rateBaiViet(e)} style={{ fontSize: "30px" }} />
                <br />
                <h2> Đánh giá của bạn</h2>
            </div>
            <AntDivider style={{ marginTop: "-5px" }} />
        </Fragment>}
        {data.length > 0 ? < Fragment >
            <AntList
                style={{ backgroundColor: "#FFFFFF" }}
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (<AntList.Item>
                    <AntComment
                        avatar={
                            <AntAvatar
                                src={item.avatar || `/static/img/avatar-default.png`}
                                alt={item.nguoi_tao}
                            />
                        }
                        author={item.nguoi_tao}
                        datetime={new Date(item.created_date).toLocaleString()}
                        content={<AntRate disabled allowHalf value={Number(item?.data_formbuilder[`${itemKeyDanhGia?.danhGia}`])} />}
                    />
                </AntList.Item>
                )}
            />
            {sl > 5 && (page ? page * pages.rate : 5 * pages.rate) < Number(list.getPagination()?.total) && <AntList.Item>
                <AntButton type="link" onClick={() => onclick()}>Xem thêm các đánh giá </AntButton>
            </AntList.Item>}
        </Fragment>
            : <AntEmpty description="Không có đánh giá nào" />
        }
    </Fragment>
};
export default Rate;