import React, { Fragment } from 'react';
import { AntAlert } from '../../../../../../../../common';

const DesignSider = () => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-envelope-o fa-2x" />}
            message="góp ý"
        />
    </Fragment>
};
export default DesignSider;