import React, { CSSProperties, FC } from "react";
import { DesignSiderProps } from "./../"

interface StyleProps {
    container: CSSProperties;
    header: CSSProperties;
    footer: CSSProperties;
    content: CSSProperties;
    row: CSSProperties;
}
const style: StyleProps = {
    container: {
        height: 100,
    },
    header: {
        margin: "0 2px",
        height: 23,
        background: "#f5f5f5",
        marginBottom: 2
    },
    content: {
        height: 50,
        display: "flex",
        marginBottom: 2
    },
    footer: {
        margin: "0 2px",
        height: 23,
        background: "#f5f5f5",
    },
    row: {
        margin: 2,
        flex: 1,
        background: "#f5f5f5",
    }
}
const DesignSider: FC<DesignSiderProps> = () => {
    return <div style={style.container}>
        <div style={style.header}></div>
        <div style={style.content}>
            <div style={style.row}></div>
            <div style={style.row}></div>
        </div>
        <div style={style.header}></div>
    </div>
}

export default DesignSider;
