
import { parse } from 'qs';
import React, { Fragment, useState } from 'react';
import Modal from './modal';
import Create from './create';
import Edit from './edit';
import List from './list';
import { useLocation } from 'react-router';
import { useList, AntTabs } from '../../../../../../../../common';
import baocaocosoluutru from '../../../../../../../system/home/baocaocosoluutru';
import baocaodautu from '../../../../../../../system/home/baocaodautu';
import baocaodiemdulich from '../../../../../../../system/home/baocaodiemdulich';
import baocaoluhanh from '../../../../../../../system/home/baocaoluhanh';
import baocaovanchuyen from '../../../../../../../system/home/baocaovanchuyen';
import { useSelector } from 'react-redux';

interface ItemKeys {
    ngayLapBaoCao: number,
    doanhNghiep: number,
    coSoLuuTru: number,
    ketQua: number,
    trangThai: number,
}
interface FormBaoCao {
    formId: number,
    idBaoCao: number,
}
interface ItemKeyDN {
    formDN: number,
    ten: number,
    trangThai: number,
}

export interface GiaTriProps {
    itemKeys: ItemKeys,
    formBaoCao: FormBaoCao,
    itemKeyDN: ItemKeyDN,
}

export const optionsBaoCao = [
    { value: "baocaocosoluutru", label: "Báo cáo cơ sở lưu trú", temp: baocaocosoluutru },
    { value: "baocaodautu", label: "Báo cáo đầu tư", temp: baocaodautu },
    { value: "baocaodiemdulich", label: "Báo cáo điểm du lịch", temp: baocaodiemdulich },
    { value: "baocaoluhanh", label: "Báo cáo lữ hành", temp: baocaoluhanh },
    { value: "baocaovanchuyen", label: "Báo cáo vận chuyển", temp: baocaovanchuyen },
]

const BaoCaoKetQua = ({ giaTri }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { itemKeys, formBaoCao } = _giaTri;
    const list = useList();
    const location = useLocation();
    const qs = parse(location.search, { ignoreQueryPrefix: true }) as { [key: string]: string };
    const [value, setValue] = useState({});
    const maDoanhNghiep = useSelector((state: any) => state?.auth?.accountCurrent?.maDoanhNghiep) || "";

    const activeKey = (): string => {
        switch (qs.tab) {
            case "edit":
                return "edit";
            case "create":
                return "create";
            case "view":
                return "view";
            default:
                return "list";
        }
    }

    return <Fragment>
        <AntTabs
            tabBarStyle={{ display: "none" }}
            activeKey={activeKey()}
            destroyInactiveTabPane
        >
            <AntTabs.TabPane key="list">
                <List list={list} giaTri={giaTri} setValue={setValue} />
            </AntTabs.TabPane>
            <AntTabs.TabPane key="create">
                <Create list={list} giaTri={giaTri} maDoanhNghiep={maDoanhNghiep} />
            </AntTabs.TabPane>
            <AntTabs.TabPane key="edit">
                <Edit list={list} giaTri={giaTri} value={value} maDoanhNghiep={maDoanhNghiep} />
            </AntTabs.TabPane>
            <AntTabs.TabPane key="view">
                <Modal itemKeys={itemKeys} formBaoCao={formBaoCao} />
            </AntTabs.TabPane>
        </AntTabs>

    </Fragment>

};

export default BaoCaoKetQua;