import { RouteProps } from "./../";

const routes: Array<RouteProps> = [
  {
    path: "bao-cao-tong-hop",
    children: [
      {
        path: "01-h-dl-cslt",
        pageTitle: "01.H/DL-CSLT",
        componentPath: "components/bao-cao-tong-hop/bc-01",
      },
      {
        path: "02-h-dl-dnlh",
        pageTitle: "02.H/DL-DNLH",
        componentPath: "components/bao-cao-tong-hop/bc-02",
      },
      {
        path: "03-h-dl-kddl",
        pageTitle: "03.H/DL-KĐDL",
        componentPath: "components/bao-cao-tong-hop/bc-03",
      },
    ],
  },
];
export default routes;
