import React, { FC, Fragment } from "react";
import ContainerPreview from "../../container.preview";
import { ComponentProps } from "..";
import { AntCol, AntRow } from "../../../../../../../../common";

const PreviewPage: FC<ComponentProps> = ({ page }) => {
    return <Fragment>
        <AntRow >
            <AntCol span={24}>
                <ContainerPreview index={0} key="row1" page={page} />
            </AntCol>
        </AntRow>
        <AntRow className="mt-10">
            <AntCol span={24}>
                <ContainerPreview index={1} key="row2" page={page} />
            </AntCol>
        </AntRow>
    </Fragment>
}
export default PreviewPage;
