import React, { CSSProperties, Fragment, useEffect } from 'react';
import { AntCol, AntLayout, AntCard, CommonList, useList, AntTag } from '../../../../../../../../common';
import { CommonButtonAction } from '../../../../../../../../common/c-action'
import { getAllDataFormbuilder } from '../../../../../../data-form-builder/api'
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
const contentStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: "#FFFFFF"
};
const DesignPage = ({ giaTri }: any) => {
    const list = useList();
    return <Fragment>
        <AntLayout style={contentStyle}>
            <AntCard>
                <CommonList
                    // list={list}
                    columns={[
                        {
                            title: "Xuất bản",
                            align: "center",
                            width: 100,
                            render: (_, record) => {
                                return (
                                    <AntTag
                                        color={
                                            record.trang_thai === 1
                                                ? "green"
                                                : "red"
                                        }
                                        key={record.id}
                                    >
                                        {record.trang_thai === 1
                                            ? "Đã Duyệt"
                                            : "Chưa Duyệt"}
                                    </AntTag>
                                );
                            },
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_date",
                            width: 100,
                            align: "center",
                            ellipsis: true,
                            render: (value) => moment(value).isValid() && moment(value).format("DD/MM/YYYY")
                        },
                        {
                            title: "Ngày cập nhật",
                            dataIndex: "last_modified_date",
                            width: 120,
                            align: "center",
                            ellipsis: true,
                            render: (value) => moment(value).isValid() && moment(value).format("DD/MM/YYYY"),
                        },
                        {
                            title: "Người cập nhật",
                            dataIndex: "last_modified_by",
                            width: 130,
                            align: "center",
                            ellipsis: true,
                            render: (_, record) => record.last_modified_by || record.created_by
                        },
                        {
                            title: "ID",
                            dataIndex: "id",
                            width: 220,
                            align: "center",
                            ellipsis: true,
                        },
                    ]}
                />
            </AntCard>
        </AntLayout>
    </Fragment>
};

export default DesignPage;