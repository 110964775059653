import React, { Fragment } from "react";
import { GiaTriProps } from "./preview.page";
import {
  AntSelect,
  AntRow,
  AntList,
  AntCol,
  AntSkeleton,
} from "../../../../../../../../common";

const DesignPage = ({ giaTri = {} }: any) => {
  const _giaTri: GiaTriProps = giaTri || {};
  const { name } = _giaTri;
  return (
    <Fragment>
      {/* <div classNameName="py-3 px-10" style={{ border: "1px dashed #ccc" }}>
        {name || "Modal"}
      </div> */}
      <AntRow>
        <AntCol span={15}>
          <AntSkeleton paragraph={{ rows: 2 }} loading />
        </AntCol>
        <AntCol span={3}></AntCol>
        <AntCol span={6}>
          <AntSkeleton paragraph={{ rows: 0 }} loading />
        </AntCol>
      </AntRow>
      <AntRow>
        <AntCol span={15}>
          <AntSkeleton paragraph={{ rows: 2 }} loading />
        </AntCol>
        <AntCol span={3}></AntCol>
        <AntCol span={6}>
          <AntSkeleton paragraph={{ rows: 0 }} loading />
        </AntCol>
      </AntRow>
      <AntRow>
        <AntCol span={15}>
          <AntSkeleton paragraph={{ rows: 2 }} loading />
        </AntCol>
        <AntCol span={3}></AntCol>
        <AntCol span={6}>
          <AntSkeleton paragraph={{ rows: 0 }} loading />
        </AntCol>
      </AntRow>
    </Fragment>
  );
};

export default DesignPage;
