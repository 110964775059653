import { RouteProps } from "./../";

const routes: Array<RouteProps> = [
  {
    path: "bao-cao-doanh-nghiep",
    children: [
      {
        path: "bc-01-cslt",
        pageTitle: "01.H/DL-CSLT",
        children: [
          {
            componentPath: "components/bao-cao-doanh-nghiep/bc-01",
          },
          {
            path: ":mabaocao/:id",
            componentPath: "components/bao-cao-doanh-nghiep/detail-baocao",
          },
          {
            path: "chi-tiet-ky-bao-cao/:formIdbc/:id",
            componentPath: "components/bao-cao-doanh-nghiep/baocaochitiet-01",
          },
        ],
      },
      {
        path: "bc-02-dnlh",
        pageTitle: "02.H/DL-DNLH",
        children: [
          {
            componentPath: "components/bao-cao-doanh-nghiep/bc-02",
          },
          {
            path: ":mabaocao/:id",
            componentPath: "components/bao-cao-doanh-nghiep/detail-baocao",
          },
          {
            path: "chi-tiet-ky-bao-cao/:formIdbc/:id",
            componentPath: "components/bao-cao-doanh-nghiep/baocaochitiet-02",
          },
        ],
      },
      {
        path: "bc-03-kddl",
        pageTitle: "03.H/DL-KĐDL",
        children: [
          {
            componentPath: "components/bao-cao-doanh-nghiep/bc-03",
          },
          {
            path: ":mabaocao/:id",
            componentPath: "components/bao-cao-doanh-nghiep/detail-baocao",
          },
          {
            path: "chi-tiet-ky-bao-cao/:formIdbc/:id",
            componentPath: "components/bao-cao-doanh-nghiep/baocaochitiet-03",
          },
        ],
      },
    ],
  },
];
export default routes;
