import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { parse, stringify } from 'qs';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AntCard, AntMessage, CommonList } from '../../../../../../../../common';
import { CommonListAction } from '../../../../../../../../common/c-action';
import { getAllDataFormbuilder, _delete } from '../../../../../../data-form-builder/api';

interface ItemKeys {
    ngayLapBaoCao: number,
    doanhNghiep: number,
    coSoLuuTru: number,
    ketQua: number,
    trangThai: number,
}
interface FormBaoCao {
    formId: number,
    idBaoCao: number,
}
interface ItemKeyDN {
    formDN: number,
    ten: number,
    trangThai: number,
    ma: number
}

export interface GiaTriProps {
    itemKeys: ItemKeys,
    formBaoCao: FormBaoCao,
    itemKeyDN: ItemKeyDN,
}

const List = ({ list, giaTri }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const history = useHistory();
    const location = useLocation();
    const { itemKeys, formBaoCao } = _giaTri;
    const qs = parse(location.search, { ignoreQueryPrefix: true }) as { [key: string]: string };
    const page = qs.page && !isNaN(parseInt(qs.page, 0)) ? parseInt(qs.page, 0) : 1;
    const pageSize = qs.pageSize && !isNaN(parseInt(qs.pageSize, 0)) ? parseInt(qs.pageSize, 0) : 10;
    const localizations = useSelector((state: any) => state.config.localizations.data).map((el: any) => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";
    const reload = qs.load ? parseInt(qs.load, 0) : 0;

    useEffect(() => {
        formBaoCao?.formId && getAllData()
    }, [formBaoCao?.formId, page, pageSize, reload])

    const getAllData = async () => {
        const data = {
            formId: formBaoCao.formId,
            localization: localizationSearch,
            pagination: {
                currentPage: page,
                pageSize: pageSize
            },
            search: {},
            trangThai: "-1"
        }

        list.onLoading();
        const res = await getAllDataFormbuilder(data)
        if (res.status) {
            list.setData(res.result.data);
            list.setPagination({
                pageSize: pageSize,
                current: page,
                total: res?.result.pagination.total,
            })
            list.onSuccess();
        } else {
            list.onError(res.msg)
        }
    }

    const getData = () => {
        return list.getData().map((item: any) => ({
            [`${itemKeys.ngayLapBaoCao}`]: item.data_formbuilder[`${itemKeys.ngayLapBaoCao}`] ? moment(item.data_formbuilder[`${itemKeys.ngayLapBaoCao}`].split('T')[0]).format("DD-MM-YYYY") : undefined,
            [`${itemKeys.ketQua}`]: item.data_formbuilder[`${itemKeys.ketQua}`] ? item.data_formbuilder[`${itemKeys.ketQua}`] : undefined,
            [`uni_hidden_select_vi_${itemKeys.trangThai}`]: item.data_formbuilder[`${itemKeys.trangThai}`] ? item.data_formbuilder[`uni_hidden_select_vi_${itemKeys.trangThai}`] : undefined,
            id: item.id
        }))
    }

    const del = async (data: any) => {
        const infor = {
            ids: data
        };
        const formId = formBaoCao.formId
        list.onLoading();
        const res = await _delete(infor, formId);
        if (res.status) {
            AntMessage.success(res.msg)
            list.setSelectedRows([]);
            history.replace({ search: stringify({ ...qs, load: reload + 1 }) })
            list.onSuccess();
        } else {
            list.onError(res.msg)
            AntMessage.error(res.msg)
        }
    };

    return <Fragment>
        <AntCard>
            <CommonList
                actions={[
                    {
                        icon: <PlusOutlined />,
                        children: "Thêm mới",
                        type: "primary",
                        onClick: () => { history.push({ search: stringify({ ...qs, tab: "create" }) }) }
                    },
                    {
                        icon: <DeleteOutlined />,
                        danger: true,
                        disabled: list.getSelectedRows().length === 0,
                        children: `Xóa${list.getSelectedRows().length > 0 ? `(${list.getSelectedRows().length})` : "(0)"}`,
                        type: "primary",
                        action_confirm: {
                            title: "Bạn chắc chắn muốn xóa?",
                            onConfirm: () => del(list.getSelectedRows().map((value: any) => value.id))
                        }

                    }
                ]}
                columns={
                    [
                        {
                            title: "Ngày lập báo cáo",
                            dataIndex: itemKeys.ngayLapBaoCao,
                            align: "center",
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: `uni_hidden_select_vi_${itemKeys.trangThai}`,
                            align: "center"
                        },
                        {
                            title: "Thao tác",
                            width: 200,
                            align: "center",
                            render: (_, record) => {
                                return <Fragment>
                                    <CommonListAction
                                        type="ghost"
                                        shape="circle"
                                        action_tooltip="Xem báo cáo"
                                        icon={<i className="fa fa-eye" />}
                                        onClick={() => history.push({ search: stringify({ ...qs, tab: "view", id: record.id }) })}
                                    />
                                    <CommonListAction
                                        type='primary'
                                        shape="circle"
                                        action_tooltip="Sửa"
                                        icon={<i className="fa fa-pencil-square-o" />}
                                        onClick={() => history.push({ search: stringify({ ...qs, tab: "edit", id: record.id }) })}

                                    />
                                    <CommonListAction
                                        type="primary"
                                        danger
                                        shape="circle"
                                        action_tooltip="Xóa"
                                        action_confirm={
                                            {
                                                placement: "bottomRight",
                                                onConfirm: () => {
                                                    del([record.id])
                                                },
                                                title: 'Chắc chắn xóa?'
                                            }
                                        }
                                        icon={<i className="fa fa-trash" />}
                                    />
                                </Fragment>
                            }

                        }
                    ]
                }
                list={list}
                dataSource={getData()}
                onChange={({ pageSize, current }, filter, sorter, e) => {
                    const _sorter = sorter.filter(item => item.order);
                    history.push({
                        search: stringify({
                            ...qs,
                            ...(current !== page ? { page: current } : {}),
                            ...(pageSize !== pageSize ? { page_size: pageSize } : {}),
                            ...(_sorter.length > 0 ? { sort: _sorter.map(item => `${item.field} ${item._order}`).toString() } : { sort: undefined })
                        })
                    })

                }}
            />
        </AntCard>
    </Fragment>
};

export default List;