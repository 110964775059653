import React, { Fragment } from "react";
import { AntCard, AntCol, AntImage, AntInput, AntList, AntRow, AntSelect, AntSkeleton, AntTabs } from "../../../../../../../../common";

const list = [
    { id: 1 },
    { id: 2 },
    { id: 3 }
]

const DesignPage = ({ giaTri = {} }: any) => {
    return <Fragment>
        <AntCard
            size="small"
            className="design-page-news mb-10"
        >
            <AntRow>
                <AntCol md={{ span: 16 }} sm={{ span: 16 }} xs={{ span: 24 }}>
                    <AntCard
                        cover={<div style={{ background: "#f5f5f5", textAlign: 'center', height: 420 }} >
                            <AntImage
                                style={{
                                    maxWidth: 200,
                                    maxHeight: 200
                                }}
                                src="error"
                            />
                        </div>}
                        bordered={false}
                        bodyStyle={{ padding: 0 }}
                    >
                    </AntCard>
                </AntCol>
                <AntCol md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 24 }} className="px-5">
                    <AntRow>
                        <AntTabs defaultActiveKey="1">
                            <AntTabs.TabPane tab={<span><AntSkeleton.Button active={false} size="small" shape="square" /></span>} key="1"></AntTabs.TabPane>
                            <AntTabs.TabPane tab={<span><AntSkeleton.Button active={false} size="small" shape="square" /></span>} key="2"></AntTabs.TabPane>
                            <AntTabs.TabPane tab={<span><AntSkeleton.Button active={false} size="small" shape="square" /></span>} key="3"></AntTabs.TabPane>
                        </AntTabs>
                    </AntRow>
                    {/*<AntRow className="ml-3 mb-10 mt-10">
                        <AntInput.Search disabled />
                    </AntRow>*/}
                    <AntRow className="ml-3 mb-10 mt-10">
                        <AntSelect disabled style={{ width: '100%' }} />
                    </AntRow>
                    <AntRow>
                        <AntList
                            dataSource={list}
                            itemLayout="horizontal"
                            renderItem={item => <AntList.Item>
                                <AntSkeleton
                                    loading
                                    avatar={{
                                        shape: "square"
                                    }}
                                />
                            </AntList.Item>}
                            footer={undefined}
                        />
                    </AntRow>
                </AntCol>
            </AntRow>
        </AntCard>
    </Fragment >
}

export default DesignPage;