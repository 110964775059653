import moment from 'moment';
import { useEffect, useState } from 'react';
import { uuid } from '..';

interface StateProps {
    loading: boolean;
    successed: boolean;
    failed: boolean;
    message?: string;
    data: any;
}

interface UseEditProps extends StateProps {
    onStart: () => void;
    onFinish: (data: any, delay?: number) => void;
    onError: (message?: string, delay?: number) => void;
}
interface IProps {
    key?: string | number;
    delay?: number
}
// let errorTimeout: any = null, successTimeout: any = null;

const useEdit = (useEditData?: IProps): UseEditProps => {
    let errorTimeout: any = null, successTimeout: any = null;
    const [state, setState] = useState<StateProps>({
        loading: false,
        successed: false,
        failed: false,
        message: "",
        data: null,
    })

    return {
        loading: state.loading,
        successed: state.successed,
        failed: state.failed,
        message: state.message,
        data: state.data,
        onStart: () => {
            successTimeout && clearTimeout(successTimeout);
            errorTimeout && clearTimeout(errorTimeout);
            setState((state: StateProps) => ({ ...state, status: 0, loading: true, successed: false, failed: false }))
        },
        onFinish: (data: any, _delay = 0) => {
            const delay = useEditData?.delay || _delay || 0;
            errorTimeout && clearTimeout(errorTimeout);
            successTimeout && clearTimeout(successTimeout);
            successTimeout = setTimeout(() => {
                setState((state: StateProps) => ({ ...state, status: 1, data, loading: false, successed: true, failed: false }))
            }, delay)
        },
        onError: (message = "Thao tác lỗi", _delay = 0) => {
            const delay = useEditData?.delay || _delay || 0;
            successTimeout && clearTimeout(successTimeout);
            errorTimeout && clearTimeout(errorTimeout);
            errorTimeout = setTimeout(() => {
                setState((state: StateProps) => ({ ...state, status: -1, message, loading: false, successed: false, failed: true, data: null }))
            }, delay)
        },
    };
}

export default useEdit;