import React, { Fragment,FC } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-home menu-icon-left  fa-2x" />}
            message={title||"Đơn đặt hàng"}
        />
    </Fragment>
}
export default DesigSider;