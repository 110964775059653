import { TokenProps } from "../common";
import { AbortController as AbortControllerProps } from "abort-controller";
import { message } from "antd";
import { ReactNode } from "react";
import { fetchLoginSSO } from "./.login";
export interface LoginDataProps {
	username: string;
	password: string
	remember?: boolean;
}
// export const HOST_NAME = "http://192.168.2.201";
// export const HOST_NAME = "http://cmsdulich.unitech.vn";
// export const HOST_NAME = "http://192.168.2.162";
// export const HOST_NAME = "http://192.168.5.146";
 export const HOST_NAME = "https://quantri.gialaitourism.vn";
//export const HOST_NAME = "http://nahangtourism.vn:81/";

export const urlLogin = "/dang-nhap.html";
export const urlLoginExpiredToken = `${urlLogin}?expired_token=1`

export interface PaginationProps {
	currentPage: number;
	pageSize: number;
	total: number;
}
export interface ResponseProps {
	error: boolean;
	statusCode: number;
	status?: boolean;
	result?: any;
	msg?: string;
	pagination?: PaginationProps,
}
export interface ResponseDownloadProps extends ResponseProps {
	header?: Headers;
	blob?: () => Blob;
}

export interface APIProps {
	url?: string;
	data?: any;
	formData?: FormData;
	auth?: boolean;
	authValue?: string;
	showLoading?: boolean;
	token?: string,
	controller?: AbortControllerProps,
	callback?: (res: ResponseProps) => void;
	showMsg?: boolean;
	loadingMessage?: ReactNode;
	hostName?: string;
}

export interface LoginSSODataProps extends APIProps { code: string, session_state: string }
export interface LoginSSOProps extends APIProps { }

export interface GetProps extends APIProps { }
export interface PostProps extends APIProps { }
export interface PutProps extends APIProps { }
export interface DelProps extends APIProps { }
export interface DownloadProps extends GetProps {
	responseType?: "onlyFileInfo"
}

export interface ResponseLoginProps extends ResponseProps {
	token?: TokenProps;
}

export interface LoginPostProps extends Omit<APIProps, "data"> {
	data: LoginDataProps
}
export interface RefreshPostProps extends Omit<APIProps, "callback"> {
	callback: (props: GetProps | PostProps | PutProps | DelProps) => Promise<any>
}


export const startLoad = (loadingMessage?: ReactNode) => {
	const _loading = document.body.getAttribute("loading");
	const loading: number = _loading && !isNaN(parseInt(_loading)) ? parseInt(_loading, 0) + 1 : 1;
	message.open({
		key: "page-loading",
		type: "loading",
		content: loadingMessage || "Loading...",
		duration: 0
	})
	document.body.setAttribute("loading", `${loading}`);
}

export const endLoad = () => {
	const _loading = document.body.getAttribute("loading");
	const loading: number = _loading && !isNaN(parseInt(_loading)) && parseInt(_loading) > 0 ? parseInt(_loading, 0) - 1 : 0;
	loading === 0 && message.open({
		key: "page-loading",
		type: "loading",
		content: "Loading...",
		duration: 0.1,
	})
	document.body.setAttribute("loading", `${loading}`);
}

export { fetchLoginSSO };
export { default as get } from "./.get";
export { default as post } from "./.post";
export { default as put } from "./.put";
export { default as del } from "./.del";
export { default as login } from "./.login";
export { default as download } from "./.download";
export { default as downloadPost } from "./.download-post";