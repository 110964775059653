import { ActionProps as CActionProps } from "./../"
import { getAuth, uuid as _uuid } from "../../common";
import { AccountCurrent, removeAuth, TokenProps } from "../../common/c-auth";
import { ItemProps as PermissionItemProps } from "../../components/system/permission/api";
import { orderBy } from "lodash";
import { ItemProps as AccountItemProps } from "./../../components/system/account/api"
export interface AuthState {
    uuid: string;
    invalid: boolean;
    token?: TokenProps;
    accountCurrent?: AccountItemProps;
    permission?: PermissionItemProps[]
}
const auth = getAuth();
const uuid = _uuid();
const stateDefault: AuthState = {
    uuid,
    invalid: auth.token ? false : true,
    token: auth,
    permission: []
}
interface ActionProps extends CActionProps {
    callback: (input: { data: AuthState, callback?: () => void }) => void
}

const orderPermission = (permission: PermissionItemProps[]): PermissionItemProps[] => {
    return orderBy(permission, "sort", "asc").map(item => ({ ...item, children: orderPermission(item.children || []) }))
}

const updatePermission = (permissions: PermissionItemProps[], permission: PermissionItemProps): PermissionItemProps[] => {
    return orderBy(permissions, "sort", "asc").map(item => ({ ...(item.id === permission.id ? permission : item), children: orderPermission(item.children || []) }))
}
export default (state: AuthState = stateDefault, action: ActionProps): AuthState => {
    const type = action.type;
    const payload = action.payload as {
        accountCurrent?: any;
        token?: TokenProps
    };
    const { callback } = action;
    switch (type) {
        case "AUTH_PERMISSION_UPDATE":
            const permissionUpdate = payload as PermissionItemProps;
            return { ...state, permission: updatePermission(state.permission || [], permissionUpdate) };
        case "AUTH_PERMISSION_LOAD":
            const permission = payload as PermissionItemProps[];
            return { ...state, permission: orderPermission(permission) };
        case "LOGIN_SUCCESS":
            const LOGIN_SUCCESS_DATA: AuthState = { invalid: false, token: payload.token, accountCurrent: payload.accountCurrent, uuid };
            callback({ data: LOGIN_SUCCESS_DATA });
            return LOGIN_SUCCESS_DATA;
        case "LOGIN_CHECKED":
            const LOGIN_CHECKED_DATA: AuthState = { ...state, accountCurrent: payload.accountCurrent, uuid };
            callback({ data: LOGIN_CHECKED_DATA });
            return LOGIN_CHECKED_DATA;
        case "LOGOUT":
            const oldAuth = getAuth();
            removeAuth();
            const LOGOUT_DATA: AuthState = { invalid: true, uuid, permission: [] }
            callback({
                data: LOGOUT_DATA,
                callback: () => removeAuth()
            });
            if (oldAuth.loginBySSO) {
                window.location.href = "https://dangnhap.gialai.gov.vn/oidc/logout";
            }
            return LOGOUT_DATA;
        case "AUTH_CHANGED":
            return { ...action.payload, uuid };
        case "ACCOUNT_CURRENT_UPDATE":
            return { ...state, accountCurrent: action.payload.accountCurrent }
        default:
            return state;
    }
}