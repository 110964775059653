import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { ModalProps } from "antd";
import { stringify } from "qs";
import React, { FC, Fragment, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AntModal, AntTable } from "../../../../../../../common";
import { CommonButtonAction } from "../../../../../../../common/c-action";
interface ILoginProps extends ModalProps {

}

declare type TabKeys = "login" | "register" | "forgot-password" | "cart";
const Login: FC<ILoginProps> = ({
    title,
    visible,
    closable,
    onCancel
}) => {
    const { pathname } = useLocation();
    const history = useHistory();
    return <Fragment>
        <AntModal
            title={title}
            visible={visible}
            closable={closable}
            onCancel={onCancel}
            footer={false}
            width={"70%"}
            style={{ top: 50 }}
            destroyOnClose
        >
            <AntTable
                bordered
                size="small"
                dataSource={[]}
                pagination={false}
                columns={[
                    {
                        title: "Sản phẩm",
                        align: "center",
                        dataIndex: "tenSanPham",
                    },
                    {
                        title: "Số lượng",
                        align: "center",
                        dataIndex: "soLuong",
                        width: 100
                    },
                    {
                        title: "Đơn giá",
                        align: "right",
                        dataIndex: "donGiaSanPham",
                        width: 100,
                    },
                    {
                        title: "Thành tiền",
                        align: "right",
                        dataIndex: "tongGia",
                        width: 120,
                    },
                    {
                        title: "Trạng thái",
                        align: "right",
                        dataIndex: "trangThai",
                        width: 120,
                    },
                    {
                        title: "Thao tác",
                        align: "right",
                        dataIndex: "trangThai",
                        width: 120,
                        render: (t, item) => {
                            return (
                                <Fragment>
                                    <CommonButtonAction
                                        type="primary"
                                        shape="circle"
                                        action_tooltip="Xóa"
                                        className="mr-2"
                                        onClick={() => {}}
                                    >
                                        <i className="fa fa-info-circle" />
                                    </CommonButtonAction>
                                </Fragment>
                            )
                        }
                    }
                ]}
                        />
        </AntModal >
    </Fragment >
}

export default Login;