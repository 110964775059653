import React, { CSSProperties, Fragment } from 'react';
import { AntCol, AntLayout, AntList, AntRow, AntSelect, AntSkeleton, AntSpace } from '../../../../../../../../common';

const contentStyle: CSSProperties = {

    padding: '8px',
    backgroundColor: "#FFFFFF"
};
const DesignPage = () => {
    return <Fragment>
        <AntLayout style={contentStyle}>
            <AntSkeleton avatar paragraph={{ rows: 2 }} />
        </AntLayout>
    </Fragment>
};

export default DesignPage;