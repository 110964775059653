/**
 * 
 * @param {*} cname 
 * @param {*} cvalue 
 * @param {*} exdays 
 */
export default (cname: string, cvalue: string | number, exdays: number = 1) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
