import React, { FC, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../../../reducers";
import { findPage } from "../../../../../../reducers/page-builder";
import { ComponentRenderProps, ContainerProps } from ".";
import { data } from "../components/components"
import { uuid } from "../../../../../../common";

const ContainerPreview: FC<ContainerProps> = ({ page, index }) => {
    const { page_id } = useParams() as { page_id?: string };
    const pages = useSelector((state: RootState) => state.pageBuilder.pages);
    const _page = page || findPage(pages, parseInt(`${page_id}`, 0));
    const builder = _page?.history?.current?.pageBuilderContent || _page?.pageBuilderContent || [];
    const _p = builder[index] || [];
    useEffect(() => {
        const onBodyScroll = () => { console.log("scroll") }
        window.addEventListener("scroll", onBodyScroll);
        return () => {
            window.removeEventListener("scroll", onBodyScroll);
        }
    }, [])
    return <div className="page-builder-component page-builder-component-preview">
        {_p.map((item, i) => <ComponentRender ma={item.ma} giaTri={item.giaTri} key={i} layoutIndex={index} index={i} />)}
    </div>
}

export default ContainerPreview;

const ComponentRender: FC<ComponentRenderProps> = ({ ma, giaTri }) => {
    const [content, setContent] = useState<any>(null)

    useEffect(() => {
        const res = data.find(item => item.ma === ma);
        setContent(<div>{res && <res.previewPage giaTri={giaTri} key={uuid()} />}</div>);
    }, [ma, giaTri])

    return <Fragment>
        {content}
    </Fragment>;
}