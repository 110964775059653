import React, { FC, Fragment } from "react";
import ContainerDrag from "../../container.drag";
import { DesignPageProps } from "./../"
import { AntCol, AntLayout, AntRow } from "../../../../../../../../common";

const DesignPage: FC<DesignPageProps> = () => {
    return <Fragment>
        <AntRow className="px-5">
            <AntCol span={24}>
                <ContainerDrag index={0} key="header" />
            </AntCol>
        </AntRow>
        <div className="px-50">
            <AntRow className="py-10">
                <AntCol span={16} className="px-5">
                    <ContainerDrag index={1} key="row1" />
                </AntCol>
                <AntCol span={8}>
                    <ContainerDrag index={2} key="row2" />
                </AntCol>
            </AntRow>
        </div>
        <AntRow className="px-5">
            <AntCol span={24}>
                <ContainerDrag index={3} key="footer" />
            </AntCol>
        </AntRow>
    </Fragment >
}
export default DesignPage;
