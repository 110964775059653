import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { ModalProps } from "antd";
import React, { FC, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { AntButton, AntCheckbox, AntCol, AntDivider, AntForm, AntModal, AntRow, AntTabs, CommonForm } from "../../../../../../../common";

interface ILoginProps extends ModalProps {

}

declare type TabKeys = "login" | "register" | "forgot-password";
const ForgotPassword: FC<ILoginProps> = ({
    title,
    visible,
    closable,
    onCancel
}) => {
    return <Fragment>
        <AntModal
            title={title}
            visible={visible}
            closable={closable}
            onCancel={onCancel}
            footer={false}
            width={450}
            style={{ top: 50 }}
            destroyOnClose
        >
            <CommonForm
                data={[
                    {
                        col: 24,
                        name: "email",
                        type: "email",
                        colClassName: "mt-10 px-10",
                        formItemProps: {
                            rules: [
                                { required: true, message: "Vui lòng nhập email" }
                            ]
                        }
                    }
                ]}
            />
        </AntModal>
    </Fragment>
}

export default ForgotPassword;