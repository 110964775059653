import React, { FC, Fragment } from "react";
import { AntAlert } from "../../../../../../../../common";
import { DesignSiderProps } from "./../";

const DesignSider: FC<DesignSiderProps> = ({ title }) => {
  return (
    <Fragment>
      <AntAlert
        icon={<i className="fa fa-map-marker fa-2x" />}
        message={title || "Lộ trình các điểm lân cận"}
      />
    </Fragment>
  );
};

export default DesignSider;
