import { RouteProps } from "./../";
import builder from "./builder";
import system from "./system";
import systemPortal from "./system-portal";
import baoCaoTongHop from "./bao-cao-tong-hop";
import baocaoDoanhnghiep from "./bao-cao-doanh-nghiep";
const routes: Array<RouteProps> = [
  {
    path: "quan-tri",
    children: [
      ...system,
      ...builder,
      ...systemPortal,
      ...baoCaoTongHop,
      ...baocaoDoanhnghiep,
    ],
  },
];
export default routes;
