
import React, { CSSProperties } from "react";
import Component from "./component";
import DesignSider from "./design.sider";
import DesignPage from "./design.page";
import PreviewPage from "./preview.page";

const Layout4 = {
    ma: "layout-4",
    siderLabel: <DesignSider />,
    pageLabel: <DesignPage />,
    previewPage: <PreviewPage />,
    component: <Component />,
    View: Component,
    column: 3
};

export interface StyleProps {
    container: CSSProperties;
    header: CSSProperties;
    footer: CSSProperties;
    content: CSSProperties;
    row: CSSProperties;
}
export default Layout4;