export default {
    tenCoSo: "Teen Cow sowr",
    ngayBaoCao: "",
    kyBaoCao: "",
    data: {
        columns: [
            {
                title: "Chỉ tiêu",
                dataIndex: "c1",
            },
            {
                title: "ĐVT",
                dataIndex: "c2",
                width: 100,
                align: "center"
            },
            {
                title: "Mã số",
                dataIndex: "c3",
                width: 50,
                align: "center"
            },
            {
                title: "Số thực hiện kỳ báo cáo tháng",
                dataIndex: "c4",
                width: 150,
                align: "center"
            },
            {
                title: "Số thực hiện kỳ tới 1 năm sau",
                dataIndex: "c5",
                width: 150,
                align: "center"
            },
            {
                title: "Số ước thực hiện cả năm",
                dataIndex: "c6",
                width: 150,
                align: "center"
            }
        ],
        body: [
            {
                c1: "1. Doanh nghiệp lữ hành, vận chuyển khách du lịch",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "1.1. Số lượt khách phục vụ ( Khách quốc tế đến, khách nội địa, khách việt nam du lịch nước ngoài)",
                c2: "Lượt",
                c3: "01",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-2", "c4-3", "c4-4"],
                    c5: ["c5-2", "c5-3", "c5-4"],
                    c6: ["c6-2", "c6-3", "c6-4"]
                }
            },
            {
                c1: "- Khách quốc tế đến",
                c2: "Lượt",
                c3: "02",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khách du lịch nội địa",
                c2: "Lượt",
                c3: "03",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khách Việt Nam du lịch nước ngoài",
                c2: "Lượt",
                c3: "04",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "Nếu phát sinh khách, đề nghị báo cáo doanh thu. Trường hợp là chi nhánh hạch toán phụ thuộc, doanh thu ghi 0",
                italic: true,
                color: "red",
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "1.2. Doanh thu theo thị trường khách và loại hình dịch vụ",
                c2: "Triệu đồng",
                c3: "05",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-8", "c4-9", "c4-10", "c4-12", "c4-13", "c4-14"],
                    c5: ["c5-8", "c5-9", "c5-10", "c5-12", "c5-13", "c5-14"],
                    c6: ["c6-8", "c6-9", "c6-10", "c6-12", "c6-13", "c6-14"]
                }
            },
            {
                c1: "Chia theo thị trường khách",
                italic: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "- Khách quốc tế đến",
                c2: "Triệu đồng",
                c3: "06",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khách du lịch nội địa",
                c2: "Triệu đồng",
                c3: "07",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khách Việt Nam du lịch nước ngoài",
                c2: "Triệu đồng",
                c3: "08",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "Chia theo loại hình dịch vụ",
                italic: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "- Hoạt động lữ hành",
                c2: "Triệu đồng",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Hoạt động vận chuyển khách du lịch",
                c2: "Triệu đồng",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Hoạt động khác",
                c2: "Triệu đồng",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "2. Các khu, điểm du lịch được công nhận",
                bold: true,
                colSpan: { c1: 6, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0 }
            },
            {
                c1: "2.1. Số lượt khách phục vụ",
                c2: "Lượt",
                c3: "09",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-17", "c4-18"],
                    c5: ["c5-17", "c5-18"],
                    c6: ["c6-17", "c6-18"],
                }
            },
            {
                c1: "- Khách quốc tế đến",
                c2: "Lượt",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khách du lịch nội địa",
                c2: "Lượt",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "2.2. Doanh thu",
                c2: "Triệu đồng",
                c3: "10",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-20", "c4-21"],
                    c5: ["c5-20", "c5-21"],
                    c6: ["c6-20", "c6-21"],
                }
            },
            {
                c1: "- Phí và lệ phí",
                c2: "Triệu đồng",
                c3: "11",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Thu khác",
                c2: "Triệu đồng",
                c3: "12",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "3. Tổng số lao động trực tiếp hoạt động du lịch của cơ sở",
                c2: "Người",
                c3: "13",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-23", "c4-24", "c4-25", "c4-26", "c4-27"],
                    c5: ["c5-23", "c5-24", "c5-25", "c5-26", "c5-27"],
                    c6: ["c6-23", "c6-24", "c6-25", "c6-26", "c6-27"],
                }
            },
            {
                c1: "- Ăn uống",
                c2: "Người",
                c3: "14",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Lữ hành",
                c2: "Người",
                c3: "15",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Vận chuyển khách",
                c2: "Người",
                c3: "16",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Vui chơi, giải trí, bán hàng",
                c2: "Người",
                c3: "17",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "- Khác",
                c2: "Người",
                c3: "18",
                colType: { c4: "input", c5: "input", c6: "input" }
            },
            {
                c1: "5. Số lượng khách của 10 thị trường hàng đầu (theo quốc tịch khách quốc tế đến)",
                c2: "Người",
                bold: true,
                colType: { c4: "sum", c5: "sum", c6: "sum" },
                sum: {
                    c4: ["c4-29", "c4-30"],
                    c5: ["c5-29", "c5-30"],
                    c6: ["c6-29", "c6-30"],
                }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            },
            {
                c2: "Người",
                colType: { c1: "select", c4: "input", c5: "input", c6: "input" }
            }
        ]
    }
}