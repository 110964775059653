import React, { CSSProperties, Fragment, useRef } from "react";
import { AntImage } from '../../../../../../../../common';

const contentStyle: CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: 'rgb(114 54 121)',
};
const DesignPage = () => {
    return <Fragment>
        <div style={contentStyle}>COMPONENT_HTML</div>
    </Fragment>
}

export default DesignPage;