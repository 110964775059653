import { parse, stringify } from "qs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IHref } from "./";
import { findbyId } from "../../../page/api";

interface IProps {
    href?: IHref
}
interface UseUrlConfigProps {
    path: string | null;
    target: string | undefined;
    replace: boolean;
}
const useUrlConfig = (data: IProps): UseUrlConfigProps => {
    const { href } = data;
    const { pathname, search, hash } = useLocation();
    const [to, setTo] = useState<string>("");
    const getPageById = async (id: number) => {
        const res = await findbyId(id);
        if (res.status) {
            const page = res.result;
            setTo(page?.page.url || "");
        }

    }
    useEffect(() => {
        switch (href?.type) {
            case "string":
                setTo(href.path || "");
                break;
            case "object":
                const fullPath = {
                    pathname: href.pathname || pathname,
                    search: stringify({ ...(href.extendSearch ? parse(search, { ignoreQueryPrefix: true }) : {}), ...parse(href.search || "") }),
                    hash: href.hash
                }
                setTo(`${fullPath.pathname}${fullPath.search ? `?${fullPath.search}` : ""}${fullPath.hash ? `#${fullPath.hash}` : ""}`);
                break;
            case "page":
                href.pageId && getPageById(href.pageId);
                break;
        }
    }, [href])
    return {
        path: to,
        target: href?.openNewTab ? "_blank" : undefined,
        replace: href?.action === "replace"
    }
}
export default useUrlConfig;