import {
  compose,
  createStore as reduxCreateStore,
  applyMiddleware,
  Reducer,
  CombinedState,
  combineReducers
} from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import pageBuilder, { PageBuilderState } from "./page-builder";
import formBuilder, { FormBuilderState } from "./form-builder";
import menuBuilder, { MenuBuilderState } from "./menu";
import auth, { AuthState } from "./auth";
import file, { FileState } from "./file";
import webContent, { webContentState } from "./web-content";
import manageModule, { ModuleState } from "./manage-module";
import groupModule, { GroupModuleState } from "./group-module";
import dataFormBuilders, { DataFormBuilderState } from "./data-form-builder";
import modelContentField, { ModelFieldState } from "./model-content-filed";
import config, { ConfigProps } from "./config"
import data, { DataState } from "./data";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export interface ActionProps {
  type: string;
  payload: any;
}

const composeEnhancer =
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
const logger = createLogger({
  collapsed: true,
  predicate: () => process.env.NODE_ENV === `development`, // eslint-disable-line no-unused-vars
});

export const createStore = (reducer: Reducer<CombinedState<RootState>>) =>
  reduxCreateStore(
    reducer,
    process.env.NODE_ENV === `development`
      ? composeEnhancer(applyMiddleware(thunk, logger))
      : applyMiddleware(thunk, logger)
  );

export default combineReducers({
  auth,
  config,
  pageBuilder,
  formBuilder,
  file,
  dataFormBuilders,
  modelContentField,
  menuBuilder,
  webContent,
  manageModule,
  groupModule,
  data,
}) as Reducer<CombinedState<RootState>>;

export interface RootState {
  auth: AuthState;
  config: ConfigProps,
  pageBuilder: PageBuilderState;
  formBuilder: FormBuilderState;
  file: FileState;
  webContent: webContentState;
  dataFormBuilders: DataFormBuilderState;
  modelContentField: ModelFieldState;
  menuBuilder: MenuBuilderState;
  manageModule: ModuleState;
  groupModule: GroupModuleState;
  data: DataState[]

}
