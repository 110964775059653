import React from "react";
import Component from "./component";
import DesignSider from "./design.sider";
import DesignPage from "./design.page";
import PreviewPage from "./preview.page";
import { ComponentDataProps } from "..";

const ComponentFooter: ComponentDataProps = {
    title: "Tiện ích",
    ma: "tienich",
    siderLabel: DesignSider,
    pageLabel: DesignPage,
    previewPage: PreviewPage,
    component: <Component />,
    View: Component

}
export default ComponentFooter;