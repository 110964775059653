import { ActionProps } from "..";
import { LayoutName } from "../../components/builder/page-builder/builder/sider/layouts/components";
import arrayMove from "array-move";
import { AntMessage, uuid } from "../../common";
import { ItemProps as ResponseItemProps } from "./../../components/builder/page-builder/page/api";
import { layouts } from "./../../components/builder/page-builder/builder/sider/layouts/components";
import { orderBy } from "lodash";
import { ComponentRenderMa } from "../../components/builder/page-builder/builder/sider/layouts";
import dataFormBuilder from "../data-form-builder";
import { ItemProps as PageModule } from "./../../components/builder/manage-module/module/api";
import { findPage } from ".";
interface BuilderOptionUpdateProps {
  page_id: number;
  index: any;
  transactionCode: string;
  giaTri: any;
  uuid: any;
}
export interface PageBuilderContentProps {
  uuid: string;
  id?: string;
  giaTri?: any;
  ma: ComponentRenderMa;
  sapXep: number;
  cauHinh?: { [key: string]: any };
}

declare type HistoryProps = {
  transactionCode: string;
  layout: LayoutName;
  pageBuilderContent: Array<PageBuilderContentProps[]>;
};
export interface PageItemProps {
  id: number;
  title: string;
  description: string;
  url: string;
  publish?: boolean;
  frameUrl?: string;
  layout: LayoutName;
  parentId: number | null;
  sort: number;
  createdDate?: string;
  createdBy?: string;
  pageComponent: Array<any>;
  delete?: boolean;
}
export interface PageProps {
  page: PageItemProps;
  pageModules: PageModule[];
  pageModulesLoaded?: boolean;
  pageBuilderContent: Array<PageBuilderContentProps[]>;
  pageChildList: PageProps[];
  history?: {
    current?: HistoryProps;
    data: HistoryProps[];
  };
}
const stateDefault: Array<PageProps> = [];

interface PayloadLayoutChangeProps {
  page_id: number;
  column: number;
  layout: LayoutName;
}

interface PayloadPageChangeProps {
  id: number;
  builder: Array<PageBuilderContentProps[]>;
}

interface PayloadPageBuilderComponentChange {
  replaceIndex: null | number;
  addedIndex: null | number;
  removedIndex: null | number;
  index: number;
  transactionCode: string;
  ma: ComponentRenderMa;
  page_id: number;
  giaTri?: any;
}

const PAGE_BUILDER_CHANGED = (
  pages: Array<PageProps> = [],
  payload: PayloadPageChangeProps
): Array<PageProps> => {
  return pages.map((p) =>
    p.page.id === payload.id
      ? { ...p, builder: payload.builder }
      : { ...p, pageChildList: PAGE_BUILDER_CHANGED(p.pageChildList, payload) }
  );
};

const history = (
  history: { current?: HistoryProps; data: HistoryProps[] },
  transactionCode: string,
  layout: LayoutName,
  pageBuilderContent: Array<PageBuilderContentProps[]>
): { current?: HistoryProps; data: HistoryProps[] } => {
  const currentIndex: number | undefined = history.data.findIndex(
    (h) => h.transactionCode === transactionCode
  );
  const current: HistoryProps = {
    transactionCode,
    layout,
    pageBuilderContent,
  };
  if (history.data.length === 0 || typeof currentIndex !== "number") {
    return {
      current,
      data: [current],
    };
  } else {
    return {
      current,
      data: [
        current,
        ...history.data.filter(
          (item, i) =>
            item.transactionCode !== transactionCode && i >= currentIndex
        ),
      ],
    };
  }
};
const PAGE_BUILDER_LAYOUT_CHANGE = (
  pages: Array<PageProps> = [],
  payload: PayloadLayoutChangeProps
): Array<PageProps> => {
  return pages.map((p) => {
    if (p.page.id === payload.page_id) {
      let pageBuilderContent: Array<PageBuilderContentProps[]> = [];
      for (let i = 0; i < payload.column || 0; i++) {
        pageBuilderContent.push(p?.pageBuilderContent[i] || []);
      }
      return {
        ...p,
        page: { ...p.page, layout: payload.layout },
        pageBuilderContent,
      };
    } else {
      return {
        ...p,
        pageChildList: PAGE_BUILDER_LAYOUT_CHANGE(p.pageChildList, payload),
      };
    }
  });
};
const PAGE_BUILDER_COMPONENT_CHANGE = (
  pages: Array<PageProps> = [],
  {
    replaceIndex,
    removedIndex,
    addedIndex,
    index,
    ma,
    page_id,
    giaTri = {},
    transactionCode,
  }: PayloadPageBuilderComponentChange
): PageProps[] => {
  return pages.map((p) => {
    if (p.page.id === page_id) {
      const pageBuilderContent: Array<PageBuilderContentProps[]> = (
        p.history?.current?.pageBuilderContent || p.pageBuilderContent
      ).map((item, i) => {
        if (i === index) {
          if (typeof replaceIndex === "number") {
            return item.map((v, i) =>
              i === replaceIndex
                ? { uuid: uuid(), ma, sapXep: i, giaTri }
                : { ...v, sapXep: i }
            );
          } else if (
            (addedIndex !== null || removedIndex !== null) &&
            removedIndex !== addedIndex
          ) {
            if (removedIndex !== null && addedIndex !== null) {
              return arrayMove(item, removedIndex, addedIndex)
                .filter((el) => !!el)
                .map((v, i) => ({ ...v, sapXep: i }));
            } else if (removedIndex !== null) {
              let i_1 = [...item];
              i_1.splice(removedIndex, 1);
              return i_1.map((v, i) => ({ ...v, sapXep: i }));
            } else if (addedIndex !== null) {
              let i_2 = [...item];
              i_2.splice(addedIndex, 0, {
                uuid: uuid(),
                ma,
                sapXep: addedIndex,
                giaTri,
              });
              return i_2.map((v, i) => ({ ...v, sapXep: i }));
            } else {
              return item.map((v, i) => ({ ...v, sapXep: i }));
            }
          }
        }
        return item.map((v, i) => ({ ...v, sapXep: i }));
      });
      return {
        ...p,
        history: history(
          p.history || { data: [] },
          transactionCode,
          p.page.layout,
          pageBuilderContent
        ),
      };
    } else {
      return {
        ...p,
        pageChildList: PAGE_BUILDER_COMPONENT_CHANGE(p.pageChildList, {
          replaceIndex,
          removedIndex,
          addedIndex,
          index,
          ma,
          page_id,
          giaTri,
          transactionCode,
        }),
      };
    }
  });
};
const PAGE_BUILDER_COMPONENT_NEXT = (
  pages: PageProps[],
  { page_id }: { page_id: number }
): PageProps[] => {
  return pages.map((page) => {
    if (page.page.id === page_id) {
      if (
        page.history?.data.length === 0 ||
        page.history?.current?.transactionCode ===
          page.history?.data[0]?.transactionCode
      ) {
        AntMessage.error({
          key: "action_fail",
          content: "Thao tác thất bại!",
        });
        return page;
      } else {
        const curentIndex: number | undefined = page.history?.current
          ? page.history?.data.findIndex(
              (h) =>
                h.transactionCode === page.history?.current?.transactionCode
            )
          : page.history?.data.length;
        const current =
          typeof curentIndex === "number"
            ? page.history?.data[curentIndex - 1]
            : undefined;
        return {
          ...page,
          history: {
            current,
            data: page.history?.data || [],
          },
        };
      }
    } else {
      return {
        ...page,
        pageChildList: PAGE_BUILDER_COMPONENT_NEXT(page.pageChildList, {
          page_id,
        }),
      };
    }
  });
};

const PAGE_BUILDER_COMPONENT_PREVIEOUS = (
  pages: PageProps[],
  { page_id }: { page_id: number }
): PageProps[] => {
  return pages.map((page) => {
    if (page.page.id === page_id) {
      if (page.history?.data.length === 0 || !page.history?.current) {
        AntMessage.error({
          key: "action_fail",
          content: "Thao tác thất bại!",
        });
        return page;
      } else {
        const curentIndex: number | undefined = page.history?.data.findIndex(
          (h) => h.transactionCode === page.history?.current?.transactionCode
        );
        const current =
          typeof curentIndex === "number"
            ? page.history?.data[curentIndex + 1]
            : undefined;
        return {
          ...page,
          history: {
            current,
            data: page.history?.data || [],
          },
        };
      }
    } else {
      return {
        ...page,
        pageChildList: PAGE_BUILDER_COMPONENT_PREVIEOUS(page.pageChildList, {
          page_id,
        }),
      };
    }
  });
};

const CREATE_PAGE = (
  list: PageProps[],
  page: ResponseItemProps
): PageProps[] => {
  return list.map((value) => {
    if (value.page.id === page.page.parentId) {
      return {
        ...value,
        pageChildList: [
          {
            page: page.page,
            pageModules: page.pageModules || [],
            pageBuilderContent: [],
            pageChildList: [],
          },
          ...(value.pageChildList || []),
        ],
      };
    } else {
      return {
        ...value,
        pageModules: value.pageModules || [],
        pageChildList: CREATE_PAGE(value.pageChildList || [], page),
      };
    }
  });
};

const UPDATE_PAGE = (
  list: PageProps[],
  update: ResponseItemProps
): PageProps[] => {
  return list.map((value) => {
    if (value.page.id === update.page.id) {
      const column = layouts.find((layout) => layout.ma === update.page.layout)
        ?.column;
      const pageBuilderContent: Array<PageBuilderContentProps[]> = [];
      if (column) {
        for (let i = 0; i < column || 0; i++) {
          pageBuilderContent.push([]);
        }
      }
      return {
        ...value,
        page: update.page,
        pageBuilderContent: pageBuilderContent.map((b, i) => {
          return orderBy(
            update.pageBuilderContent[i]?.pageBuilderOption || [],
            "sort",
            "asc"
          ).map((item) => ({
            uuid: uuid(),
            ma: item.optionId,
            sapXep: item.sort,
            giaTri: item.buildOption || {},
          })) as PageBuilderContentProps[];
        }),
        history: undefined,
      };
    } else {
      return {
        ...value,
        pageChildList: UPDATE_PAGE(value.pageChildList, update),
      };
    }
  });
};

const PAGE_LOAD = (
  pages: ResponseItemProps[],
  oldPages: PageProps[]
): PageProps[] => {
  return pages.map((p) => {
    const pageChildList = p.pageChildList || ([] as ResponseItemProps[]);
    const column = layouts.find((layout) => layout.ma === p.page.layout)
      ?.column;
    const pageBuilderContent: Array<PageBuilderContentProps[]> = [];
    if (column) {
      for (let i = 0; i < column || 0; i++) {
        pageBuilderContent.push([]);
      }
    }
    const oldPage = findPage(oldPages, p.page.id);
    return {
      page: p.page,
      pageModules: oldPage?.pageModules || [],
      pageBuilderContent: pageBuilderContent.map((b, i) => {
        return orderBy(
          (p.pageBuilderContent || [])[i]?.pageBuilderOption || [],
          "sort",
          "asc"
        ).map((item) => ({
          uuid: uuid(),
          ma: item.optionId,
          sapXep: item.sort,
          giaTri: {
            ...(item.buildOption || {}),
            private: item.buildOptionPrivate || {},
          },
        })) as PageBuilderContentProps[];
      }),
      pageChildList: PAGE_LOAD(pageChildList || [], oldPages),
      pageModulesLoaded: oldPage?.pageModulesLoaded,
    };
  });
};

const BUILDER_OPTION_UPDATE = (
  state: PageProps[],
  { page_id, giaTri, index, transactionCode, uuid }: BuilderOptionUpdateProps
): any => {
  /**
   *  Code tại đây
   */
  return [...state].map((p) => {
    if (p.page.id === page_id) {
      const pageBuilderContent: Array<PageBuilderContentProps[]> = (
        p.history?.current?.pageBuilderContent || p.pageBuilderContent
      ).map((item, i) => {
        if (i === index) {
          return item.map((builder) => {
            if (builder.uuid === uuid) {
              return {
                ...builder,
                giaTri,
              };
            }
            return builder;
          });
        }
        return item;
      });
      return {
        ...p,
        history: history(
          p.history || { data: [] },
          transactionCode,
          p.page.layout,
          pageBuilderContent
        ),
      };
    } else {
      return {
        ...p,
        pageChildList: BUILDER_OPTION_UPDATE(p.pageChildList, {
          page_id,
          giaTri,
          index,
          transactionCode,
          uuid,
        }),
      };
    }
  });
};

const UPDATE_PAGE_MODULES = (
  state: PageProps[],
  pageModules: PageModule[],
  pageId: number
): PageProps[] => {
  return state.map((item) => {
    if (item.page.id === pageId) {
      return {
        ...item,
        pageModulesLoaded: true,
        pageModules,
      };
    } else {
      return {
        ...item,
        pageChildList: UPDATE_PAGE_MODULES(
          item.pageChildList,
          pageModules,
          pageId
        ),
      };
    }
  });
};

const UPDATE_MODULES = (
  pages: PageProps[],
  modules: PageModule[]
): PageProps[] => {
  return pages.map((item) => {
    const module = modules.find((m) => m.pageId === item.page.id);
    let pageModules = item.pageModules.filter(
      (pm) => modules.findIndex((m) => pm.optionId === m.optionId) === -1
    );
    if (module && item.pageModulesLoaded) {
      pageModules = [...pageModules, module];
    }
    return {
      ...item,
      pageModules,
      pageChildList: UPDATE_MODULES(item.pageChildList, modules),
    };
  });
};

const UPDATE_MODULE_CAUHINH = (
  pages: PageProps[],
  module: PageModule
): PageProps[] => {
  return pages.map((item) => {
    return {
      ...item,
      pageModules: item.pageModules.map((m) =>
        m.optionId === module.optionId ? { ...m, cauHinh: module.cauHinh } : m
      ),
      pageChildList: UPDATE_MODULE_CAUHINH(item.pageChildList, module),
    };
  });
};
const PAGE_DELETE = (pages: PageProps[], pageIds: number[]): PageProps[] =>
  pages
    .filter((page) => !pageIds.includes(page.page.id))
    .map((page) => ({
      ...page,
      pageChildList: PAGE_DELETE(page.pageChildList, pageIds),
    }));

const MOVE_PAGE = (list: PageProps[], movePage: PageProps): PageProps[] => {
  return list
    .filter((value) => value.page.id !== movePage.page.id)
    .map((value) => {
      if (value.page.id === movePage.page.parentId) {
        return {
          ...value,
          pageChildList: [...value.pageChildList, movePage],
        };
      } else {
        return {
          ...value,
          pageChildList: MOVE_PAGE(value.pageChildList, movePage),
        };
      }
    });
};

const pages = (
  state: PageProps[] = stateDefault,
  action: ActionProps
): PageProps[] => {
  const type = action.type;
  switch (type) {
    case "PAGE_LOAD":
      const pages = action.payload as ResponseItemProps[];
      return PAGE_LOAD(pages, state);
    case "PAGE_DELETE":
      const pagesDelete = action.payload as { pageIds: number[] };
      return PAGE_DELETE(state, pagesDelete.pageIds);
    case "PAGE_BUILDER_CHANGED":
      return PAGE_BUILDER_CHANGED(
        state,
        action.payload as PayloadPageChangeProps
      );
    case "PAGE_BUILDER_LAYOUT_CHANGE":
      return PAGE_BUILDER_LAYOUT_CHANGE(
        state,
        action.payload as PayloadLayoutChangeProps
      );
    case "PAGE_BUILDER_COMPONENT_CHANGE":
      return PAGE_BUILDER_COMPONENT_CHANGE(
        state,
        action.payload as PayloadPageBuilderComponentChange
      );
    case "PAGE_BUILDER_COMPONENT_NEXT":
      return PAGE_BUILDER_COMPONENT_NEXT(
        state,
        action.payload as { page_id: number }
      );
    case "PAGE_BUILDER_COMPONENT_PREVIEOUS":
      return PAGE_BUILDER_COMPONENT_PREVIEOUS(
        state,
        action.payload as { page_id: number }
      );
    case "CREATE_PAGE":
      const page = action.payload as ResponseItemProps;
      return page.page.parentId
        ? CREATE_PAGE(state, page)
        : [
            {
              page: page.page,
              pageModules: [],
              pageBuilderContent: [],
              pageChildList: [],
            },
            ...state,
          ];
    case "UPDATE_PAGE":
      const update = action.payload as ResponseItemProps;
      return UPDATE_PAGE(state, update);
    case "BUILDER_OPTION_UPDATE":
      const builderOptionUpdate = action.payload as BuilderOptionUpdateProps;
      return BUILDER_OPTION_UPDATE(state, builderOptionUpdate);
    case "UPDATE_PAGE_MODULES":
      const updatePageModules = action.payload as {
        pageModules: Array<PageModule>;
        pageId: number;
      };
      return UPDATE_PAGE_MODULES(
        state,
        updatePageModules.pageModules,
        updatePageModules.pageId
      );
    case "UPDATE_MODULES":
      const updateModules = action.payload as { modules: Array<PageModule> };
      return UPDATE_MODULES(state, updateModules.modules);
    case "UPDATE_MODULE_CAUHINH":
      const updateModuleCauHinh = action.payload as { module: PageModule };
      return UPDATE_MODULE_CAUHINH(state, updateModuleCauHinh.module);
    case "MOVE_PAGE":
      const movePage = action.payload as PageProps;
      return movePage.page.parentId === 0
        ? [...MOVE_PAGE(state, movePage), movePage]
        : MOVE_PAGE(state, movePage);
    default:
      return state;
  }
};
export default pages;
