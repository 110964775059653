import React, { FC, Fragment } from "react";
import ContainerPreview from "../../container.preview";
import { ComponentProps } from "..";
import { AntCol, AntRow } from "../../../../../../../../common";

const Component: FC<ComponentProps> = ({ page }) => {
    return <Fragment>
        <AntRow >
            <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <ContainerPreview index={0} key="row1" page={page} />
            </AntCol>
            <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <ContainerPreview index={1} key="row2" page={page} />
            </AntCol>
        </AntRow>
    </Fragment>
}
export default Component;
