import React, { Fragment } from "react";
import { AntButton, AntCard, AntCol, AntImage, AntList, AntRow, AntSkeleton } from "../../../../../../../../common";

const list = [
    { id: 1 },
    { id: 2 },
    { id: 3 }
]

interface ViewAllActionProps {
    hidden?: boolean;
    href?: string;
    iconCls?: string;
    label?: string;
    displayType?: "center" | "left" | "right" | "full"
}
interface FormItemProps {
    formId: number;
    dataIds: string[]
}
interface IDisplayType {
    viewAllAction: ViewAllActionProps;
    loading?: boolean;
    data?: any[]
}

interface ITitle {
    label?: string;
    hidden?: boolean;
    fontSize?: number;
    color?: string;
    bgColor?: string;
}
interface GiaTriProps {
    viewAllAction?: ViewAllActionProps;
    forms?: FormItemProps[];
    title?: ITitle;
    displayType?: 1 | 2 | 3 | 4
}


const DesignPage = ({ giaTri = {} }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { viewAllAction, displayType, forms, title } = _giaTri;
    const textAlign = viewAllAction?.displayType === "left" || viewAllAction?.displayType === "right" || viewAllAction?.displayType === "center" ? viewAllAction.displayType : undefined;
    return <Fragment>
        <AntCard
            title={!Boolean(title?.hidden) ? <span >{title?.label}</span> || <AntSkeleton.Input size="small" active style={{ width: 200 }} /> : undefined}
            size="small"
            className="design-page-news mb-10"
            headStyle={{ color: title?.color, backgroundColor: title?.bgColor, fontSize: title?.fontSize || 25 }}
        >
            {
                displayType === 4 ?
                    <AntRow>
                        <AntCol span={24}>
                            <AntList.Item>
                                <AntSkeleton
                                    loading
                                    avatar={{
                                        shape: "square",
                                        size: "large"
                                    }}
                                />
                            </AntList.Item>
                            <div>
                                <span>
                                    <i className="fa fa-long-arrow-left mr-10 c-pointer" />
                                </span>
                                <span>
                                    0/3
                                </span>
                                <span>
                                    <i className="fa fa-long-arrow-right ml-10 c-pointer" />
                                </span>
                            </div>
                        </AntCol>
                    </AntRow> :
                    <AntRow>
                        {
                            (displayType === 1 || displayType === 2) &&
                            <AntCol md={{ span: displayType === 2 ? 24 : 12 }} sm={{ span: displayType === 2 ? 24 : 12 }} xs={{ span: 24 }} className="px-5">
                                <AntCard
                                    cover={<div style={{ background: "#f5f5f5", textAlign: 'center' }}>
                                        <AntImage
                                            style={{
                                                maxWidth: 200,
                                                maxHeight: 200
                                            }}
                                            src="error"
                                        />
                                    </div>}
                                    bordered={false}
                                    bodyStyle={{ padding: 0 }}
                                >
                                    <AntSkeleton />
                                </AntCard>
                            </AntCol>
                        }
                        <AntCol md={{ span: displayType === 1 ? 12 : 24 }} sm={{ span: displayType === 1 ? 12 : 24 }} xs={{ span: 24 }} className="px-5">
                            <AntList
                                dataSource={list}
                                itemLayout="horizontal"
                                renderItem={item => <AntList.Item>
                                    <AntSkeleton
                                        loading
                                        avatar={{
                                            shape: "square"
                                        }}
                                    />
                                </AntList.Item>}
                                footer={!viewAllAction?.hidden ?
                                    <div style={{ textAlign }}>
                                        <AntButton block={viewAllAction?.displayType === "full"} type="primary">
                                            Xem tất cả
                                        </AntButton>
                                    </div> : undefined}
                            />
                        </AntCol>
                    </AntRow>
            }
        </AntCard>
    </Fragment >
}

export default DesignPage;