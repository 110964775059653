import moment from 'moment';
import React, { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { AntAlert, AntAvatar, AntCard, AntCol, AntEmpty, AntList, AntRow, AntSelect, AntSkeleton, AntTabs, CommonScrollBar, useEdit } from "../../../../../../../../common";
import { getDataByFromIdsAndDataIds, getDataFieldSelectByIds } from '../../../../../../data-form-builder/api';
import * as COMMON_FNC from './fnc';

const MapMarker = lazy(() => import(`./map/map`));

const list = [
    { id: 1 },
    { id: 2 },
    { id: 3 }
]
interface FormItemProps {
    formId: number;
    dataIds: string[]
}
interface IDisplayType {
    loading?: boolean;
    lang: string;
    itemKey: IItemKey;
    tabCategory?: any[];
    selectCategory?: any[];
    activeKey?: string;
    changeSelect?: any;
    dataFilter?: any[];
    changeTab?: any;
    handleMap?: any;
    mapsSelected?: any;
}

interface ITitle {
    label?: string;
    hidden?: boolean;
    fontSize?: number;
    color?: string;
    bgColor?: string;
}
interface IItemKey {
    image: string[];
    title: string[];
    description: string[];
    map: string[];
    reference: string[];
    address: string[];
}

interface GiaTriProps {
    forms?: FormItemProps[];
    formData?: {
        [key: string]: FormItemProps[]
    };
    title?: ITitle;
    displayType?: 1 | 2;
    itemKey: IItemKey;
    autoPlay?: boolean;
    autoplaySpeed?: number;
}

const PreviewPage = ({ giaTri = {} }: any) => {
    const { lang } = useParams() as { lang: string };
    const _giaTri: GiaTriProps = giaTri || {};
    const { displayType, forms, title, formData, itemKey } = _giaTri;
    const edit = useEdit({ key: moment().toISOString() });
    const [tabCategory, setTabCategory]: any[] = useState([]);
    const [selectCategory, setSelectCategory]: any[] = useState([]);
    const [dataFilter, setDataFilter]: any[] = useState([]);
    const [activeKey, setActiveKey] = useState("");
    const [dataMap, setDataMap]: any = useState({});
    const [selectTab, setSelectTab]: any = useState([]);
    const [mapsSelected, setMapsSelected]: any = useState([[0, 0]]);
    const [loading, setLoading] = useState(true);
    const getData = async (forms: FormItemProps[], lang: string) => {
        edit.onStart();
        const info = forms.map((el: any) => ({...el, trang_thai: 1}));
        const res = await getDataByFromIdsAndDataIds(info, lang);
        if (res.status) {
            edit.onFinish(res.result);
        }
        else {
            edit.onError();
        }
    }
    const getTabCategory = async (itemKey: IItemKey, lang: string) => {
        const keyRefMap = [...(itemKey.reference || []), ...itemKey.map];
        let formId: any[] = [];
        const tempRef = COMMON_FNC.getListFormIdReference(keyRefMap, lang);
        if (tempRef.length > 0) {
            formId = [...formId, ...tempRef];
            const res = await getDataFieldSelectByIds(formId);
            if (res.status) {
                setTabCategory(COMMON_FNC.creeateCategory(res.result, lang));
            }
        }
    }
    const getSelectCategory = async (itemKey: IItemKey, lang: string) => {
        const keyAddressMap = itemKey.address || [];
        let formId: any[] = [];
        const tempAddress = COMMON_FNC.getListFormIdReference(keyAddressMap, lang);
        if (tempAddress.length > 0) {
            formId = [...formId, ...tempAddress];
            const res = await getDataFieldSelectByIds(formId);
            if (res.status) {
                setSelectCategory(COMMON_FNC.creeateCategory(res.result, lang));
            }
        }
    }
    const changeTab = (activeKey: any) => {
        setLoading(true);
        let arrData = [];
        if (edit.data && "vi") {
            const selected = selectTab.find((el: any) => (el.tab === activeKey));
            if (!selected) {
                arrData = COMMON_FNC.changeTab(activeKey, edit.data, "vi");
            } else {
                arrData = COMMON_FNC.changeSelect(activeKey, edit.data, "vi", selected.select);
            }
        }
        setDataFilter(arrData);
        setActiveKey(activeKey);
        setLoading(false);
    }

    const changeSelect = (activeKey: string, selectKey: any, option: any) => {
        setLoading(true);
        let arrData = [];
        if (edit.data && "vi") {
            if (!selectKey) {
                setSelectTab((el: any[]) => el.filter((iEl: any) => iEl.tab !== activeKey));
                arrData = COMMON_FNC.changeTab(activeKey, edit.data, "vi");
            } else {
                const selected = selectTab.find((el: any) => (el.tab === activeKey));
                if (!selected) {
                    setSelectTab((el: any[]) => [...el, { tab: activeKey, select: selectKey }]);
                } else {
                    setSelectTab((el: any[]) => el.map((iEl: any) => iEl.tab === activeKey ? { ...iEl, select: selectKey } : iEl));
                }
                arrData = COMMON_FNC.changeSelect(activeKey, edit.data, "vi", selectKey);
            }
        }
        setDataFilter(arrData);
        setLoading(false);
    }

    useEffect(() => {
        handleMap(dataFilter, activeKey);
    }, [dataFilter, activeKey]);

    useEffect(() => {
        itemKey && "vi" && getTabCategory(itemKey, "vi");
        itemKey && "vi" && getSelectCategory(itemKey, "vi");
        formData && "vi" && getData(formData["vi"], "vi");
    }, [formData, lang]);

    useEffect(() => {
        changeTab(tabCategory[0]?.value || "");
        if (edit.data) {
            let dataMapTemp: any = {};
            tabCategory.map((tb: any) => {
                dataMapTemp[tb.value] = COMMON_FNC.getListMap(tb.value, (itemKey.map || []), "vi", edit.data);
            })
            setDataMap(dataMapTemp);
        }
    }, [tabCategory, edit.data, lang]);

    const handleMap = (data: any[], activeKey: string) => {
        let dataSelected: any[] = [];
        data.map((mp: any) => {
            const find = (dataMap[activeKey] || []).find((fl: any) => fl.id === mp.id);
            find && dataSelected.push([find.value.lat, find.value.lng]);
        })
        setMapsSelected(dataSelected.length > 0 ? dataSelected : [[0, 0]]);
    }

    return <Fragment>
        <AntCard
            size="small"
            className="preview-page-news mb-10"
            bordered={false}
            headStyle={{ color: title?.color, backgroundColor: title?.bgColor, fontSize: title?.fontSize || 25 }}
        >
            {
                <DisplayType1
                    loading={loading} itemKey={itemKey} lang={lang} changeTab={changeTab}
                    tabCategory={tabCategory} selectCategory={selectCategory} activeKey={activeKey}
                    dataFilter={dataFilter || []} changeSelect={changeSelect} handleMap={handleMap}
                    mapsSelected={mapsSelected} />
            }
        </AntCard>
    </Fragment >
}

interface IProps {
    item: any;
    lang: string;
    keys: string[];
    loading?: boolean;
    activeKey?: string;
    handleMap?: any;
}

interface IImageProps extends IProps {
    size: "large" | "default" | "small"
}

const Image: FC<IImageProps> = ({ item, lang, keys, loading, size }) => {
    return <div style={{ background: "#f5f5f5", textAlign: 'center' }}>
        {loading ? <AntSkeleton.Avatar
            active
            shape="square"
            style={size === "large" ? { height: 200, width: 200 } : { height: 100, width: 100 }}
        /> :
            <AntAvatar
                shape="square"
                style={size === "large" ? { height: 200, width: 200 } : { height: 100, width: 100 }}
                src={COMMON_FNC.getImageUrl(item, lang, keys)}
            />
        }
    </div>
}

const Title: FC<IProps> = ({ item, lang, keys, activeKey, handleMap }) => {
    const [_href, _setHref] = useState<string>("");
    return <Fragment>
        <a href="#" onClick={() => handleMap([item], activeKey || "")}>
            {COMMON_FNC.getValue(item, lang, keys)}
        </a>
    </Fragment>
}

const DisplayType1: FC<IDisplayType> = ({
    loading,
    lang,
    itemKey,
    tabCategory,
    selectCategory,
    activeKey,
    changeSelect,
    dataFilter,
    changeTab,
    handleMap,
    mapsSelected
}) => {
    return <AntRow>
        <AntCol md={{ span: 18 }} sm={{ span: 16 }} xs={{ span: 24 }}>
            <Suspense fallback={<AntAlert type="loading" message="" />}>
                <MapMarker dataMap={mapsSelected} style={{ height: "100%" }}/>
            </Suspense>
        </AntCol>
        <AntCol md={{ span: 6 }} sm={{ span: 8 }} xs={{ span: 24 }} className="px-5">
            {(tabCategory || []).length === 0 ?
                <AntRow>
                    <AntTabs defaultActiveKey="1" style={{ width: "100%" }}>
                        <AntTabs.TabPane tab={<span><AntSkeleton.Button active={false} size="small" shape="square" /></span>} key="1">
                            <AntSelect disabled style={{ width: '100%' }} className="mt-10 mb-10" />
                            <AntList
                                dataSource={list}
                                itemLayout="horizontal"
                                renderItem={item => <AntList.Item>
                                    <AntSkeleton
                                        loading
                                        avatar={{
                                            shape: "square"
                                        }}
                                    />
                                </AntList.Item>}
                                footer={undefined}
                            />
                        </AntTabs.TabPane>
                    </AntTabs>
                </AntRow> :
                <AntRow>
                    <CommonScrollBar height="calc(100vh - 50px)" style={{ height: "inherit", width: "100%" }}>
                        <AntTabs defaultActiveKey={activeKey} onChange={(activeKey) => changeTab(activeKey)}>
                            {(tabCategory || []).map(mp => {
                                return <AntTabs.TabPane tab={mp.label} key={mp.value}>
                                    <AntSelect
                                        allowClear
                                        options={selectCategory}
                                        style={{ width: "100%" }}
                                        className="mt-10 mb-10"
                                        onChange={(value, option) => changeSelect(activeKey, value, option)}
                                        onClear={() => changeTab(activeKey)}
                                    />
                                    <AntList
                                        dataSource={loading ? list : (dataFilter || [])}
                                        itemLayout="horizontal"
                                        locale={{
                                            emptyText: <AntEmpty description="Không có dữ liệu" />
                                        }}
                                        renderItem={(item, i) => loading ? <AntList.Item>
                                            <AntSkeleton
                                                active
                                                loading
                                                avatar={{ shape: "square" }}
                                            />
                                        </AntList.Item> :
                                            <AntList.Item key={i}>
                                                <AntList.Item.Meta
                                                    avatar={<AntSkeleton.Avatar
                                                        shape="square"
                                                        style={{ height: 100, width: 100 }}
                                                    />}
                                                    title={<Title item={item} lang={lang} keys={itemKey.title} activeKey={activeKey} handleMap={handleMap} />}
                                                />
                                            </AntList.Item>}
                                    />
                                </AntTabs.TabPane>
                            })}
                        </AntTabs>
                    </CommonScrollBar>
                </AntRow>}
        </AntCol>
    </AntRow >
}

export default PreviewPage;