import { orderBy } from "lodash";
import { ActionProps } from "..";

interface FieldDataProps {
    id?: number;
    name?: string;
    description?: string;
    fieldOptionText?: string;
    fieldType?: string;
    delete?: boolean;
}

interface FormProps {
    id?: number;
    name?: string;
}
interface FormDongBoProps {
    id?: number;
    optionDongBo?: object;
}
export interface ModelContentFieldOptionProps {
    form?: FormProps,
    formField?: FieldDataProps[],
}

const stateDefault: Array<ModelContentFieldOptionProps> = [];
const fileds = (state: ModelContentFieldOptionProps[] = stateDefault, action: ActionProps): ModelContentFieldOptionProps[] => {
    const type = action.type;
    switch (type) {
        case 'FIELD_LOAD':
            const fieldData = action.payload as ModelContentFieldOptionProps[];
            return fieldData.map(item => ({ form: item.form, formField: orderBy(item.formField || [], "sort", "asc") }));
        case "MODEL_CREATE":
            const modelCreate = action.payload as FormProps;
            return [...state, { form: modelCreate, formField: [] }];
        case "MODEL_UPDATE":
            const modelUpdate = action.payload as FormProps;
            return state.map(item => item.form?.id === modelUpdate.id ? { form: modelUpdate, formField: item.formField } : item);
        case "MODEL_DELETE":
            const idDeletes = action.payload as number[];
            return state.filter(item => item.form?.id && !idDeletes.includes(item.form?.id));
        case "FORM_FIELD_SORT":
            const formFiledSort = action.payload as ModelContentFieldOptionProps;
            return state.map(item => item.form?.id === formFiledSort.form?.id ? formFiledSort : item);
        case "FIELD_CREATE":
            const fieldCreate = action.payload as { formId: number; formfield: FieldDataProps }
            return state.map(item => item.form?.id === fieldCreate.formId ? { form: item.form, formField: [...(item.formField || []), fieldCreate.formfield] } : item);
        case "FIELD_UPDATE":
            const fieldUpdate = action.payload as { formId: number; formfield: FieldDataProps }
            return state.map(item => item.form?.id === fieldUpdate.formId ? { form: item.form, formField: (item.formField || []).map(field => field.id === fieldUpdate.formfield.id ? fieldUpdate.formfield : field) } : item);
        case "FIELD_DELETE":
            const fieldDelete = action.payload as { formId: number; fieldIds: number[] }
            return state.map(item => item.form?.id === fieldDelete.formId ? { form: item.form, formField: (item.formField || []).filter(field => field.id && !fieldDelete.fieldIds.includes(field.id)) } : item);
        case "MODEL_FIELD_CREATE":
            const modelFieldCreate = action.payload;
            return [...state, { form: modelFieldCreate.form, formField: modelFieldCreate.formField }];
        case "MODEL_FIELD_UPDATE":
            const modelFieldUpdate = action.payload;
            return state.map(item => item.form?.id === modelFieldUpdate.form.id ? { form: modelFieldUpdate.form, formField: modelFieldUpdate.formField } : item);
        case "MODEL_UPDATE_OPTION_DONG_BO":
            const modelUpdateDongBo = action.payload as FormDongBoProps;
            return state.map(item => item.form?.id === modelUpdateDongBo.id ? { form: {...item.form, optionDongBo: modelUpdateDongBo?.optionDongBo} , formField: item.formField } : item);
        default:
            return state;
    }
}
export default fileds