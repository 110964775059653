import React, { Fragment, CSSProperties } from "react";
import { AntLayout, AntCard, CommonForm, useList, AntRow, AntCol, AntList, AntSkeleton } from '../../../../../../../../common';
import { CommonButtonAction } from '../../../../../../../../common/c-action'
import moment from "moment";
import { DateFormat } from "../../../../../../../../common/c-form";
const contentStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: "#FFFFFF"
};
export const DATE_FORMAT = "DD/MM/YYYY";
const DesignPage = () => {
    const list = useList();
    return <Fragment>
        <AntLayout style={{ background: "#FFFFFF" }}>
            <AntRow>
                <AntCol span={24} style={contentStyle}>
                    <AntList.Item >
                        <AntSkeleton
                            loading
                        />
                    </AntList.Item>
                </AntCol>
            </AntRow>
        </AntLayout>

    </Fragment>
}
export default DesignPage;