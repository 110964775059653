import React, { FC, Fragment } from "react";
import { AntCard, AntCol, AntRow } from "../../../../../../../common";

interface IProps { }
const Profile: FC<IProps> = () => {
    return <Fragment>
        <AntRow className="mt-10">
            <AntCol
                className="px-10"
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
            >
                <AntCard
                    hoverable
                    cover={<div style={{ textAlign: "center" }}>
                        <img
                            style={{
                                width: 200
                            }}
                            src="/static/img/avatar-default.png"
                        />
                    </div>}
                >

                </AntCard>
            </AntCol>
        </AntRow>
    </Fragment>
}
export default Profile;