import { ReactNode } from "react";
import admin from "./admin";

export interface RouteProps {
    pageTitle?: ReactNode;
    path?: string | string[];
    firstChildPath?: string;
    componentPath?: string;
    children?: Array<RouteProps>;
    props?: { [key: string]: any };
    fullScreen?: boolean;
    hidePageTitle?: boolean;
    collapsed?: boolean;
    checkAuth?: boolean
}

export default [
    ...admin
] as Array<RouteProps>;

const findFirstChildPath = (item: RouteProps): string | undefined => {
    let res;
    if (item.componentPath) {
        res = item.path;
    }
    else if (item.children && item.children[0]) {
        res = findFirstChildPath({ ...item.children[0], path: `${item?.path ? `/${item.path}` : ""}${item.children[0]?.path ? `/${item.children[0].path}` : ""}` });
    }
    return res ? `${res}` : undefined;
}
interface FlattenRouteResultProps extends Omit<RouteProps, "path"> {
    path?: string;
}
export const flattenRoute = (children?: Array<RouteProps>, parent?: RouteProps): Array<FlattenRouteResultProps> => Array.prototype.concat.apply(
    convertPathString((children || [])).map(x => ({
        ...x,
        pageTitle: x.pageTitle || parent?.pageTitle,
        path: `${parent?.path ? `/${parent.path}` : ""}${x?.path ? `/${x.path}` : ""}${(x.children || []).length === 0 ? `.html` : ``}`,
        parent,
        firstChildPath: x.componentPath ? undefined : (findFirstChildPath(x) ? `${findFirstChildPath(x)}.html` : undefined)
    })),
    convertPathString((children || [])).map(x => flattenRoute(x.children || [], {
        ...x,
        pageTitle: x.pageTitle || parent?.pageTitle,
        path: `${parent?.path ? `${parent.path}` : ""}${x?.path ? `${parent?.path ? "/" : ""}${x.path}` : ""}`,
        firstChildPath: x.componentPath ? undefined : (findFirstChildPath(x) ? `${findFirstChildPath(x)}.html` : undefined)
    }))
);

const convertPathString = (children?: Array<RouteProps>): FlattenRouteResultProps[] => {
    const res: FlattenRouteResultProps[] = []
    children?.map(child => {
        if (Array.isArray(child.path)) {
            res.push({
                ...child,
                path: undefined
            })
            child.path.map(path => {
                res.push({
                    ...child,
                    path
                })
                return res;
            })
        }
        else {
            const path = child.path as string | undefined;
            res.push({
                ...child,
                path
            });
        }
        return res;
    })
    return res;
}