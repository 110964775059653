import { ActionProps } from "..";

export interface ItemProps {
    loaded: boolean,
    page_size: number
}

const stateDefault: ItemProps = {
    loaded: false,
    page_size: 10
}

const pagination = (state: ItemProps = stateDefault, action: ActionProps): ItemProps => {
    const type = action.type;
    switch (type) {
        case 'PAGINATION':
            const data = action.payload as number;
            return {
                loaded: true,
                page_size: data
            };
        default:
            return state;
    }
}

export default pagination;