import React, { CSSProperties, Fragment, useRef } from "react";
import { AntCarousel, AntImage, AntSkeleton } from '../../../../../../../../common';
const contentStyle: CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const btnStyle: CSSProperties = {
    position: "absolute",
    top: "calc(50% - 50px)",
    bottom: "calc(50% - 50px)",
    width: 50,
    background: "#00000036",
    zIndex: 1,
    color: "#fff",
    cursor: "pointer",
    lineHeight: "120px",
    textAlign: "center"
}
const DesignPage = () => {
    return <Fragment>
        <AntCarousel style={{ width: "100%", background: "#fff", padding: 20 }}>
            {
                [1, 2, 3, 4].map((item, i) => <div key={i}>
                    <AntImage width={200} height={200} />
                    <AntSkeleton
                    />
                </div>)
            }
        </AntCarousel>
    </Fragment>
}

export default DesignPage;