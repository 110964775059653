import { combineReducers, Reducer, CombinedState } from "redux";
import fileds, {
    ModelContentFieldOptionProps as FieldStateProps,
} from "./fields";
export default combineReducers({
    fileds
}) as Reducer<CombinedState<ModelFieldState>>;
export interface ModelFieldState {
    fileds: FieldStateProps[];
}
