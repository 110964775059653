import React, { CSSProperties, Fragment } from 'react';
import { AntCol, AntLayout, AntList, AntRow, AntSelect, AntSkeleton } from '../../../../../../../../common';

const contentStyle: CSSProperties = {

    padding: '8px',
    backgroundColor: "#FFFFFF"
};
const DesignPage = () => {
    return <Fragment>
        <AntLayout style={contentStyle}>
            {/* <AntSkeleton avatar /> */}
            <AntRow style={{ textAlign: "center" }}>
                <AntCol span={4}>
                    <AntSkeleton.Input active={false} size="large" style={{ width: 200 }} />
                </AntCol>
                <AntCol span={4}>
                    <AntSkeleton.Input active={false} size="large" style={{ width: 200 }} />
                </AntCol>
                <AntCol span={4}>
                    <AntSkeleton.Input active={false} size="large" style={{ width: 200 }} />
                </AntCol>
                <AntCol span={4}>
                    <AntSkeleton.Input active={false} size="large" style={{ width: 200 }} />
                </AntCol>
                <AntCol span={4}>
                    <AntSkeleton.Input active={false} size="large" style={{ width: 200 }} />
                </AntCol>

            </AntRow>
            <AntRow>
                <AntCol span={11} style={{ marginRight: "60px" }}>
                    <AntSkeleton />
                </AntCol>
                <AntCol span={11}>
                    <AntSkeleton />
                </AntCol>
            </AntRow>
        </AntLayout>
    </Fragment>
};

export default DesignPage;