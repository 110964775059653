import { combineReducers, Reducer, CombinedState } from "redux";
import pages, { PageProps, PageProps as PagesStateProps } from "./pages";
export default combineReducers({
    pages,
}) as Reducer<CombinedState<PageBuilderState>>;
export interface PageBuilderState {
    pages: PagesStateProps[],
}

export const findPage = (pages: PageProps[], pageId: number): PageProps | undefined => {
    let result: PageProps | undefined;
    if (pages.find(page => page.page.id === pageId)) {
        return pages.find(page => page.page.id === pageId);
    }
    else if (pages.length > 0) {
        pages.map(page => {
            if (page.pageChildList && findPage(page.pageChildList, pageId)) {
                result = findPage(page.pageChildList, pageId);
            }
            return result;
        })
        return result;
    }
    return result;
}