import React, { Fragment } from "react";
import { GiaTriProps } from "./preview.page";
import { AntRow, AntCol, AntSkeleton } from "../../../../../../../../common";

const DesignPage = ({ giaTri = {} }: any) => {
  const _giaTri: GiaTriProps = giaTri || {};
  const { name } = _giaTri;
  return (
    <Fragment>
      <AntRow>
        <AntCol span={24}>
          <AntSkeleton loading />
        </AntCol>
      </AntRow>
    </Fragment>
  );
};

export default DesignPage;
