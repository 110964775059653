
import { useForm } from 'antd/lib/form/Form';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Form from './form';
import { create, getAllDataFormbuilder, getAllFormbuilder } from '../../../../../../data-form-builder/api';
import { AntButton, AntCard, AntMessage } from '../../../../../../../../common';
import moment from 'moment';

interface ItemKeys {
    ngayLapBaoCao: number,
    doanhNghiep: number,
    coSoLuuTru: number,
    ketQua: number,
    trangThai: number,
}
interface FormBaoCao {
    formId: number,
    idBaoCao: number,
}
interface ItemKeyDN {
    formDN: number,
    ten: number,
    trangThai: number,
    ma: number
}
export interface GiaTriProps {
    itemKeys: ItemKeys,
    formBaoCao: FormBaoCao,
    itemKeyDN: ItemKeyDN,
}

const Create = ({ list, giaTri, maDoanhNghiep }: any) => {
    const [data, setData] = useState({});
    const _giaTri: GiaTriProps = giaTri || {};
    const { itemKeys, formBaoCao, itemKeyDN } = _giaTri;
    const history = useHistory();
    const [formDN, setFormDN] = useState({});
    const [form] = useForm();
    const localizations = useSelector((state: any) => state.config.localizations.data).map((el: any) => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";
    const local = [...localizations].find((el) => { if (el.default) return el; })
    const [doanhNghiep, setDoanhNghiep]: any = useState([]);
    const trangThai = [
        {
            value: "dadongbo",
            label: "Đã đồng bộ"
        },
        {
            value: "taomoi",
            label: "Tạo mới"
        }
    ]

    useEffect(() => {
        const getallform = async () => {
            list.onLoading();
            const res = await getAllFormbuilder();
            if (res.status) {
                let dt = res.result.find((item: any) => item.form.id === itemKeyDN.formDN)
                setFormDN({
                    id: dt.form.id,
                    name: dt.form.name
                })
            }
        }
        getallform()
    }, [itemKeyDN.formDN])

    useEffect(() => {
        getform(itemKeyDN.formDN, (keys: any) => setDoanhNghiep(keys))
    }, [itemKeyDN.formDN])

    const getform = async (item: any, callback: any) => {
        const data = {
            formId: item,
            localization: localizationSearch,
            trangThai: "1"
        }
        list.onLoading()
        const res = await getAllDataFormbuilder(data)
        if (res.status) {
            let keys = res.result.data
            callback && callback(keys);
            list.onSuccess();
        }
        else {
            list.onError(res.msg);
        }
    }

    const onSubmit = async (e: any) => {
        const dataValue = {
            form_id: formBaoCao.formId.toString(),
            data_formbuilder: {
                [`${itemKeys.ngayLapBaoCao}`]: e.ngayLapBaoCao ? e.ngayLapBaoCao.toISOString() : undefined,
                [`${itemKeys.trangThai}`]: e.status ? e.status : undefined,
                [`${itemKeys.ketQua}`]: JSON.stringify(data),
                [`${itemKeys.doanhNghiep}`]: [{ ...formDN, data: e.doanhNghiep ? [e.doanhNghiep.toString()] : undefined }],
                [`${localizationSearch}_${itemKeys.ngayLapBaoCao}`]: e.ngayLapBaoCao ? e.ngayLapBaoCao.toISOString() : undefined,
                [`uni_hidden_select_${itemKeys.trangThai}`]: trangThai.find((item: any) => item.value === e.status)?.label,
                [`uni_hidden_select_vi_${itemKeys.trangThai}`]: trangThai.find(item => item.value === e.status)?.label,
                [`${localizationSearch}_${itemKeys.trangThai}`]: e.status ? e.status : undefined,
                [`${localizationSearch}_${itemKeys.ketQua}`]: JSON.stringify(data),
                [`${localizationSearch}_${itemKeys.doanhNghiep}`]: [{ ...formDN, data: e.doanhNghiep ? [e.doanhNghiep.toString()] : undefined }],
            },
            localizations: [local],
            tinh_trang: 1,
            trang_thai: 1
        }
        list.onLoading();
        const res = await create(dataValue);
        if (res.status) {
            list.setData((item: any) => [
                ...item,
                res.result
            ])
            form.resetFields(['doanhNghiep', 'status', 'ngayLapBaoCao']);
            setData({});
            AntMessage.success(res.msg)
            history.goBack()
            list.onSuccess();
        } else {
            list.onError(res.msg)
            AntMessage.error(res.msg)
        }
    }

    return <Fragment>
        <AntCard title="THÊM MỚI BÁO CÁO"
            extra={
                <Fragment>
                    <AntButton type="primary" icon={<i className="fa fa-save mr-5" />} onClick={() => form.submit()} className="mr-5">Lưu</AntButton>
                    <AntButton type="default" icon={<i className="fa fa-reply mr-5" />} onClick={() => history.goBack()}> Quay lại</AntButton>
                </Fragment>
            }
        >
            <Form
                data={data}
                setData={setData}
                doanhNghiep={doanhNghiep}
                id={formBaoCao.idBaoCao}
                form={form}
                onSubmit={onSubmit}
                itemKeyDN={itemKeyDN}
                initialValue={{
                    ngayLapBaoCao: moment(),
                    status: "taomoi",
                    doanhNghiep: doanhNghiep.find((item: any) => item.data_formbuilder[`${itemKeyDN.ma}`] === maDoanhNghiep)?.id || ""
                }}
            />
        </AntCard>
    </Fragment>
};

export default Create;