
import { get, post, put, del, ResponseProps } from '../../../../../.fetch'
import { Base64 } from '../../../../../common';
import { PageProps, PageItemProps } from "./.././../../../../reducers/page-builder/pages";

interface pageBuilderOptionProps {
    id?: number;
    buildOption?: any;
    buildOptionPrivate?: any;
    optionId: string;
    sort: number;
}
interface PageBuilderContentProps {
    id?: number;
    page?: PageItemProps;
    pageBuilderOption: pageBuilderOptionProps[]
}
export interface ItemProps extends Omit<PageProps, "pageBuilderContent" | "pageChildList"> {
    pageBuilderContent: PageBuilderContentProps[],
    pageChildList?: ItemProps[]
}
const api: string = '/pagebuilder';

interface FetchAllResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps[]
}
export const fetchAll = async (): Promise<FetchAllResponseProps> => await get({
    url: `${api}/pagetree`
})

interface FindByIdResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps
}
export const findbyId = async (id: number): Promise<FindByIdResponseProps> => await get({
    url: `${api}/getpage/${id}`
})

export const findbyUrl = async (url: string): Promise<FindByIdResponseProps> => await get({
    url: `${api}/getbyurl/${Base64.encode(url)}`
})

interface CreateResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps
}
export const create = async (data: ItemProps): Promise<CreateResponseProps> => await post({
    url: `${api}/create`,
    data
})

interface UpdateResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps
}
export const update = async (data: ItemProps): Promise<UpdateResponseProps> => await put({
    url: `${api}/update`,
    data
})

export const _delete = async (data: number[]) => await del({
    url: `${api}/delete`,
    data
})
