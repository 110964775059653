import React, { CSSProperties, FC, ReactNode, useEffect, useState } from "react";
import { download } from "../../.fetch";
import { AntAlert } from "../antd";
import { getAuth } from "../c-auth";

interface FileInfoProps {
    url: string;
    type: string;
    fileName: string;
}

interface DocumentPreviewProps {
    documentId: number;
}
const usePreviewFile = ({ documentId }: DocumentPreviewProps): FileInfoProps | "loading" | "error" => {
    const [fileInfo, setFileInfo] = useState<FileInfoProps | "loading" | "error">("loading")
    useEffect(() => {
        const getData = async () => {
            const auth = getAuth();
            setFileInfo("loading");
            const res = await download({
                url: `/cms/cmis/document/download/${documentId}`,
                token: auth.token,
                responseType: "onlyFileInfo"
            })
            setFileInfo(res.fileInfo);
        }
        getData();
    }, [])
    return fileInfo;
}

export default usePreviewFile;