import React from "react";
import Component from "./component";
import DesignSider from "./design.sider";
import DesignPage from "./design.page";
import PreviewPage from "./preview.page";
import { ComponentDataProps } from "..";
const ComponentForm: ComponentDataProps = {
    title: "Camera360",
    ma: "camera360",
    siderLabel: DesignSider,
    pageLabel: DesignPage,
    previewPage: PreviewPage,
    component: <Component />,
    View: Component
}
export default ComponentForm;