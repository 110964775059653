import { RouteProps } from "./../";

const routes: Array<RouteProps> = [
    {
        path: "form-builder",
        pageTitle: "Cấu trúc",
        children: [
            {
                path: ["model/:module_id"],
                componentPath: "components/builder/form-builder/form",
            }
        ]
    },
    {
        path: 'page-builder',
        pageTitle: "Trang",
        children: [
            {
                path: "page",
                children: [
                    {
                        path: [":parent_id", "them-moi", "them-moi/:parent_id", ":parent_id/cap-nhat/:id"],
                        componentPath: "components/builder/page-builder/page",
                    }
                ]
            },
            {
                path: "builder",
                children: [
                    {
                        path: [":page_id"],
                        collapsed: true,
                        componentPath: "components/builder/page-builder/builder/main",
                        hidePageTitle: true
                    },
                ]
            },

        ]
    },
    {
        path: 'menu/siderbar',
        pageTitle: 'Menu',
        children: [
            {
                componentPath: 'components/builder/menu'
            },
            {
                path: ':id',
                componentPath: 'components/builder/menu'
            }
        ]
    },
    {
        path: 'data-form-builder',
        pageTitle: "Nội dung",
        children: [
            {
                path: [":formRootId/data", ":formRootId/them-moi", ":formRootId/cap-nhat/:dataId"],
                componentPath: "components/builder/data-form-builder",
            }
        ]
    },
    {
        path: "file",
        pageTitle: "Tài liệu",
        children: [
            {
                path: [":folder_id"],
                componentPath: "components/builder/file",
            }
        ]
    },
    {
        path: "web-content",
        pageTitle: "Khám phá",
        children: [
            {
                path: [":parent_id", "them-moi/:parent_id", "cap-nhat/:content_id/:parent_id"],
                componentPath: "components/builder/web-content",
            }
        ],
    },
    {
        path: "manage-module/module",
        pageTitle: "Module",
        children: [
            {
                path: [":cauhinh_id", "cau-hinh/:cauhinh_id"],
                componentPath: "components/builder/manage-module/module",
            }
        ],
    },
    {
        path: "manage-module/group",
        pageTitle: "Nhóm Module",
        children: [
            {
                path: [":parent_id", "them-moi/:parent_id", "cap-nhat/:content_id/:parent_id"],
                componentPath: "components/builder/manage-module/group",
            }
        ],
    },
    {
        path: "form-css",
        pageTitle: "Quản lí Form css",
        componentPath: "components/builder/form-css",
    },
    {
        path: "settings",
        pageTitle: "Tuỳ biến",
        children: [
            {
                componentPath: "components/builder/settings",
            },
            {
                path: ":id",
                componentPath: "components/builder/settings",
            },
        ]
    }
]
export default routes;