import { parse, stringify } from "qs";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AntModal } from "../../../../../../../../common";
import { FormKeys, forms } from "../../forms";

interface ITitle {
  label?: string;
  hidden?: boolean;
}
export interface GiaTriProps {
  name?: string;
  form: FormKeys;
  title?: ITitle;
  visible?: {
    key: string;
    value: string;
  };
}

const DesignPage = ({ giaTri = {} }: any) => {
  const { pathname, search, hash } = useLocation();
  const history = useHistory();
  const qs = parse(search, { ignoreQueryPrefix: true }) as {
    [key: string]: string;
  };
  const _giaTri: GiaTriProps = giaTri || {};
  const { visible, title, form } = _giaTri;
  const [body, setBody] = useState<any>(null);
  useEffect(() => {
    const Body = forms.find((f) => f.key === form);
    setBody(
      Body ? (
        <Body.component
          title={!title?.hidden && title?.label}
          closable={!Boolean(title?.hidden)}
          visible={Boolean(
            visible?.key && visible?.value && qs[visible?.key] === visible.value
          )}
          onCancel={() => {
            visible?.key &&
              visible?.value &&
              history.replace({
                search: stringify({ ...qs, [visible.key]: undefined }),
              });
          }}
        />
      ) : null
    );
  }, [form, visible, title, visible?.key && qs[visible?.key]]);
  return (
    <Fragment>
      {body ? (
        body
      ) : (
        <AntModal
          title={!title?.hidden && title?.label}
          closable={!Boolean(title?.hidden)}
          visible={Boolean(
            visible?.key && visible?.value && qs[visible?.key] === visible.value
          )}
          onCancel={() => {
            visible?.key &&
              visible?.value &&
              history.replace({
                search: stringify({ ...qs, [visible.key]: undefined }),
              });
          }}
        >
          {body}
        </AntModal>
      )}
    </Fragment>
  );
};

export default DesignPage;
