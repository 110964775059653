import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { AntCol, AntRow } from "../../../../../../../../common";
import { GiaTriProps, BoxProps } from "./preview.page";

const DesignPage = ({ giaTri = {} }: any) => {
    const _giaTri = giaTri as GiaTriProps;
    const { listBox, displayType, title } = _giaTri;
    const boxLength = (listBox || []).filter(item => !item.hidden).length;
    const span = parseInt(`${boxLength > 0 ? 24 / boxLength : 0}`, 0)
    return <Fragment>
        <div className="px-10">
            <AntRow justify="center">
                <AntCol span={span * boxLength}>
                    {!title?.hidden && <div style={{ color: title?.color, backgroundColor: title?.bgColor, fontSize: title?.fontSize }}>{title?.label}</div>}
                </AntCol>
            </AntRow>
            <AntRow
                gutter={[16, 16]}
                className="py-10"
                justify="center"
            >
                {
                    (listBox || [])
                        .filter(item => !item.hidden)
                        .map((item, i) => {
                            switch (displayType) {
                                case 2:
                                    return <ItemType2 box={item} key={i} span={span} />
                                default:
                                    return <ItemType1 box={item} key={i} span={span} />
                            }
                        })
                }

            </AntRow>
        </div>
    </Fragment>
}

const ItemType1: FC<{ box: BoxProps, span: number }> = ({ box, span }) => {
    return <AntCol span={span}>
        <span style={{ display: "inline-block", color: box.color, backgroundColor: box.bgColor, height: "100%", width: "100%", borderRadius: 5 }} className="p-20">
            <div style={{ textAlign: "center" }}>
                <i className={`${box.iconCls} fa-3x`} />
            </div>
            <div style={{ textAlign: "center", fontSize: 25 }} className="mt-10">
                {box.title}
            </div>
            <div style={{ textAlign: "justify" }}>
                {box.description}
            </div>
        </span>
    </AntCol>
}

const ItemType2: FC<{ box: BoxProps, span: number }> = ({ box, span }) => {
    return <AntCol span={24}>
        <span style={{ display: "flex", color: box.color, backgroundColor: box.bgColor, height: "100%", width: "100%", borderRadius: 5 }} className="p-20">
            <div style={{ width: 70, height: "100%" }}>
                <i className={`${box.iconCls} fa-3x`} />
            </div>
            <div style={{ flex: 1 }}>
                <div style={{ fontSize: 25 }}>
                    {box.title}
                </div>
                <div style={{ textAlign: "justify" }}>
                    {box.description}
                </div>
            </div>
        </span>
    </AntCol>
}

export default DesignPage;