import React, { FC, Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import { FCMainProps, RowProps } from "./types";
import { TableColumnType } from "antd";
import Search from "./search"
import { uuid } from "..";
import { AntTable } from "./../"
import { CommonButtonAction } from "../c-action";
import { AntAlert, AntButton, AntCard, AntEmpty, AntTooltip, AntBadge } from "../antd";
import { countLevel } from "../fnc";
import { useLocation } from "react-router-dom";
import { parse } from "qs";
import HeaderCell from "./header-cell";


const Main: FC<FCMainProps> = ({
    tblResizeID,
    id,
    columns = [],
    showIndex = true,
    dataSource,
    actions = [],
    search,
    list,
    rowSelection,
    rowClassName,
    jumLastPageHidden,
    jumFirstPageHidden,
    containerTag,
    scroll,
    showStatus,
    searchExtends,
    disabledCheckbox,
    ...props
}) => {
    const location = useLocation();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const newDataSource = Array.isArray(dataSource) ? dataSource : list?.getData() || [];
    const selectedRows = rowSelection?.selectedRowKeys || list?.getSelectedRows() || [];
    const newPagination = list?.getPagination();
    const [uid] = useState<string>(id || uuid());

    const sort = qs.sort as string;;
    const _s = (sort || "")
        .split(",")
        .filter(item => {
            const kv = (item || "").split(" ");
            return kv[0] && (kv[1] === "asc" || kv[1] === "desc")
        })
        .map(item => {
            const kv = (item || "").split(" ");
            return { key: kv[0], value: kv[1] }
        });
    const getSortOrder = (dataIndex: string) => {
        const sort = _s.find(item => item.key === dataIndex);
        return sort && (sort.value === "asc" ? "ascend" : "descend");
    }

    useEffect(() => {
        try {
            if (jumFirstPageHidden) {
                const jumpPrev = document.querySelector(`#container-${uid} .ant-pagination>.ant-pagination-jump-prev`);
                const firstPage = document.querySelector(`#container-${uid} .ant-pagination>.ant-pagination-item-1`);
                if (Boolean(jumpPrev)) {
                    firstPage && firstPage.classList.add("ant-pagination-hide");
                }
                else {
                    firstPage && firstPage.classList.remove("ant-pagination-hide");
                }
            }
            if (jumLastPageHidden) {
                const total = newPagination?.total || 0;
                const pageSize = newPagination?.pageSize || 0;
                const totalPage = total && pageSize ? Math.ceil(total / pageSize) : 0;
                const jumpNext = document.querySelector(`#container-${uid} .ant-pagination>.ant-pagination-jump-next`);
                const lastPage = document.querySelector(`#container-${uid} .ant-pagination>.ant-pagination-item-${totalPage}`);
                if (Boolean(jumpNext)) {
                    lastPage && lastPage.classList.add("ant-pagination-hide");
                }
                else {
                    lastPage && lastPage.classList.remove("ant-pagination-hide");
                }
            }
        }
        catch (e) { }
    }, [newPagination, uid, jumLastPageHidden, jumFirstPageHidden])

    const hashQs = parse(location.hash.substr(1));

    const getColumnWidth = useCallback((cellID?: number | string, defaultValue?: number) => {
        const tblColWidthStr = localStorage.getItem("tblColWidth") || "{}";
        let tblColWidth: { [key: string]: number } = {};
        try { tblColWidth = JSON.parse(tblColWidthStr); }
        catch (e) { }
        if (!tblResizeID) {
            return defaultValue;
        }
        const _width = tblColWidth[`${tblResizeID}_${cellID}`];
        return _width || defaultValue;
    }, [hashQs["resize"]]);

    return <Fragment>
        {
            containerTag === "card" ?
                <AntCard
                    size="small"
                    headStyle={{ height: 50 }}
                    bordered={false}
                    title={<Fragment>
                        <div style={{width:"60%"}}>
                        <Search {...search} list={list} />
                        </div>
                        {/* <div style={{ display: "flex" }}>
                            {search !== false && <div style={{ flex: 1, maxWidth: 400, paddingRight: 10 }}>
                                <Search {...search} list={list} />
                            </div>}

                            {searchExtends && <div>{searchExtends}</div>}
                        </div> */}
                    </Fragment>}
                    extra={actions.map(action => <CommonButtonAction {...action} />)}
                >
                    <div id={`container-${uid}`}>
                        <AntTable
                            loading={list?.loading}
                            locale={{ emptyText: <AntEmpty description="Không có dữ liệu" /> }}
                            id={uid}
                            columns={[
                                ...((showStatus && showStatus?.show || false) ? [{
                                    align: "center",
                                    // fixed: "left",
                                    width: 30,
                                    render: (t: any, r: any, i: number) => {
                                        if (r?.[showStatus && showStatus?.type || ""] === 0) {
                                            return <AntTooltip placement="topLeft" color="blue" title="Thư mục">
                                                <i className="fa fa-circle" style={{ color: "#108ee9" }} />
                                            </AntTooltip>
                                        } else {
                                            const valueField = Number(r?.[showStatus && showStatus?.fieldStatus || ""]);
                                            if (isNaN(valueField) || ![0, 1].includes(valueField)) {
                                                return "-";
                                            } else {
                                                return <AntTooltip placement="topLeft" color={valueField === 1 ? "#87d068" : "#f5222d"} title={showStatus?.valueStatus?.[valueField] || ""}>
                                                    <i className="fa fa-circle" style={{ color: valueField === 1 ? "#87d068" : "#f5222d" }} />
                                                </AntTooltip>
                                            }
                                        }
                                    },
                                    shouldCellUpdate: (record, prevRecord) => {
                                        return true;
                                    }
                                } as TableColumnType<RowProps>] : []),
                                ...(showIndex ? [{
                                    title: "STT",
                                    align: countLevel(newDataSource) > 1 ? "left" : "center",
                                    width: 70 + (countLevel(newDataSource) * 15),
                                    fixed: "left",
                                    render: (t: any, r: any, i: number) => {
                                        if (newPagination) {
                                            const { pageSize, current } = newPagination;
                                            if (current !== undefined && pageSize !== undefined) {
                                                return i + current * pageSize - pageSize + 1;
                                            }
                                        }
                                        const { _level, _parentIndex } = r;
                                        return `${_parentIndex && _parentIndex.length > 0 ? `${_parentIndex.join(".")}.` : ""}${i + 1}`;
                                    },
                                    shouldCellUpdate: (record, prevRecord) => {
                                        return true;
                                    }
                                } as TableColumnType<RowProps>] : []),
                                ...columns.map((item: any) => ({
                                    ...item,
                                    width: getColumnWidth(item.cellID, item.width),
                                    sortOrder: getSortOrder(item.dataIndex || ""),
                                    render: (t: ReactNode, r: any, i: number) => {
                                        return item?.render ? item.render(t, r, i) : t;
                                    },
                                    tblResizeID,
                                    shouldCellUpdate: () => true,
                                    onHeaderCell: (col: any) => ({ ...col, title: item.title }),
                                }))
                            ]}
                            bordered
                            rowKey="id"
                            size="small"
                            pagination={newPagination ? {
                                size: "default",
                                showSizeChanger: true,
                                showLessItems: true,
                                ...newPagination
                            } : false}
                            {...props}
                            components={{
                                header: {
                                    cell: (props: any) => <HeaderCell props={props} />
                                }
                            }}
                            onChange={(p, f, s, e) => {
                                if (props.onChange) {
                                    document.getElementById(uid)?.scrollIntoView();
                                    props.onChange(
                                        p,
                                        f,
                                        (s && !Array.isArray(s) ? [s] : s).map(item => ({ ...item, _order: item.order === "ascend" ? "asc" : item.order === "descend" ? "desc" : undefined })),
                                        e
                                    );
                                }
                            }}
                            dataSource={addLevelTreeData(newDataSource)}
                            rowSelection={rowSelection !== false ? {
                                fixed: true,
                                selectedRowKeys: selectedRows.map(item => item.id),
                                onSelectAll: (selected) => {
                                    !selected && list?.unCheckAll();
                                },
                                onSelect: (_, selected) => {
                                    !selected && list?.unCheckAll();
                                },
                                onChange: (keys, rows) => {
                                    if (rowSelection?.type === "radio") {
                                        rowSelection?.onChange && rowSelection.onChange(keys, rows)
                                        list?.setSelectedRows(rows)
                                    }
                                    else {
                                        const _rows = [...selectedRows.filter(item => flattenDataSource(newDataSource).findIndex(d => d.id === item.id) === -1), ...rows]
                                        rowSelection?.onChange && rowSelection.onChange(keys, _rows)
                                        list?.setSelectedRows(_rows)
                                    }

                                },
                                type: rowSelection?.type || "checkbox",
                                getCheckboxProps: (record) => {
                                    if (disabledCheckbox){
                                        switch (disabledCheckbox?.sign) {
                                            case "includes":
                                                return { disabled: (disabledCheckbox?.value).includes(record?.[disabledCheckbox?.columnName || ""]) };
                                            default:
                                                return { disabled: false };;
                                        }
                                    } else {
                                        return { disabled: false };
                                    }
                                },
                            } : undefined}
                            scroll={{
                                x: "100%",
                                ...(scroll || {})
                                // y: "calc(100vh - 200px)"
                            }}
                            rowClassName={(record, index, indent) => {
                                let show = false;
                                if (!list?.getQ()) {
                                    show = true;
                                }
                                else {
                                    const tr = document.querySelector(`tr[data-row-key="${record.id}"]`);
                                    if (tr) {
                                        const q = list.getQ()?.toLowerCase();
                                        tr.childNodes.forEach(td => {
                                            if (
                                                td.textContent
                                                && q
                                                && (q.includes(`${td.textContent}`.toLowerCase()) || `${td.textContent}`.toLowerCase().includes(q))
                                            ) {
                                                show = true;
                                            }
                                        })
                                    }
                                }
                                return `${rowClassName ? (typeof rowClassName === "string" ? `${rowClassName} ` : rowClassName(record, index, indent)) : ""} ${!show ? "row-hidden" : ""}`;
                            }}
                        />
                    </div>
                </AntCard> :
                <Fragment>
                    <div style={{ display: "flex" }} className="mb-10">
                        {search !== false && <div style={{ flex: 1, maxWidth: 400, paddingRight: 10 }}>
                            <Search {...search} list={list} />
                        </div>}
                        <div style={{ flex: "min-content", textAlign: "right" }}>
                            {actions.map(action => <CommonButtonAction {...action} />)}
                        </div>
                    </div>

                    <div id={`container-${uid}`}>
                        <AntTable
                            loading={list?.loading}
                            locale={{ emptyText: <AntEmpty description="Không có dữ liệu" /> }}
                            id={uid}
                            columns={[
                                ...((showStatus && showStatus?.show || false) ? [{
                                    align: "center",
                                    width: 30,
                                    render: (t: any, r: any, i: number) => {
                                        if (r?.[showStatus && showStatus?.type || ""] === 0) {
                                            return <AntTooltip placement="topLeft" color="blue" title="Thư mục">
                                                <i className="fa fa-circle" style={{ color: "#108ee9" }} />
                                            </AntTooltip>
                                        } else {
                                            const valueField = Number(r?.[showStatus && showStatus?.fieldStatus || ""]);
                                            if (isNaN(valueField) || ![0, 1].includes(valueField)) {
                                                return "-";
                                            } else {
                                                return <AntTooltip placement="topLeft" color={valueField === 1 ? "#87d068" : "#f5222d"} title={showStatus?.valueStatus?.[valueField] || ""}>
                                                    <i className="fa fa-circle" style={{ color: valueField === 1 ? "#87d068" : "#f5222d" }} />
                                                </AntTooltip>
                                            }
                                        }
                                    },
                                    shouldCellUpdate: (record, prevRecord) => {
                                        return true;
                                    }
                                } as TableColumnType<RowProps>] : []),
                                ...(showIndex ? [{
                                    title: "STT",
                                    align: countLevel(newDataSource) > 1 ? "left" : "center",
                                    width: 70 + (countLevel(newDataSource) * 15),
                                    //fixed: "left",
                                    render: (t: any, r: any, i: number) => {
                                        if (newPagination) {
                                            const { pageSize, current } = newPagination;
                                            if (current !== undefined && pageSize !== undefined) {
                                                return i + current * pageSize - pageSize + 1;
                                            }
                                        }
                                        const { _level, _parentIndex } = r;
                                        return `${_parentIndex && _parentIndex.length > 0 ? `${_parentIndex.join(".")}.` : ""}${i + 1}`;
                                    },
                                    shouldCellUpdate: (record, prevRecord) => {
                                        return true;
                                    }
                                } as TableColumnType<RowProps>] : []),
                                ...columns.map((item: any) => ({
                                    ...item,
                                    width: getColumnWidth(item.cellID, item.width),
                                    sortOrder: getSortOrder(item.dataIndex || ""),
                                    render: (t: ReactNode, r: any, i: number) => {
                                        return item?.render ? item.render(t, r, i) : t;
                                    },
                                    tblResizeID,
                                    shouldCellUpdate: () => true,
                                    onHeaderCell: (col: any) => ({ ...col, title: item.title }),
                                }))
                            ]}
                            bordered
                            rowKey="id"
                            size="small"
                            pagination={newPagination ? {
                                size: "default",
                                showSizeChanger: true,
                                showLessItems: true,
                                ...newPagination
                            } : false}
                            {...props}
                            components={{
                                header: {
                                    cell: (props: any) => <HeaderCell props={props} />
                                }
                            }}
                            onChange={(p, f, s, e) => {
                                if (props.onChange) {
                                    document.getElementById(uid)?.scrollIntoView();
                                    props.onChange(
                                        p,
                                        f,
                                        (s && !Array.isArray(s) ? [s] : s).map(item => ({ ...item, _order: item.order === "ascend" ? "asc" : item.order === "descend" ? "desc" : undefined })),
                                        e
                                    );
                                }
                            }}
                            dataSource={addLevelTreeData(newDataSource)}
                            rowSelection={rowSelection !== false ? {
                                fixed: true,
                                selectedRowKeys: selectedRows.map(item => item.id),
                                onSelectAll: (selected) => {
                                    !selected && list?.unCheckAll();
                                },
                                onSelect: (_, selected) => {
                                    !selected && list?.unCheckAll();
                                },
                                onChange: (keys, rows) => {
                                    if (rowSelection?.type === "radio") {
                                        rowSelection?.onChange && rowSelection.onChange(keys, rows)
                                        list?.setSelectedRows(rows)
                                    }
                                    else {
                                        const _rows = [...selectedRows.filter(item => flattenDataSource(newDataSource).findIndex(d => d.id === item.id) === -1), ...rows]
                                        rowSelection?.onChange && rowSelection.onChange(keys, _rows)
                                        list?.setSelectedRows(_rows)
                                    }

                                },
                                type: rowSelection?.type || "checkbox",
                                getCheckboxProps: (record) => {
                                    if (disabledCheckbox){
                                        switch (disabledCheckbox?.sign) {
                                            case "includes":
                                                return { disabled: (disabledCheckbox?.value).includes(record?.[disabledCheckbox?.columnName || ""]) };
                                            default:
                                                return { disabled: false };;
                                        }
                                    } else {
                                        return { disabled: false };
                                    }
                                },
                            } : undefined}
                            scroll={{
                                x: "100%",
                                ...(scroll || {})
                                // y: "calc(100vh - 200px)"
                            }}
                            rowClassName={(record, index, indent) => {
                                let show = false;
                                if (!list?.getQ()) {
                                    show = true;
                                }
                                else {
                                    const tr = document.querySelector(`tr[data-row-key="${record.id}"]`);
                                    if (tr) {
                                        const q = list.getQ()?.toLowerCase();
                                        tr.childNodes.forEach(td => {
                                            if (
                                                td.textContent
                                                && q
                                                && (q.includes(`${td.textContent}`.toLowerCase()) || `${td.textContent}`.toLowerCase().includes(q))
                                            ) {
                                                show = true;
                                            }
                                        })
                                    }
                                }
                                return `${rowClassName ? (typeof rowClassName === "string" ? `${rowClassName} ` : rowClassName(record, index, indent)) : ""} ${!show ? "row-hidden" : ""}`;
                            }}
                        />
                    </div>
                </Fragment>
        }
    </Fragment >
}
export default Main;

const addLevelTreeData = (data: Array<any> = [], _level: number = 1, _parentIndex: number[] = []): Array<any> => {
    return data.map((item, i) => ({
        ...item,
        _level,
        _parentIndex,
        children: item.children && Array.isArray(item.children) ? addLevelTreeData(item.children, _level + 1, [..._parentIndex, i + 1]) : undefined
    }))
}

const flattenDataSource = (children: Array<any>, _level = 1): Array<any> => Array.prototype.concat.apply(
    children,
    children.map(item => item.children && Array.isArray(item.children) ? flattenDataSource(item.children) : [])
)