import { ActionProps } from ".."
export interface FoderProps {
    len?: number,
    parent_id?: number;
    name?: string;
    description?: string;
    last_modified_date?: string;
    id?: number;
    created_date?: string;
    type?: 0 | 1;
    children?: Array<FoderProps>;
    title: string,
    key: number
}
const stateDefault: Array<FoderProps> = [
]
const updateModuleSuccess = (state: any, data: any): Array<FoderProps> => {
    return state?.map((item: any) => {
        if (item.id === data.id) {
            return {
                ...item,
                ...data
            }
        } else {
            return {
                ...item
            }
        }
    })
}
export default (state: Array<FoderProps> = stateDefault, action: ActionProps): Array<FoderProps> => {
    const type = action.type;
    switch (type) {
        case "DATA_MODULE":
            const data = state = action.payload
            return data
        case "CREATE_MODULE_SUCCESS":
            return [...state, action.payload]
        case "UPDATE_MODULE_SUCCESS":
            return updateModuleSuccess(state, action.payload)
        case "DELETE_MODULE_SUCCESS":
            return state.filter(item => !action.payload.includes(item.id))
        default:
            return state;
    }
}
