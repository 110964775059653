import { setAuth, AntModal } from "../common"
import { stringify } from "qs"
import { startLoad, HOST_NAME, endLoad, LoginPostProps, ResponseLoginProps, LoginSSODataProps, LoginSSOProps } from ".";

export default async ({ hostName, data, showLoading = true, controller }: LoginPostProps): Promise<ResponseLoginProps> => {
    const {
        username,
        password,
        remember,
    } = data;
    showLoading && startLoad("Đang đăng nhập...")
    const response = await fetch(`${hostName || HOST_NAME}/uaa/oauth/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Basic YnJvd3Nlcjo="
        },
        signal: controller?.signal,
        body: stringify({
            username,
            password,
            grant_type: "password"
        }),
    })
        .catch(e => {
            showLoading && endLoad();
            const res: Promise<ResponseLoginProps> = new Promise(resolve => resolve({ error: true, statusCode: 0 }));
            return {
                json: () => res,
                status: 0
            }
        })
    showLoading && endLoad();
    const _data = await response.json();
    const error = _data.error; // unauthorized | invalid_grant
    const error_description = _data.error_description; // CUSTOM_ACCOUNT_BLOCK | 
    const rest_attempt_login = _data.rest_attempt_login; // num
    switch (response.status) {
        case 200:
            const _res = {
                ..._data,
                remember,
                token: `${_data.token_type} ${_data.access_token}`,
                loginBySSO: false
            }
            setAuth(_res);
            return { error: false, statusCode: 200, token: _res, };
        case 400:
            if (error === "invalid_grant" && rest_attempt_login && !isNaN(rest_attempt_login)) {
                const num = parseInt(_data.rest_attempt_login, 0);
                if (num <= 2) {
                    AntModal.warning({
                        content: `Nếu đăng nhập sai ${num + 1} lần nữa tài khoản của bạn sẽ bị khoá!`
                    });
                }
            }
            return { error: true, statusCode: response.status };
        case 401:
            if (error === "unauthorized" && error_description === "CUSTOM_ACCOUNT_BLOCK") {
                AntModal.error({
                    content: `Tài khoản của bạn đã bị khoá. Vui lòng liên hệ với quản trị viên để biết thông tin!`
                });
            }
            return { error: true, statusCode: response.status };
        default:
            return { error: true, statusCode: response.status };
    }
}

export const fetchLoginSSO = async ({ hostName, data, showLoading = true, controller }: LoginSSOProps): Promise<ResponseLoginProps> => {

    showLoading && startLoad("Đang đăng nhập...")
    const response = await fetch(`${hostName || HOST_NAME}/uaa/sso/complete?${stringify(data)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Basic YnJvd3Nlcjo="
        },
        signal: controller?.signal
    })
        .catch(e => {
            showLoading && endLoad();
            const res: Promise<ResponseLoginProps> = new Promise(resolve => resolve({ error: true, statusCode: 0 }));
            return {
                json: () => res,
                status: 0
            }
        })
    showLoading && endLoad();
    const __data = await response.json();
    const _data = __data.result || {};
    const error = __data.error; // unauthorized | invalid_grant
    switch (response.status) {
        case 200:
            if (__data?.status) {
                const _res = {
                    ..._data,
                    token: `${_data.token_type} ${_data.access_token}`,
                    loginBySSO: true
                }
                setAuth(_res);
                return { error, statusCode: 200, status: __data.status, token: _res, };
            } else {
                return { error: true, status: __data?.status || false, statusCode: response.status };
            }

        default:
            return { error: true, statusCode: response.status, status: false };
    }
}