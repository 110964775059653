import React, { FC, Fragment } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-code fa-2x" />}
            message={title || "Component HTML"}
        />
    </Fragment>
}

export default DesigSider;