
import React from "react";
import Component from "./component";
import DesignSider from "./design.sider";
import DesignPage from "./design.page";
import PreviewPage from "./preview.page";

const Layout1 = {
    ma: "layout-1",
    siderLabel: <DesignSider />,
    pageLabel: <DesignPage />,
    previewPage: <PreviewPage />,
    component: <Component />,
    View: Component,
    column: 4
};
export default Layout1;
