import { ActionProps } from "..";
interface DataProps {
    config?: {
        bc_01?: {
            formId: number;
        };
        bc_02?: {
            formId: number;
        }
        bc_03?: {
            formId: number;
        }
    }
}
export interface ItemProps {
    loaded: boolean,
    data: DataProps
}

const stateDefault: ItemProps = {
    loaded: false,
    data: {}
}

const baoCaoTongHop = (state: ItemProps = stateDefault, action: ActionProps): ItemProps => {
    const type = action.type;
    switch (type) {
        case 'BAO_CAO_TONG_HOP_CAU_HINH':
            const data = action.payload as DataProps;
            return {
                loaded: true,
                data
            };
        default:
            return state;
    }
}

export default baoCaoTongHop;