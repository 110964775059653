import { FC } from "react";
import { PageProps } from "../../../../../../../reducers/page-builder/pages";
import { ViewTypeProps } from "../../../content";
import Layout1 from "./layout.1";
import Layout2 from "./layout.2";
import Layout3 from "./layout.3";
import Layout4 from "./layout.4";
import Layout5 from "./layout.5";
import Layout6 from "./layout.6";
import Layout7 from "./layout.7";
import Layout8 from "./layout.8";
import Layout9 from "./layout.9";
import Layout10 from "./layout.10";
import Layout11 from "./layout.11";

export declare type LayoutName = "layout-1" | "layout-2" | "layout-3" | "layout-4" | "layout-5" | "layout-6" | "layout-7" | "layout-8" | "layout-9" | "layout-10" | "layout-11";

export interface DataType {
    ma: string;
    sapXep: number;
}

export interface LayoutProps {
    data?: Array<Array<DataType>>
}

interface IProps {
    name?: LayoutName;
    type?: "builder" | "preview";
    viewType: ViewTypeProps
}
const BuilderLayout: FC<IProps> = ({ name, type = "builder" }) => {
    switch (name) {
        case "layout-1":
            return Layout1.pageLabel
        case "layout-2":
            return Layout2.pageLabel
        case "layout-3":
            return Layout3.pageLabel
        case "layout-4":
            return Layout4.pageLabel
        case "layout-5":
            return Layout5.pageLabel
        case "layout-6":
            return Layout6.pageLabel
        case "layout-7":
            return Layout7.pageLabel;
        case "layout-8":
            return Layout8.pageLabel;
        case "layout-9":
            return Layout9.pageLabel;
        case "layout-10":
            return Layout10.pageLabel;
        case "layout-11":
            return Layout11.pageLabel;
        default:
            return null;
    }
}
const PreviewLayout: FC<IProps> = ({ name }) => {
    switch (name) {
        case "layout-1":
            return Layout1.previewPage
        case "layout-2":
            return Layout2.previewPage
        case "layout-3":
            return Layout3.previewPage
        case "layout-4":
            return Layout4.previewPage
        case "layout-5":
            return Layout5.previewPage
        case "layout-6":
            return Layout6.previewPage
        case "layout-7":
            return Layout7.previewPage
        case "layout-8":
            return Layout8.previewPage
        case "layout-9":
            return Layout9.previewPage
        case "layout-10":
            return Layout10.previewPage
        case "layout-11":
            return Layout11.previewPage;
        default:
            return null;
    }
}
export default BuilderLayout;
export { PreviewLayout };
export const layouts = [
    Layout1,
    Layout2,
    Layout3,
    Layout4,
    Layout5,
    Layout6,
    Layout7,
    Layout8,
    Layout9,
    Layout10,
    Layout11,
]

export interface DesignSiderProps { }
export interface DesignPageProps { }
export interface PreviewPageProps { page?: PageProps }
export interface ComponentProps { page?: PageProps }