import React, { CSSProperties, FC, ReactNode, useEffect, useState } from "react";
import { download } from "../../.fetch";
import { AntAlert } from "../antd";
import { getAuth } from "../c-auth";
import PreviewFile from "./previewFile";

interface FileInfoProps {
    url: string;
    type: string;
    fileName: string;
}

interface DocumentPreviewProps {
    style?: CSSProperties;
    documentId: number;
}
const DocumentPreview: FC<DocumentPreviewProps> = ({ documentId, style }) => {
    const [fileInfo, setFileInfo] = useState<FileInfoProps | "loading" | "error">()
    useEffect(() => {
        const getData = async () => {
            const auth = getAuth();
            setFileInfo("loading");
            const res = await download({
                url: `/cms/cmis/document/preview/${documentId}`,
                token: auth.token,
                responseType: 'onlyFileInfo'
            })
            setFileInfo(res.fileInfo);
        }
        getData();
    }, [])
    const content = (fileInfo: FileInfoProps): ReactNode => {
        const re: RegExp = /(?:\.([^.]+))?$/;
        const _e = re.exec(fileInfo.fileName);
        const ext = _e && _e[1];
        return ext ? <PreviewFile
            url={fileInfo.url}
            type={ext}
            fileName={fileInfo.fileName}
        /> : "Không hỗ trợ"
    }
    return <div>
        {
            fileInfo === "loading" ? <AntAlert type="loading" message="Vui lòng chờ" /> :
                fileInfo === "error" ? <AntAlert type="error" message="Lỗi" /> :
                    <div>
                        {fileInfo && content(fileInfo)}
                    </div>
        }
    </div>
}

export default DocumentPreview;