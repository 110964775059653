import React, { CSSProperties, Fragment, useEffect } from 'react';
import { AntSelect, AntSkeleton } from '../../../../../../../../common';
const contentStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: "#FFFFFF"
};
const DesignPage = () => {
    return <Fragment>
        <AntSkeleton active />
    </Fragment>
};

export default DesignPage;