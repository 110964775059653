import { parse } from 'qs';
import React, { } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useLocation } from 'react-router-dom';
import { HOST_NAME } from '../../../../../../../../.fetch';
import MapView from "./mapView";
const MapUnitily = ({ list, itemKey, lang }: any) => {
    const { search } = useLocation();
    const qs = parse(search, { ignoreQueryPrefix: true });
    const lat: number | undefined = parseFloat(qs.lat as string) || 14;
    const lng: number | undefined = parseFloat(qs.lng as string) || 108;
    const zoom = !isNaN(parseInt(qs.zoom as string)) ? parseInt(qs.zoom as string) : 13;
    const popup: string | undefined = qs.openPopup ? (qs.openPopup as string) : undefined;
    const toaDo = itemKey && list.getData().
        filter((item: any) => (item.data_formbuilder && item.data_formbuilder[`${itemKey.toaDo}`] &&
            Object.values(item?.data_formbuilder[`${itemKey.toaDo}`]).length > 0)).
        map((i: any) => ({
            diadiem: [i.data_formbuilder[`${itemKey.toaDo}`][`lat`], i.data_formbuilder[`${itemKey.toaDo}`][`lng`]],
            diachi: i.data_formbuilder[`${itemKey.diaChi}`] || i.data_formbuilder[`${lang}_${itemKey.diaChi}`],
            ten: i.data_formbuilder[`${itemKey?.ten}`] || i.data_formbuilder[`${lang}_${itemKey.ten}`],
            img: (i.data_formbuilder[`${itemKey?.img}`] || i.data_formbuilder[`${lang}_${itemKey.img}`]) ?
                (i.data_formbuilder[`${itemKey?.img}`] || i.data_formbuilder[`${lang}_${itemKey.ten}`]) :
                undefined,
            trangThai: (i.data_formbuilder[`${itemKey?.trangThai}`]) || i.data_formbuilder[`${lang}_${itemKey.trangThai}`] ?
                (i.data_formbuilder[`${itemKey?.trangThai}`] || i.data_formbuilder[`${lang}_${itemKey.trangThai}`]) :
                undefined,
            sdt: (i.data_formbuilder[`${itemKey?.sdt}`] || i.data_formbuilder[`${lang}_${itemKey.sdt}`]) ?
                (i.data_formbuilder[`${itemKey?.sdt}`] || i.data_formbuilder[`${lang}_${itemKey.sdt}`]) :
                undefined,
            id: i.id
        }))

    return <MapContainer
        key={toaDo && JSON.stringify(toaDo[0]?.[`diadiem`]) || "-1"}
        style={{ height: "calc(100vh - 150px)" }}
        center={typeof lat === "number" && typeof lng === "number" ? [lat, lng] : [14, 108]}
        zoom={zoom}
        boundsOptions={{ padding: [13, 13] }}
        scrollWheelZoom
    >
        <MapView />
        <TileLayer
            url={`https://maps.vnpost.vn/api/tm/{z}/{x}/{y}@2x.png?apikey=${process.env.REACT_APP_MAP_KEY}`}
        />
        {
            toaDo && toaDo.map((item: any) => {
                return list.getData().length > 0 && <Marker position={item.diadiem}
                    ref={r => {
                        if (popup === item.id && r) {
                            r.openPopup();
                        } else {
                            r?.closePopup();
                        }
                    }}
                >
                    <Popup>
                        <div>
                            <img
                                style={{ objectFit: "cover" }}
                                width={"100%"}
                                src={item.img ? `${HOST_NAME}/headlessCms/api/public/document/preview/${item.img.id}` : `/static/img/no-image.jpg`} />
                            <p><h3 style={{ fontSize: 20, color: "#2c4964" }}><b>{item.ten}</b></h3></p>
                            <p style={{ fontSize: 15 }}>{item.diachi}</p>
                            <p style={{ fontSize: 15 }}>{item.sdt}</p>
                        </div>
                    </Popup>
                </Marker>
            })
        }
    </MapContainer >
};

export default MapUnitily;