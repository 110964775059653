import React, { Fragment } from "react";
import { AntDescriptions, AntDivider } from "../../../../../../../../common";
import moment from "moment";
const DateTimeFormat = "DD/MM/YYYY hh:mm:ss";

const DetailOrder = ({itemDetail}) => {
    const getColorStatus = (stt) => {
        let nameStt = "Chưa xử lý"
        switch (stt) {
            case "CHUAXULY":
                nameStt = "Chưa xử lý"
                break;
            case "THANHCONG":
                nameStt = "Thành công"
                break;
            case "KHONGTHANHCONG":
                nameStt = "Không thành công"
                break;
            case "DAHUY":
                nameStt = "Đã hủy"
                break;
            default:
                break;
        }
        return nameStt
    }
    return (
        <Fragment>
            <AntDivider />
                <div style={{ display: 'flex', padding: '0px 10px' }}>
                    <span style={{ fontSize: 14, color: '#23111185' }}>
                    Mã sản phẩm:
                    </span>
                    <b style={{
                        marginLeft: 10,
                        color: '#191010',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {itemDetail?.ma_san_pham}
                    </b>
                </div>
                <div style={{ display: 'flex', padding: '0px 10px' }}>
                    <span style={{ fontSize: 14, color: '#23111185' }}>
                        Số lượng:
                    </span>
                    <b style={{ marginLeft: 10, color: '#191010' }}>{itemDetail.so_luong}</b>
                </div>
            <AntDivider />
            <AntDescriptions size="small" column={1}>
                <AntDescriptions.Item label="Tên người liên hệ">{itemDetail.ten_nguoi_lien_he}</AntDescriptions.Item>
                <AntDescriptions.Item label="Email người liên hệ">{itemDetail.email_nguoi_lien_he}</AntDescriptions.Item>
                <AntDescriptions.Item label="Số điện thoại người liên hệ">{itemDetail.sdt_nguoi_lien_he}</AntDescriptions.Item>
                <AntDescriptions.Item label="Ngày bắt đầu">{itemDetail.ngay_bat_dau}</AntDescriptions.Item>
                <AntDescriptions.Item label="Ngày kết thúc">{itemDetail.ngay_ket_thuc}</AntDescriptions.Item>
                <AntDescriptions.Item label="Mô tả">{itemDetail.mo_ta}</AntDescriptions.Item>
                <AntDescriptions.Item label="Ngày tạo">{moment(itemDetail.ngay_tao).format(DateTimeFormat)}</AntDescriptions.Item>
                <AntDescriptions.Item label="Trạng thái">{getColorStatus(itemDetail.trang_thai)}</AntDescriptions.Item>
            </AntDescriptions>
        </Fragment>
    );
}
export default DetailOrder;