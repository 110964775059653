import React, { CSSProperties, Fragment } from "react";
import { AntLayout } from "../../../../../../../../common";
import DateTimeCurrent from "./date_time";
import { GiaTriProps } from "./preview.page";

const DesignPage = ({ giaTri = {} }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { menus, general } = _giaTri
    return <Fragment>
        <AntLayout.Header
            style={{
                color: general?.color,
                backgroundColor: general?.bgColor,
                height: general?.height,
                lineHeight: general?.height
            }}
        >
            <div style={{ display: "flex" }}>
                <div>
                    <DateTimeCurrent />
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                    {
                        menus?.map((item, i) => <span style={{ color: general?.color, padding: "0 10px" }}>Menu {i + 1}</span>)
                    }
                </div>
            </div>

        </AntLayout.Header>
    </Fragment>
}

export default DesignPage;