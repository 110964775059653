import React, { Fragment } from "react";
import { AntButton, AntCard, AntCol, AntImage, AntList, AntRow, AntSkeleton } from "../../../../../../../../common";

const list = [
    { id: 1 },
    { id: 2 },
    { id: 3 }
]

interface ViewAllActionProps {
    hidden?: boolean;
    href?: string;
    iconCls?: string;
    label?: string;
    displayType?: "center" | "left" | "right" | "full"
}
interface FormItemProps {
    formId: number;
    dataIds: string[]
}
interface IDisplayType {
    viewAllAction: ViewAllActionProps;
    loading?: boolean;
    data?: any[]
}

interface ITitle {
    label?: string;
    hidden?: boolean;
    fontSize?: number;
    color?: string;
    bgColor?: string;
}
interface GiaTriProps {
    viewAllAction?: ViewAllActionProps;
    forms?: FormItemProps[];
    title?: ITitle;
    displayType?: 1 | 2 | 3 | 4
}


const DesignPage = ({ giaTri = {} }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    return <Fragment>
        <AntCard
            size="small"
            className="design-page-news mb-10"
            cover={<AntSkeleton.Image />}
        >
            <AntSkeleton />
        </AntCard>
    </Fragment >
}

export default DesignPage;