import React, { Fragment } from "react";
import { AntSelect } from '../../../../../../../../common';
const DesignPage = () => {
   return <Fragment>
      <div style={{ height: '120px', width: '100%', backgroundColor: '#e3e3e3', borderRadius: '4px' }}>
         <div style={{ padding: '20px' }}>
            <div>
               <h2>Liên Kết Website</h2>
            </div>
            <div>
               <AntSelect
                  style={{ width: '75%' }}
                  placeholder="Liên kết web"
               />
            </div>
         </div>
      </div>
   </Fragment>
}

export default DesignPage;