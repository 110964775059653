
import { parse, stringify } from "qs";
import React, { CSSProperties, FC, Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AntCol, AntEmpty, AntList, AntRow } from "../../../../../../../../common";
import { findbyId } from "../../../../../page/api";
import { IHref } from "../../../../common/url-config";

const CLink: FC<{ href?: IHref; style?: CSSProperties }> = ({ href, children, style }) => {
    const { pathname, search, hash } = useLocation();
    const [to, setTo] = useState<string>("");
    const getPageById = async (id: number) => {
        const res = await findbyId(id);
        if (res.status) {
            const page = res.result;
            setTo(page?.page.url || "");
        }
    }
    useEffect(() => {
        switch (href?.type) {
            case "string":
                setTo(href.path || "");
                break;
            case "object":
                const fullPath = {
                    pathname: href.pathname || pathname,
                    search: stringify({ ...(href.extendSearch ? parse(search, { ignoreQueryPrefix: true }) : {}), ...parse(href.search || "") }),
                    hash: href.hash
                }
                setTo(`${fullPath.pathname}${fullPath.search ? `?${fullPath.search}` : ""}${fullPath.hash ? `#${fullPath.hash}` : ""}`);
                break;
            case "page":
                href.pageId && getPageById(href.pageId);
                break;
        }
    }, [href])
    return <Link to={to} target={href?.openNewTab ? "_blank" : undefined} replace={href?.action === "replace"} style={style}>
        {children}
    </Link>
}

interface Setting {
    numberCol?: number;
    colorBG?: string;
    color?: string;
    fontSize?: number;
    displayType?: "center" | "left" | "right" | "justify";
}

interface Content {
    label?: string;
    href?: IHref;
    selectColumn?: string[];
}
export interface GiaTriProps {
    setting?: Setting;
    content?: Content[];
}

const PreviewPage = ({ giaTri }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { setting, content } = _giaTri
    const number = setting?.numberCol || 1
    const data: any[] = []

    useEffect(() => {
        for (let i = 0; i < number; i++) {
            data.push(<AntCol key={i + 1}
                span={24 - (24 % number)}
                style={{
                    backgroundColor: setting?.colorBG || "#FFFF",
                    textAlign: setting?.displayType || "justify"
                }} >
                {(content || [])
                    .filter(v => (i === 0 && !v.selectColumn) || (v.selectColumn || []).includes(`${i + 1}`))
                    .map(c =>
                        <AntList>
                            <CLink style={{
                                color: setting?.color || "#000000",
                                fontSize: setting?.fontSize || 25
                            }}
                                href={c.href}
                            >
                                {c.label}
                            </CLink>
                        </AntList>
                    )}
            </AntCol>)
        }
    }, [])

    return <Fragment>
        {content?.length ? <AntRow >
            {data}
        </AntRow> :
            <AntEmpty description="Không có dữ liệu" style={{ background: "#FFFF" }} />
        }
    </Fragment>
}

export default PreviewPage;