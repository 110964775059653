import React, { Fragment } from "react";
import { GiaTriProps } from "./preview.page";

const DesignPage = ({ giaTri = {} }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { name } = _giaTri;
    return <Fragment>
        <div className="py-3 px-10" style={{ border: "1px dashed #ccc" }}>{name || "View"}</div>
    </Fragment>
}

export default DesignPage;