import React, { Fragment, useState ,useEffect} from "react";
import { AntLayout, AntCard, useList, AntRow, AntCol } from '../../../../../../../../common';
import { useHistory, useLocation } from "react-router";
import { parse, stringify } from "qs"
import { Line, Pie } from '@ant-design/charts';
const ProviewPage = () => {
    const {search}=useLocation();
    const history = useHistory();
    const qs = parse(search,{ignoreQueryPrefix:true});
    useEffect(() => {
    }, [])
    const data = [
        { year: '1991', value: 3 },
        { year: '1992', value: 4 },
        { year: '1993', value: 3.5 },
        { year: '1994', value: 5 },
        { year: '1995', value: 4.9 },
        { year: '1996', value: 6 },
        { year: '1997', value: 7 },
        { year: '1998', value: 9 },
        { year: '1999', value: 13 },
      ];
    
    const config = {
        data,
        height: 400,
        xField: 'year',
        yField: 'value',
        point: {
          size: 5,
          shape: 'diamond',
        },
      };

      var data1 = [
        {
          type: 'abc',
          value: 27,
        },
        {
          type: 'acb',
          value: 25,
        },
        {
          type: 'bac',
          value: 18,
        },
        {
          type: 'bca',
          value: 15,
        },
        {
          type: 'cab',
          value: 10,
        },
        {
          type: 'cba',
          value: 5,
        },
      ];
      var config1 = {
        appendPadding: 10,
        data: data1,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        label: {
          type: 'spider',
          labelHeight: 28,
          content: '{name}\n{percentage}',
        },
        interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
      };
    return <Fragment>
        <AntLayout>
            <AntCard>
                <AntRow gutter={[16,16]}>
                    <AntCol span={12}>
                        <AntRow gutter={[16,16]}>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <span style={{ fontSize: 16}}> sfdhgfahgd</span>
                            </AntCol>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <Pie {...config1} />
                            </AntCol>
                        </AntRow>
                    </AntCol>
                    <AntCol span={12}>
                        <AntRow gutter={[16,16]}>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <span style={{ fontSize: 16}}> sfdhgfahgd</span>
                            </AntCol>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <Line {...config} />
                            </AntCol>
                        </AntRow>
                    </AntCol>
                    <AntCol span={12}>
                    <AntRow gutter={[16,16]}>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <span style={{ fontSize: 16}}> sfdhgfahgd</span>
                            </AntCol>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <Line {...config} />
                            </AntCol>
                        </AntRow>
                    </AntCol>
                    <AntCol span={12}>
                        <AntRow gutter={[16,16]}>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <span style={{ fontSize: 16}}> sfdhgfahgd</span>
                            </AntCol>
                            <AntCol span={24} style={{ textAlign: "center"}}>
                                <Pie {...config1} />
                            </AntCol>
                        </AntRow>
                    </AntCol>
                </AntRow>
            </AntCard>
        </AntLayout>
    </Fragment>
}
export default ProviewPage;