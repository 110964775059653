import React, { FC, Fragment } from "react";
import ContainerPreview from "../../container.preview";
import { ComponentProps } from "./../"
import { AntCol, AntRow } from "../../../../../../../../common";

const Component: FC<ComponentProps> = ({ page }) => {
    return <Fragment>
        <AntRow >
            <AntCol span={24}>
                <ContainerPreview index={0} key="header" page={page} />
            </AntCol>
        </AntRow>
        <AntRow>
            <AntCol span={24}><ContainerPreview index={1} key="row1" page={page} /></AntCol>
        </AntRow>
        <AntRow>
            <AntCol span={24}>
                <ContainerPreview index={2} key="footer" page={page} />
            </AntCol>
        </AntRow>
    </Fragment>
}
export default Component;
