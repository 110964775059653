import React, { FC, Fragment } from "react";
import ContainerDrag from "../../container.drag";
import { DesignPageProps } from "./../"
import { AntCol, AntRow } from "../../../../../../../../common";

const DesignPage: FC<DesignPageProps> = () => {
    return <Fragment>
        <AntRow className="px-5">
            <AntCol span={24}>
                <ContainerDrag index={0} key="header" />
            </AntCol>
        </AntRow>
        <AntRow className="py-10">
            <AntCol md={{ span: 16 }} sm={{ span: 24 }} className="px-5">
                <ContainerDrag index={1} key="row1" />
            </AntCol>
            <AntCol md={{ span: 8 }} sm={{ span: 24 }} className="px-5">
                <ContainerDrag index={2} key="row2" />
            </AntCol>
        </AntRow>
        <AntRow className="py-10">
            <AntCol md={{ span: 12 }} sm={{ span: 24 }} className="px-5">
                <ContainerDrag index={3} key="row3" />
            </AntCol>
            <AntCol md={{ span: 6 }} sm={{ span: 24 }} className="px-5">
                <ContainerDrag index={4} key="row4" />
            </AntCol>
            <AntCol md={{ span: 6 }} sm={{ span: 24 }} className="px-5">
                <ContainerDrag index={5} key="row6" />
            </AntCol>
        </AntRow>
        <AntRow className="px-5">
            <AntCol span={24}>
                <ContainerDrag index={6} key="footer" />
            </AntCol>
        </AntRow>
    </Fragment >
}
export default DesignPage;
