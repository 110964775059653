import { AccountCurrent, TokenProps } from ".";
import { getCookie, Base64 } from "./../";

export default (): TokenProps => {
    const authString = getCookie(`app-au`);
    let result: TokenProps = {
        access_token: undefined,
        token_type: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        scope: undefined,
        token: undefined,
        remember: false,
        loginBySSO: false
    }
    if (!authString) { result = {} }
    try { result = JSON.parse(Base64.decode(authString)); }
    catch (e) { result = {} }
    result.token = result.token_type && result.access_token ? `${result.token_type} ${result.access_token}` : undefined;
    return result || {};
}

export const acc = (): AccountCurrent | null => {
    const accString = localStorage.getItem(`app-acc`);
    let result: AccountCurrent | null = null;
    if (!accString) { return null; }
    try { return JSON.parse(Base64.decode(accString)); }
    catch (e) { return null; }
}