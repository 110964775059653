import Profile from "./profile";

export declare type ViewKeys = "profile";
interface ViewProps {
    key: ViewKeys;
    name: string;
    component: any
}
export const views: ViewProps[] = [
    {
        key: "profile",
        name: "Trang cá nhân",
        component: Profile
    }
]