import { ActionProps } from "..";

export interface MapProps {
    name?: string;
    lat?: number;
    lng?: number;
    formId?: number;
}

export interface FileProps {
    nameField?: string;
    value?: string;
    id?: number;
    trangThai?: number;
}

export interface FileSelectedProps {
    files?: Array<FileProps>;
    allowMulti?: boolean;
}

export interface FormBuilderProps {
    name?: string;
    id?: number;
    key?: number;
    nameDatabase?: string;
    folder?: boolean;
    manageVersion?: boolean;
    optionDongBo?: object;
    formFields?: any;
    permission?: any[];
    searchElastic?: boolean;
    searchAll?: boolean;
}

export interface FormFieldProps {
    id?: number;
    name?: string;
    key: number;
    description?: string;
    fieldOptionText?: string;
}

export interface listStoreProps {
    key?: number;
    value?: string;
}

export interface FormBuildersProps {
    form: FormBuilderProps;
    formFields: Array<FormFieldProps>;
}

export interface DataFormBuilderProps {
    name?: string;
    id?: string;
    key: number;
    createdDate?: string;
    createdBy?: string;
}

export interface FormWithConfigProps {
    forms?: Array<FormBuildersProps>;
    listStoreProps?: Array<listStoreProps>;
}

export interface DataRefProps {
    formId?: number;
    formFieldId?: string;
    dataRef?: any[];
}

export interface DataRefParentChildProps {
  name?: string;
  data?: object;
  formId?: number;
  status?: number;
  formRootId?: number;
  dataId?: string;
}

const stateFormDataDefault: Array<DataFormBuilderProps> = [];
const stateFormDefault: FormWithConfigProps = {};
const stateFormFieldDefault: Array<FormFieldProps> = [];
const stateMapDefault: Array<MapProps> = [];
const stateFileDefault: FileSelectedProps = {};
const stateFormBuilderDefault: FormBuilderProps = {};
const stateDataRefDefault: Array<DataRefProps> = [];
const stateDataRefParentChildDefault: Array<DataRefParentChildProps> = [];

export const dataRefParentChild = (
    state: DataRefParentChildProps[] = stateDataRefParentChildDefault,
    action: ActionProps
): DataRefParentChildProps[] => {
    const type = action.type;
    switch (type) {
        case "ADD_DATA_REF_PARENT_CHILD":
            const dataAdd = action.payload;
            const dataRefExit = state.find(
                (el: any) =>
                    Number(el.formRootId) === Number(dataAdd.formRootId) &&
                    Number(el.formId) === Number(dataAdd.formId) &&
                    el.name === dataAdd.name
            );

            if (!dataRefExit) {
                return [
                    ...state,
                    ...[
                        {
                            formId: dataAdd.formId,
                            name: dataAdd.name,
                            data: dataAdd.data,
                            status: dataAdd.status,
                            formRootId: dataAdd.formRootId,
                            dataId: dataAdd.dataId,
                        },
                    ],
                ];
            } else {
                return state.map((el) =>
                    Number(el.formRootId) === Number(dataAdd.formRootId) &&
                    Number(el.formId) === Number(dataAdd.formId) &&
                    el.name === dataAdd.name
                        ? { ...el, data: dataAdd.data, status: dataAdd.status, dataId: dataAdd.dataId }
                        : el
                );
            }
        case "DELETE_DATA_REF_PARENT_CHILD":
            const dataDelete = action.payload;
            return state.filter(
                (item) =>
                    Number(item.formRootId) !== Number(dataDelete.formRootId)
            ) as DataRefProps[];
        default:
            return state;
    }
};

export const dataRef = (
    state: DataRefProps[] = stateDataRefDefault,
    action: ActionProps
): DataRefProps[] => {
    const type = action.type;
    switch (type) {
        case "ADD_DATA_REF":
            const dataAdd = action.payload as DataRefProps;
            const dataRefExit = state.find(
                (el) =>
                    el.formFieldId === dataAdd.formFieldId &&
                    el.formId + "" === dataAdd.formId + ""
            );
            if (!dataRefExit) {
                return [...state, ...[dataAdd]];
            } else {
                return state.map((el) =>
                    el.formFieldId === dataAdd.formFieldId &&
                    el.formId + "" === dataAdd.formId + ""
                        ? { ...el, dataRef: dataAdd.dataRef }
                        : el
                );
            }
        case "DELETE_DATA_REF":
            const dataDelete = action.payload as DataRefProps;
            return state.filter(
                (item) => item.formId + "" !== dataDelete.formId + ""
            ) as DataRefProps[];
        default:
            return state;
    }
};

export const map = (
    state: MapProps[] = stateMapDefault,
    action: ActionProps
): MapProps[] => {
    const type = action.type;
    switch (type) {
        case "MAP_SELECTED":
            const maps = action.payload as MapProps[];
            return maps;
        case "MAP_DELETE_BY_FORMID":
            const _p = action.payload as { formId: number };
            return state.filter(
                (item: MapProps) => item.formId + "" !== _p.formId + ""
            ) as MapProps[];
        default:
            return state;
    }
};

export const formBuilders = (
    state: FormWithConfigProps = stateFormDefault,
    action: ActionProps
): FormWithConfigProps => {
    const type = action.type;
    switch (type) {
        case "FORM_BUILDER_LOAD":
            const forms = action.payload as FormWithConfigProps;
            return forms;
        case "FORM_BUILDER_QUYEN":
            const dataPermission = action.payload;
            const newForm = (state?.forms || []).map((el: any) => {
                if (el.form.id === dataPermission.formId) {
                    return { ...el, permission: dataPermission.permission };
                } else {
                    return el;
                }
            });
            return { ...state, forms: newForm };
        default:
            return state;
    }
};

export const formBuilder = (
    state: FormBuilderProps = stateFormBuilderDefault,
    action: ActionProps
): FormBuilderProps => {
    const type = action.type;
    switch (type) {
        case "FORM_BUILDER_SELECTED":
            return action.payload as FormBuilderProps;
        case "FORM_BUILDER_SELECTED_QUYEN":
            return { ...state, permission: action.payload };
        default:
            return state;
    }
};

export const formFields = (
    state: FormFieldProps[] = stateFormFieldDefault,
    action: ActionProps
): FormFieldProps[] => {
    const type = action.type;
    switch (type) {
        case "FORM_FIELD_BUILDER_LOAD":
            const forms = action.payload as FormFieldProps[];
            return forms;
        default:
            return state;
    }
};

export const fileSelected = (
    state: FileSelectedProps = stateFileDefault,
    action: ActionProps
): FileSelectedProps => {
    const type = action.type;
    switch (type) {
        case "GET_ID_FILE_SELECT_UPLOAD":
            const file = action.payload as FileSelectedProps;
            return file;
        default:
            return state;
    }
};

const dataFormBuilders = (
    state: DataFormBuilderProps[] = stateFormDataDefault,
    action: ActionProps
): DataFormBuilderProps[] => {
    const type = action.type;
    switch (type) {
        default:
            return state;
    }
};
export default dataFormBuilders;
