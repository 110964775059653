import React, { CSSProperties, Fragment } from 'react';
import { AntCol, AntLayout, AntList, AntRow, AntSkeleton } from '../../../../../../../../common';

const contentStyle: CSSProperties = {
    padding: '0 8px 0 8px',

};
const DesignPage = () => {
    return <Fragment>
        <AntLayout style={{ background: "#FFFFFF" }}>
            <AntRow>
                <AntCol span={8} style={contentStyle}>
                    <AntList.Item >
                        <AntSkeleton
                            loading
                        />
                    </AntList.Item>
                </AntCol>
                <AntCol span={8} style={contentStyle}>
                    <AntList.Item>
                        <AntSkeleton
                            loading
                        />
                    </AntList.Item>
                </AntCol>
                <AntCol span={8} style={contentStyle}>
                    <AntList.Item>
                        <AntSkeleton
                            loading
                        />
                    </AntList.Item>
                </AntCol>
            </AntRow>
        </AntLayout>
    </Fragment>
};

export default DesignPage;