import React, { FC, Fragment } from "react";
import ContainerPreview from "../../container.preview";
import { ComponentProps } from "./../"
import { AntCol, AntRow } from "../../../../../../../../common";

const Component: FC<ComponentProps> = ({ page }) => {
    return <Fragment>
        <AntRow >
            <AntCol span={24}>
                <ContainerPreview index={0} key="header" page={page} />
            </AntCol>
        </AntRow>
        <div className="px-50">
            <AntRow>
                <AntCol md={{ span: 16 }} sm={{ span: 24 }} xs={{ span: 24 }} className="px-10">
                    <ContainerPreview index={1} key="row1" page={page} />
                </AntCol>
                <AntCol md={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }} className="px-10">
                    <ContainerPreview index={2} key="row2" page={page} />
                </AntCol>
            </AntRow>
        </div>
        <AntRow>
            <AntCol span={24}>
                <ContainerPreview index={3} key="footer" page={page} />
            </AntCol>
        </AntRow>
    </Fragment>
}
export default Component;
