import { FormInstance, useForm } from "antd/lib/form/Form";
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { AntButton, AntCheckbox, AntCollapse, AntDivider, AntDrawer, AntDropdown, AntForm, AntInput, AntModal, AntRadio, AntSelect, AntTree, AntTreeSelect, CommonForm } from "../../../../../../common";
import { PageProps } from "../../../../../../reducers/page-builder/pages";
import { fetchAll, ItemProps as PageItemProps } from "../../../page/api";

declare type TypeOption = "string" | "object" | "page" | undefined;
export interface IHref {
    action?: "push" | "replace"; // default:"push"
    path?: string;
    pathname?: string;
    search?: string;
    hash?: string;
    extendSearch?: boolean;
    extendHash?: boolean;
    type?: TypeOption
    pageId?: number;
    openNewTab?: boolean
}
interface IProps {
    trigger?: "click" | "contextMenu"; //default click
    viewType?: "modal" | "drawer";
    visible?: boolean;
    onVisibleChange?: (visible: boolean) => void;
    onOK: (href: IHref) => void;
    getInit: () => IHref;
    children?: ReactNode
}

const defaultValue: IHref = {
    action: "push",
    type: "string",
    extendHash: true,
    extendSearch: true,
}
const UrlConfig: FC<IProps> = ({
    viewType,
    visible,
    onVisibleChange,
    onOK,
    children = "Cấu hình đường dẫn",
    trigger,
    getInit,
}) => {
    const [form] = useForm();
    const [_visible, _setVisible] = useState<boolean>(false);
    const [href, setHref] = useState<IHref | undefined>(defaultValue)
    useEffect(() => {
        if (visible || _visible) {
            const init = getInit && typeof getInit === "function" ? getInit() : defaultValue;
            setHref(init);
        }
    }, [visible, _visible])
    const onClose = () => {
        onVisibleChange && typeof onVisibleChange === "function" ?
            onVisibleChange(false) :
            _setVisible(false);
    }
    return <Fragment>
        <AntButton
            onClick={() => {
                onVisibleChange && typeof onVisibleChange === "function" ?
                    onVisibleChange(true) :
                    _setVisible(true);
            }}
        >
            {children}
        </AntButton>
        {
            viewType === "drawer" ?
                <AntDrawer
                    width={400}
                    destroyOnClose
                    title="Cấu hình đường dẫn"
                    visible={visible === true || visible === false ? visible : _visible}
                    onClose={onClose}
                    bodyStyle={{ padding: "20px 0 0 0" }}
                    footer={<div style={{ textAlign: "right" }}>
                        <AntButton className="mr-10" onClick={onClose}>Huỷ</AntButton>
                        <AntButton type="primary" onClick={form.submit}>Xác nhận</AntButton>
                    </div>}
                >
                    <Body
                        href={href}
                        onOk={(href) => {
                            onOK && typeof onOK === "function" && onOK(href);
                            onClose();
                        }}
                        form={form}
                    />
                </AntDrawer> :
                <AntModal
                    width={500}
                    style={{ top: 50 }}
                    destroyOnClose
                    title="Cấu hình đường dẫn"
                    visible={visible === true || visible === false ? visible : _visible}
                    onCancel={onClose}
                    footer={[
                        <AntButton onClick={onClose}>Huỷ</AntButton>,
                        <AntButton type="primary" onClick={form.submit}>Xác nhận</AntButton>
                    ]}
                    bodyStyle={{ padding: "20px 0 0 0" }}
                >
                    <Body
                        href={href}
                        onOk={(href) => {
                            onOK && typeof onOK === "function" && onOK(href);
                            onClose();
                        }}
                        form={form}
                    />
                </AntModal>
        }
    </Fragment>
}


const Body: FC<{ href?: IHref, onOk: (href: IHref) => void, form: FormInstance<IHref> }> = ({ href, onOk, form }) => {
    const [type, setType] = useState<TypeOption>(href?.type)
    const [pageData, setPageData] = useState<{ loaded: boolean, data: PageItemProps[] }>({ loaded: false, data: [] });
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const getAllPage = async () => {
        const res = await fetchAll();
        if (res && res.status) {
            setPageData({
                loaded: true,
                data: res.result || []
            })
        }
    }
    useEffect(() => {
        setType(href?.type);
    }, [href?.type])

    useEffect(() => {
        if (!pageData.loaded && type === "page") {
            getAllPage();
        }
    }, [type, pageData.loaded])

    const treeData = (pages: PageItemProps[]): Array<{ value: number, label: string; children: Array<any> }> => pages.map((item) => ({ value: item.page.id, label: item.page.title, children: treeData(item.pageChildList || []) }))
    return <div>
        <CommonForm
            form={form}
            initialValues={href}
            labelCol={{ span: 6 }}
            onFinish={(href: IHref) => onOk(href)}
        >
            <AntForm.Item name={["action"]} className="mb-5" label="Thao tác" labelCol={{ span: 8 }}>
                <AntRadio.Group>
                    <AntRadio value="push" className="mr-10">
                        Chuyển đến<br />
                        <small className="pl-25">History.push</small>
                    </AntRadio>
                    <AntRadio value="replace">
                        Thay thế<br />
                        <small className="pl-25">History.replace</small>
                    </AntRadio>
                </AntRadio.Group>
            </AntForm.Item>
            <AntForm.Item name={["openNewTab"]} className="mb-5" wrapperCol={{ offset: 8 }} valuePropName="checked">
                <AntCheckbox>Mở trong thẻ mới</AntCheckbox>
            </AntForm.Item>
            <AntForm.Item className="mb-5">
                <AntForm.Item name="type" hidden><AntInput /></AntForm.Item>
                <AntCollapse
                    activeKey={type}
                    accordion
                    onChange={(v) => {
                        if (v) {
                            const type = v as TypeOption;
                            form.setFieldsValue({ type })
                            setType(type);
                        }
                    }}
                    expandIcon={({ isActive }) => <AntRadio checked={Boolean(isActive)} />}
                >
                    <AntCollapse.Panel header="Cơ bản" key="string" >
                        <AntForm.Item
                            labelCol={{ span: 8 }}
                            name={["path"]}
                            label="Path"
                            className="mb-0"
                        >
                            <AntInput />
                        </AntForm.Item>
                    </AntCollapse.Panel>
                    <AntCollapse.Panel header="Nâng cao" key="object">
                        <AntForm.Item
                            labelCol={{ span: 8 }}
                            label="Pathname"
                            name={["pathname"]}
                            className="mb-10"
                        >
                            <AntInput />
                        </AntForm.Item>
                        <AntForm.Item
                            labelCol={{ span: 8 }}
                            label="Search"
                            name={["search"]}
                            className="mb-0"
                        >
                            <AntInput />
                        </AntForm.Item>
                        <AntForm.Item
                            wrapperCol={{ offset: 8 }}
                            name={["extendSearch"]}
                            valuePropName="checked"
                            className="mb-10"
                        >
                            <AntCheckbox>Giữ lại giá trị cũ</AntCheckbox>
                        </AntForm.Item>
                        <AntForm.Item
                            labelCol={{ span: 8 }}
                            label="Hash"
                            name={["hash"]}
                            className="mb-0"
                        >
                            <AntInput />
                        </AntForm.Item>
                        <AntForm.Item
                            wrapperCol={{ offset: 8 }}
                            name={["extendHash"]}
                            valuePropName="checked"
                            className="mb-5"
                        >
                            <AntCheckbox>Giữ lại giá trị cũ</AntCheckbox>
                        </AntForm.Item>
                    </AntCollapse.Panel>
                    <AntCollapse.Panel key="page" header="Chọn trong danh sách trang">
                        <AntForm.Item
                            name={["pageId"]}
                            className="mb-0"
                        >
                            <AntTreeSelect
                                placeholder="Vui lòng chọn trang"
                                treeData={treeData(pageData.data)}
                            />
                        </AntForm.Item>
                    </AntCollapse.Panel>
                </AntCollapse>
            </AntForm.Item>
        </CommonForm>
    </div>
}
export { default as useUrlConfig } from "./useUrlConfig";
export { default as UrlConfigRender } from "./render";
export default UrlConfig;