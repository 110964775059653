import { parse, stringify } from "qs";
import React, { useState } from "react";
import { Resizable } from "react-resizable";
import { useHistory, useLocation } from "react-router-dom";

const HeaderCell = ({ props }: { props: any }) => {
  const location = useLocation();
  const history = useHistory();
  const hashQs = parse(location.hash.substr(1));
  const width: number = props.width;
  const tblResizeID: string | undefined = props.tblResizeID;
  const cellID: number | string = props.cellID;

  const getColumnWidth = () => {
    const tblColWidthStr = localStorage.getItem("tblColWidth") || "{}";
    let tblColWidth: { [key: string]: number } = {};
    try {
      tblColWidth = JSON.parse(tblColWidthStr);
    } catch (e) {}
    if (!tblResizeID) {
      return width;
    }
    const _width = tblColWidth[`${tblResizeID}_${cellID}`];
    return _width || width;
  };

  const setColumnWidth = (width: number) => {
    const tblColWidthStr = localStorage.getItem("tblColWidth") || "{}";
    let tblColWidth: { [key: string]: number } = {};
    try {
      tblColWidth = JSON.parse(tblColWidthStr);
    } catch (e) {}
    localStorage.setItem(
      "tblColWidth",
      JSON.stringify({
        ...tblColWidth,
        [`${tblResizeID}_${cellID}`]: width,
      })
    );
  };
  const _width = getColumnWidth();

  const [startWidth, setStartWidth] = useState<number>(0);
  const [resizeWidth, setResizeWidth] = useState<number>(0);
  return tblResizeID && width && cellID ? (
    <Resizable
      className="cell-resize-container"
      width={resizeWidth || _width || width}
      height={0}
      onResizeStart={(e, { size }) => {
        setStartWidth(size.width);
        setResizeWidth(size.width);
        document.body.classList.add("tbl-cell-resizing");
      }}
      onResize={(e, { size }) => setResizeWidth(size.width)}
      onResizeStop={(e, { size }) => {
        document.body.classList.remove("tbl-cell-resizing");
        document.body.style.cursor = "wait !important";
        setResizeWidth(0);
        const { width } = size;
        if (width !== startWidth) {
          setColumnWidth(width);
          const hashQsResizeStr = (hashQs["resize"] as string) || "0";
          const hashQsResize = parseInt(hashQsResizeStr, 0);
          history.replace({
            search: location.search,
            hash: stringify({ ...hashQs, resize: hashQsResize + 1 }),
          });
        }
      }}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...props} width={_width || width}>
        {props.children}
        <span
          style={{
            opacity: resizeWidth > 0 ? 1 : 0,
            width: "1px",
            position: "absolute",
            background: "green",
            zIndex: 1,
            top: 0,
            bottom: 0,
            left: resizeWidth,
          }}
        />
      </th>
    </Resizable>
  ) : (
    <th {...props} width={_width || width} />
  );
};

export default HeaderCell;
