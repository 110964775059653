import React, { FC, Fragment } from "react";
import ContainerPreview from "../../container.preview";
import { ComponentProps } from "./../"
import { AntCol, AntRow } from "../../../../../../../../common";

const Component: FC<ComponentProps> = ({ page }) => {
    return <Fragment>
        <AntRow >
            <AntCol span={24}><ContainerPreview index={0} key="header" page={page} /></AntCol>
        </AntRow>
        <AntRow className="mt-10">
            <AntCol md={{ span: 16, offset: 4 }} sm={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }} className="px-10"><ContainerPreview index={1} key="row1" page={page} /></AntCol>
        </AntRow>
        <AntRow className="mt-10">
            <AntCol span={24}><ContainerPreview index={2} key="footer" page={page} /></AntCol>
        </AntRow>
    </Fragment>
}
export default Component;
