import React from 'react';
import { ComponentDataProps } from '..';
import Component from './component';
import DesignPage from './design.page';
import DesignSider from './design.sider';
import PreviewPage from './preview-page';

const ComponentMenuNav: ComponentDataProps = {
    title: "MenuNav",
    ma: "menuNav",
    siderLabel: DesignSider,
    pageLabel: DesignPage,
    previewPage: PreviewPage,
    component: <Component />,
    View: Component
};

export default ComponentMenuNav;