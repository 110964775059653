import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { removeVietnameseTones } from "../../../../../../../../common";
const _format = "dddd DD/MM/YYYY HH:mm:ss";
const DateTimeCurrent = () => {
    const [content, setContent] = useState<string>(moment().format(_format))

    useEffect(() => {
        const interval = setInterval(() => {
            setContent(moment().format(_format));
        }, 1000)
        return () => clearInterval(interval);
    }, [])
    return <Fragment>
        <i className="fa fa-calendar mr-5" />{removeVietnameseTones(content, "titleCase")} 123
    </Fragment>
}

export default DateTimeCurrent;