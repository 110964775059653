import React, { Fragment,FC } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-headphones fa-2x"></i>}
            message={title||"Hỗ trợ"}
        />
    </Fragment>
}
export default DesigSider;