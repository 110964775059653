import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AntAvatar, AntButton, AntCard, AntCarousel, AntCol, AntEmpty, AntImage, AntList, AntRow, AntSkeleton, useAntCarousel, useEdit } from "../../../../../../../../common";
import { getDataByFromIdsAndDataIds, urlPreviewFile } from '../../../../../../data-form-builder/api';
import ReactShowMoreText from "react-show-more-text";
import { IHref } from '../../../../common/url-config';
import { parse, stringify } from 'qs';
import { findbyId } from '../../../../../page/api';
const list = [
    { id: 1 },
    { id: 2 },
    { id: 3 }
]
interface ViewAllActionProps {
    hidden?: boolean;
    href?: IHref;
    iconCls?: string;
    label?: string;
    displayType?: "center" | "left" | "right" | "full";
}
interface FormItemProps {
    formId: number;
    dataIds: string[]
}
interface IDisplayType {
    viewAllAction: ViewAllActionProps;
    loading?: boolean;
    data?: any[];
    lang: string;
    itemKey: IItemKey;
    detail?: DetailProps;
}

interface ITitle {
    label?: string;
    hidden?: boolean;
    fontSize?: number;
    color?: string;
    bgColor?: string;
}

interface IItemKey {
    image: string[];
    title: string[];
    description: string[];
    time: string[];
}

interface DetailProps {
    href?: IHref
}

interface GiaTriProps {
    viewAllAction?: ViewAllActionProps;
    forms?: FormItemProps[];
    formData?: {
        [key: string]: FormItemProps[]
    };
    title?: ITitle;
    displayType?: 1 | 2 | 3 | 4;
    itemKey: IItemKey;
    autoPlay?: boolean;
    autoplaySpeed?: number;
    detail?: DetailProps
}

const PreviewPage = ({ giaTri = {} }: any) => {
    const { lang } = useParams() as { lang: string };
    const _giaTri: GiaTriProps = giaTri || {};
    const { viewAllAction, displayType, forms, title, formData, itemKey, detail } = _giaTri;
    const edit = useEdit({ key: moment().toISOString() });
    const getData = async (forms: FormItemProps[], lang: string) => {
        edit.onStart();
        const res = await getDataByFromIdsAndDataIds(forms, lang);
        if (res.status) {
            let data: any[] = [];
            (res.result || []).map((item: any) => { data = [...data, ...(item.data || [])] });
            edit.onFinish(data);
        }
        else {
            edit.onError();
        }
    }

    useEffect(() => {
        formData && lang && getData(formData[lang], lang);
    }, [formData, lang])

    return <Fragment>
        <AntCard
            title={!Boolean(title?.hidden) ? <span>{title?.label}</span> || <AntSkeleton.Input size="small" active style={{ width: 200 }} /> : undefined}
            size="small"
            className="preview-page-news mb-10"
            bordered={false}
            headStyle={{ color: title?.color, backgroundColor: title?.bgColor, fontSize: title?.fontSize || 25 }}
        >
            {
                displayType === 4 ?
                    <DisplayType4 viewAllAction={viewAllAction || {}} loading={edit.loading} data={edit.data || []} lang={lang} itemKey={itemKey} detail={detail} /> :
                    displayType === 3 ?
                        <DisplayType3 viewAllAction={viewAllAction || {}} loading={edit.loading} data={edit.data || []} lang={lang} itemKey={itemKey} detail={detail} /> :
                        displayType === 2 ?
                            <DisplayType2 viewAllAction={viewAllAction || {}} loading={edit.loading} data={edit.data || []} lang={lang} itemKey={itemKey} detail={detail} /> :
                            <DisplayType1 viewAllAction={viewAllAction || {}} loading={edit.loading} data={edit.data || []} lang={lang} itemKey={itemKey} detail={detail} />

            }
        </AntCard>
    </Fragment >
}

const getValue = (item: any, lang: string, keys: string[]): string => {
    if (item) {
        const localizations = item.localizations || [];
        const langDefault = localizations.find((lang: any) => lang.default)?.id;
        const data = item.data_formbuilder;

        return (data && data[`${lang}_${keys.find(k => data[`${lang}_${k}`])}`])
            || (langDefault && (data && data[`${langDefault}_${keys.find(k => data[`${langDefault}_${k}`])}`]))
            || "";
    }
    return "";
}

const getImageUrl = (item: any, lang: string, keys: string[]): string | undefined => {
    const localizations = (item && item.localizations) || [];
    const langDefault = localizations.find((lang: any) => lang.default)?.id;
    const data = item && item.data_formbuilder;
    if (item) {
        const id = (
            (data && data[`${lang}_${keys.find(k => data[`${lang}_${k}`])}`])
            || (data && langDefault && data[`${langDefault}_${keys.find(k => data[`${langDefault}_${k}`])}`])
            || {}
        ).id;
        return id && urlPreviewFile(id);
    }
    return undefined;
}

interface IProps {
    item: any;
    lang: string;
    keys: string[];
    loading?: boolean;
    detail?: DetailProps
}
interface IImageProps extends IProps {
    size: "large" | "default" | "small"
}
const Image: FC<IImageProps> = ({ item, lang, keys, loading, size }) => {
    return <div style={{ background: "#f5f5f5", textAlign: 'center' }}>
        {loading ? <AntSkeleton.Avatar
            active
            shape="square"
            style={size === "large" ? {
                height: 200,
                width: 200
            } : {
                    height: 100,
                    width: 100
                }}
        /> :
            <AntAvatar
                shape="square"
                style={size === "large" ? {
                    height: 200,
                    width: 200
                } : {
                        height: 100,
                        width: 100
                    }}
                src={getImageUrl(item, lang, keys)}
            />
        }
    </div>
}

const Title: FC<IProps> = ({ item, lang, keys, detail }) => {
    const href = detail?.href;
    const [_href, _setHref] = useState<string>("");
    const location = useLocation();
    const { pathname, search } = location;

    const getPageById = async (id: number, itemId: number) => {
        const res = await findbyId(id);
        if (res.status) {
            const page = res.result;
            _setHref(`${page?.page.url || ""}?id=${itemId}`);
        }

    }
    useEffect(() => {
        switch (href?.type) {
            case "string":
                _setHref(`${href.path || ""}?id=${item.id}`);
                break;
            case "object":
                const fullPath = {
                    pathname: href.pathname || pathname,
                    search: stringify({ ...(href.extendSearch ? parse(search, { ignoreQueryPrefix: true }) : {}), ...parse(href.search || ""), id: item.id }),
                    hash: href.hash
                }
                _setHref(`${fullPath.pathname}${fullPath.search ? `?${fullPath.search}` : ""}${fullPath.hash ? `#${fullPath.hash}` : ""}`);
                break;
            case "page":
                href.pageId && getPageById(href.pageId, item.id);
                break;
        }
    }, [href, item.id])
    return <Fragment>
        <Link to={_href}>
            {getValue(item, lang, keys)}
        </Link>
    </Fragment>
}

const Description: FC<IProps> = ({ item, lang, keys }) => {
    return <div>
        <ReactShowMoreText lines={3} keepNewLines more="Xem thêm" less="Xem ít hơn">
            {getValue(item, lang, keys)}
        </ReactShowMoreText>
    </div>
}

const Time: FC<IProps> = ({ item, lang, keys }) => {
    return <Fragment>
        <small>
            <i className="fa fa-calendar mr-10" />
            {getValue(item, lang, keys)}
        </small>
    </Fragment>
}

const ViewAll: FC<ViewAllActionProps> = ({
    displayType,
    hidden,
    href,
    iconCls,
    label,
}) => {
    const textAlign = displayType === "left" || displayType === "right" || displayType === "center" ? displayType : undefined;
    const [_href, _setHref] = useState<string>("");
    const history = useHistory();
    const location = useLocation();
    const { pathname, hash, search } = location;

    const getPageById = async (id: number) => {
        const res = await findbyId(id);
        if (res.status) {
            const page = res.result;
            _setHref(page?.page.url || "");
        }

    }
    useEffect(() => {
        switch (href?.type) {
            case "string":
                _setHref(href.path || "");
                break;
            case "object":
                const fullPath = {
                    pathname: href.pathname || pathname,
                    search: stringify({ ...(href.extendSearch ? parse(search, { ignoreQueryPrefix: true }) : {}), ...parse(href.search || "") }),
                    hash: href.hash
                }
                _setHref(`${fullPath.pathname}${fullPath.search ? `?${fullPath.search}` : ""}${fullPath.hash ? `#${fullPath.hash}` : ""}`);
                break;
            case "page":
                href.pageId && getPageById(href.pageId);
                break;
        }
    }, [href])
    return hidden ? null : <div style={{ textAlign }}>
        <AntButton
            block={displayType === "full"}
            type="primary"
            icon={iconCls ? <i className={`${iconCls} mr-5`} /> : undefined}
            onClick={(e) => {
                if (!href?.openNewTab) {
                    e.preventDefault();
                    href?.action === "replace" ? history.replace(_href) : history.push(_href);
                }
            }}
            target={href?.openNewTab ? "_blank" : undefined}
            href={_href}
        >
            {label || "Xem tất cả"}
        </AntButton>
    </div>
}

const DisplayType1: FC<IDisplayType> = ({
    viewAllAction,
    loading,
    data,
    lang,
    itemKey,
    detail
}) => {
    const history = useHistory();
    const { displayType, hidden, href, iconCls, label } = viewAllAction;
    const textAlign = displayType === "left" || displayType === "right" || displayType === "center" ? displayType : undefined;
    const mainData = data && data[0];
    return <AntRow>
        <AntCol md={{ span: 12 }} sm={{ span: 8 }} xs={{ span: 24 }} className="px-5">
            <AntCard
                cover={<Image
                    size="large"
                    item={mainData}
                    keys={itemKey.image}
                    lang={lang}
                    loading={loading}
                />}
                bordered={false}
                bodyStyle={{ padding: 0 }}
            >
                {
                    loading ?
                        <AntSkeleton className="mt-10" active={loading} /> :
                        mainData ?
                            <AntList.Item>
                                <AntList.Item.Meta
                                    title={<Title item={mainData} lang={lang} keys={itemKey.title} detail={detail} />}
                                    description={<Fragment>
                                        <Description item={mainData} lang={lang} keys={itemKey.description} />
                                        <Time item={mainData} lang={lang} keys={itemKey.time} />
                                    </Fragment>}
                                />
                            </AntList.Item> :
                            <AntEmpty description="Không có dữ liệu" />
                }

            </AntCard>
        </AntCol>
        <AntCol md={{ span: 12 }} sm={{ span: 14 }} xs={{ span: 24 }} className="px-5">
            <AntList
                dataSource={loading ? list : (data || []).filter((item, i) => i >= 1)}
                itemLayout="horizontal"
                locale={{
                    emptyText: <AntEmpty description="Không có dữ liệu" />
                }}
                renderItem={(item, i) => loading ? <AntList.Item>
                    <AntSkeleton
                        active
                        loading
                        avatar={{ shape: "square" }}
                    />
                </AntList.Item> :
                    <AntList.Item key={i}>
                        <AntList.Item.Meta
                            avatar={<Image
                                size="default"
                                item={item}
                                keys={itemKey.image}
                                lang={lang}
                            />}
                            title={<Title item={item} lang={lang} keys={itemKey.title} detail={detail} />}
                            description={<Fragment>
                                <Description item={item} lang={lang} keys={itemKey.description} />
                                <Time item={item} lang={lang} keys={itemKey.time} />
                            </Fragment>}
                        />
                    </AntList.Item>}
                footer={(!loading && data && data.length > 1) ?
                    <ViewAll
                        {...viewAllAction}
                    /> : undefined}
            />
        </AntCol>
    </AntRow >
}

const DisplayType2: FC<IDisplayType> = ({
    viewAllAction,
    loading,
    data,
    lang,
    itemKey,
    detail
}) => {
    const history = useHistory();
    const { displayType, hidden, href, iconCls, label } = viewAllAction;
    const textAlign = displayType === "left" || displayType === "right" || displayType === "center" ? displayType : undefined;
    const mainData = data && data[0];
    return <Fragment>
        <AntRow>
            <AntCol md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} className="px-5">
                <AntCard
                    cover={<Image
                        size="large"
                        item={mainData}
                        keys={itemKey.image}
                        lang={lang}
                        loading={loading}
                    />}
                    bordered={false}
                    bodyStyle={{ padding: 0 }}
                >
                    {
                        loading ?
                            <AntSkeleton className="mt-10" active={loading} /> :
                            mainData ?
                                <AntList.Item>
                                    <AntList.Item.Meta
                                        title={<Title item={mainData} lang={lang} keys={itemKey.title} detail={detail} />}
                                        description={<Fragment>
                                            <Description item={mainData} lang={lang} keys={itemKey.description} />
                                            <Time item={mainData} lang={lang} keys={itemKey.time} />
                                        </Fragment>}
                                    />
                                </AntList.Item> :
                                <AntEmpty description="Không có dữ liệu" />
                    }

                </AntCard>
            </AntCol>
        </AntRow>
        <AntRow>
            <AntCol md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} className="px-5">
                <AntList
                    dataSource={loading ? list : (data || []).filter((item, i) => i >= 1)}
                    itemLayout="horizontal"
                    locale={{
                        emptyText: <AntEmpty description="Không có dữ liệu" />
                    }}
                    renderItem={(item, i) => loading ? <AntList.Item>
                        <AntSkeleton
                            active
                            loading
                            avatar={{ shape: "square" }}
                        />
                    </AntList.Item> :
                        <AntList.Item key={i}>
                            <AntList.Item.Meta
                                avatar={<Image
                                    size="default"
                                    item={item}
                                    keys={itemKey.image}
                                    lang={lang}
                                />}
                                title={<Title item={item} lang={lang} keys={itemKey.title} detail={detail} />}
                                description={<Fragment>
                                    <Description item={item} lang={lang} keys={itemKey.description} />
                                    <Time item={item} lang={lang} keys={itemKey.time} />
                                </Fragment>}
                            />
                        </AntList.Item>}
                    footer={(!loading && data && data.length > 1) ?
                        <ViewAll
                            {...viewAllAction}
                        /> : undefined}
                />
            </AntCol>
        </AntRow>
    </Fragment>
}

const DisplayType3: FC<IDisplayType> = ({
    viewAllAction,
    loading,
    data,
    lang,
    itemKey,
    detail
}) => {
    const history = useHistory();
    const { displayType, hidden, href, iconCls, label } = viewAllAction;
    const textAlign = displayType === "left" || displayType === "right" || displayType === "center" ? displayType : undefined;
    return <AntRow>
        <AntCol md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} className="px-5">
            <AntList
                dataSource={loading ? list : data}
                itemLayout="horizontal"
                renderItem={(item, i) => loading ? <AntList.Item key={i}>
                    <AntSkeleton
                        active={loading}
                        loading
                        avatar={{ shape: "square", size: "large" }}
                    />
                </AntList.Item> :
                    <AntList.Item key={i}>
                        <AntList.Item.Meta
                            avatar={<Image
                                size="default"
                                item={item}
                                keys={itemKey.image}
                                lang={lang}
                            />}
                            title={<Title item={item} lang={lang} keys={itemKey.title} detail={detail} />}
                            description={<Fragment>
                                <Description item={item} lang={lang} keys={itemKey.description} />
                                <Time item={item} lang={lang} keys={itemKey.time} />
                            </Fragment>}
                        />
                    </AntList.Item>}
                footer={(!loading && data && data.length > 0) ?
                    <ViewAll
                        {...viewAllAction}
                    /> : undefined}
            />
        </AntCol>
    </AntRow>
}

const DisplayType4: FC<IDisplayType> = ({
    viewAllAction,
    loading,
    data,
    lang,
    itemKey,
    detail
}) => {
    const [index, setIndex] = useState(0);
    const dataLength = data?.length || 0;
    const antCarousel = useAntCarousel();
    return <AntRow>
        <AntCol span={24}>
            <AntCarousel
                useCarousel={antCarousel}
                autoplay
                autoplaySpeed={10000}
                draggable={true}
                pauseOnDotsHover
                style={{ width: "100%" }}
                initialSlide={index}
            >

                {loading ? <AntSkeleton
                    active={loading}
                    loading
                    avatar={{
                        shape: "square",
                        size: "large"
                    }}
                /> : (data && data?.length > 0) ? data.map((item, i) => <AntList.Item key={i}>
                    <AntList.Item.Meta
                        avatar={<Image
                            size="default"
                            item={item}
                            keys={itemKey.image}
                            lang={lang}
                        />}
                        title={<Title item={item} lang={lang} keys={itemKey.title} detail={detail} />}
                        description={<Fragment>
                            <Description item={item} lang={lang} keys={itemKey.description} />
                            <Time item={item} lang={lang} keys={itemKey.time} />
                        </Fragment>}
                    />
                </AntList.Item>) :
                        <AntEmpty description="Không có dữ liệu" />}
            </AntCarousel>
            {dataLength && dataLength > 1 ? <div style={{ textAlign: "right" }}>
                <AntButton.Group>
                    <AntButton
                        icon={<i className="fa fa-angle-left" />}
                        onClick={() => setIndex(i => {
                            const p = Math.abs((i - 1) % dataLength);
                            antCarousel.onPrev();
                            return p;
                        })}
                    />
                    <AntButton
                        icon={<i className="fa fa-angle-right" />}
                        onClick={() => setIndex(i => {
                            const p = Math.abs((i + 1) % dataLength);
                            antCarousel.onNext();
                            return p;
                        })}
                    />
                </AntButton.Group>
            </div> : null}
        </AntCol>
    </AntRow>
}

export default PreviewPage;