import { ActionProps } from ".."
export interface DataState {
    type: string;
    id: number | string;
    data: any
}
const stateDefault: DataState[] = []

export default (state: DataState[] = stateDefault, action: ActionProps): DataState[] => {
    const type = action.type;
    switch (type) {
        case "SET_DATA":
            const payload = action.payload as DataState;
            const { id, type } = payload;
            return state.findIndex(s => s.type === type && s.id === id) === -1 ?
                [...state, payload] :
                state.map(s => s.id === id && s.type === type ? payload : s)
        default:
            return state;
    }
}