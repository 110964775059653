
import { get, post, put, del, ResponseProps } from '../../../../.fetch'
const api: string = '/pagebuilder/navigationmenu';


interface ItemProps {
    data: MenuProps;
    pagination: Pagination;
}
interface Pagination {
    items_per_page: number;
    total: number;
    current_page: number;
}
interface MenuItemProps {
    id: number;
    menuId: number;
    name: string;
    sapXep?: null | number;
    parentId?: null | number;
    type: 0 | 1 | 2;
    url?: string | null;
    pageId?: number;
    menuItemPage?: null | {};
    listMenuItemChildren: MenuItemProps[]
}
interface MenuProps {
    id: number;
    name: string;
    type: 0 | 1 | 2;
    listMenuItem: MenuItemProps[]
}

interface PaginationProps {
    itemPerPage: number;
    page: number;
    sortType: string;
    isName: string;
    isNgayTao: string;
    search: string;
}


interface FetchAllResponseProps extends Omit<ResponseProps, 'result'> {
    result?: ItemProps[]
}

export const fetchAllMenu = async (data: PaginationProps): Promise<FetchAllResponseProps> => await get({
    url: `${api}/menu`,
    data
})
interface CreateResponseProps extends Omit<ResponseProps, "result"> {
    result?: MenuProps
}

export const createMenu = async (data: MenuProps): Promise<CreateResponseProps> => await post({
    url: `${api}/menu`,
    data,
})
export const updateMenu = async (data: MenuProps) => await put({
    url: `${api}/menu`,
    data,
    auth: true
})
export const deleteMenu = async (data: number[]) => await del({
    url: `${api}/menu`,
    data,
})

interface FindByIdResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps
}

export const findMenuById = async (id: number, search?: object): Promise<FindByIdResponseProps> => await get({
    url: `${api}/menu/${id}`,
    data: search
})
export const fetchMenuTree = async (data: PaginationProps): Promise<FetchAllResponseProps> => await get({
    url: `${api}/menutree`,
    data
})
export const updateTrangThaiMenu = async (data: MenuProps) => await put({
    url: `${api}/menu/updateDefault`,
    data
})
/**
 * 
 * Menu item
 */

export interface MenuItemByIdItemProps extends Omit<ItemProps, "data"> {
    data: MenuItemProps
}
interface FetchAllMenuItemByIdResponseProps extends Omit<ResponseProps, 'result'> {
    result?: MenuItemByIdItemProps[]
}

export const fetchAllMenuItemByMenuId = async (id: number): Promise<FetchAllMenuItemByIdResponseProps> => await get({
    url: `${api}/itemmenu/${id}`,
})
interface CreateMenuItemByIdResponseProps extends Omit<ResponseProps, 'result'> {
    result?: MenuItemProps
}

export const createMenuItem = async (data: MenuItemProps): Promise<CreateMenuItemByIdResponseProps> => await post({
    url: `${api}/itemmenu`,
    data,
})

interface UpdateMenuItemByIdResponseProps extends Omit<ResponseProps, 'result'> {
    result?: MenuItemProps[]
}

export const updateMenuItem = async (data: MenuItemProps): Promise<UpdateMenuItemByIdResponseProps> => await put({
    url: `${api}/itemmenu`,
    data,
})

interface FindMenuItemByIdResponseProps extends Omit<ResponseProps, 'result'> {
    result?: MenuItemByIdItemProps[]
}
export const findMenuItemById = async (id: number): Promise<FindMenuItemByIdResponseProps> => await get({
    url: `${api}/itemmenu/${id}`,
})

export const deleteMenuItem = async (data: number[]) => await del({
    url: `${api}/itemmenu`,
    data,
})

