
import { useForm } from 'antd/lib/form/Form';
import React, { Fragment, useEffect, useState } from 'react';
import Form from './form'
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { parse } from 'qs';
import moment from 'moment';
import { getAllDataFormbuilder, getAllFormbuilder, getDetailDataFormbuilder, update } from '../../../../../../data-form-builder/api';
import { AntAlert, AntButton, AntCard, AntMessage, useEdit } from '../../../../../../../../common';

interface ItemKeys {
    ngayLapBaoCao: number;
    doanhNghiep: number;
    dichVuDuLich: number;
    ketQua: number;
    trangThai: number;
    doanhThu: number;
}
interface FormBaoCao {
    formId: number,
    idBaoCao: number,
}
interface ItemKeyDN {
    formDN: number,
    ten: number,
    trangThai: number,
    ma: number
}

interface ItemKeyDoanhThu {
    show?: boolean,
    label?: string,
}

export interface GiaTriProps {
    itemKeys: ItemKeys,
    formBaoCao: FormBaoCao,
    itemKeyDN: ItemKeyDN,
    itemKeyDoanhThu: ItemKeyDoanhThu,
}

const Edit = ({ list, giaTri }: any) => {
    const [data, setData] = useState({});
    const _giaTri: GiaTriProps = giaTri || {};
    const { itemKeys, formBaoCao, itemKeyDN, itemKeyDoanhThu } = _giaTri;
    const [doanhNghiep, setDoanhNghiep] = useState([]);
    const [formDN, setFormDN] = useState([]);
    const location = useLocation()
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const [form] = useForm();
    const history = useHistory();
    const localizations = useSelector((state: any) => state.config.localizations.data).map((el: any) => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const edit = useEdit();
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";
    const local = [...localizations].find((el) => { if (el.default) return el; });
    const trangThai = [
        {
            value: "dadongbo",
            label: "Đã đồng bộ"
        },
        {
            value: "taomoi",
            label: "Tạo mới"
        }
    ]

    useEffect(() => {
        formBaoCao.formId && getAllData()
    }, [formBaoCao.formId])
    const getAllData = async () => {
        const data = {
            dataId: qs.id,
            formId: formBaoCao.formId,
            localization: localizationSearch,
            sort: {}
        }
        edit.onStart();
        const res: any = await getDetailDataFormbuilder(data)
        if (res.status) {
            setFormDN(res.data.data_formbuilder[`${itemKeyDN.formDN}`])
            edit.onFinish({
                ngayLapBaoCao: moment(res.data.data_formbuilder[`${itemKeys.ngayLapBaoCao}`]).isValid() ? moment(res.data.data_formbuilder[`${itemKeys.ngayLapBaoCao}`]) : null,
                doanhNghiep: res.data.data_formbuilder[`${itemKeys.doanhNghiep}`][0].data,
                status: res.data.data_formbuilder[`${itemKeys.trangThai}`],
                id: res.data.id,
                ketQua: res.data.data_formbuilder[`${itemKeys.ketQua}`],
                ...(itemKeyDoanhThu?.show ? {
                    doanhThu: res.data.data_formbuilder[`${itemKeys.doanhThu}`]
                } : {})
            })
        } else {
            edit.onError(res.msg)
        }
    }

    useEffect(() => {
        getform(itemKeyDN.formDN, (keys: any) => setDoanhNghiep(keys))
    }, [itemKeyDN.formDN])

    const getform = async (item: any, callback: any) => {
        const data = {
            formId: item,
            localization: localizationSearch,
            tranngThai: 1
        }
        list.onLoading()
        const res = await getAllDataFormbuilder(data)
        if (res.status) {
            let keys = res.result.data
            callback && callback(keys);
            list.onSuccess();
        }
        else {
            list.onError(res.msg);
        }
    }

    const onSubmit = async (e: any) => {
        const dataValue: any = {
            form_id: formBaoCao.formId.toString(),
            data_id: qs.id,
            data_formbuilder: {
                [`${itemKeys.ngayLapBaoCao}`]: e.ngayLapBaoCao.toISOString(),
                [`${itemKeys.trangThai}`]: e.status,
                [`${itemKeys.ketQua}`]: JSON.stringify(data),
                [`${itemKeys.doanhNghiep}`]: [{ ...formDN, data: e.doanhNghiep }],
                [`${localizationSearch}_${itemKeys.ngayLapBaoCao}`]: e.ngayLapBaoCao.toISOString(),
                [`uni_hidden_select_${itemKeys.trangThai}`]: trangThai.find(item => item.value === e.status)?.label,
                [`uni_hidden_select_vi_${itemKeys.trangThai}`]: trangThai.find(item => item.value === e.status)?.label,
                [`${localizationSearch}_${itemKeys.trangThai}`]: e.status,
                [`${localizationSearch}_${itemKeys.ketQua}`]: JSON.stringify(data),
                [`${localizationSearch}_${itemKeys.doanhNghiep}`]: [{ ...formDN, data: e.doanhNghiep }],
            },
            localizations: [local],
            tinh_trang: 1,
            trang_thai: 1
        }
        list.onLoading();
        const res = await update(dataValue);
        if (res.status) {
            list.setData(list.getData().map((item: any) => item.id === dataValue.id ? { ...item, ...dataValue } : item))
            form.resetFields(['doanhNghiep', 'status']);
            setData({})
            AntMessage.success(res.msg)
            history.goBack();
            list.onSuccess();
        } else {
            AntMessage.error(res.msg)
            list.onError(res.msg)
        }
    }

    useEffect(() => {
        if (edit.data && edit.data.ketQua) {
            try {
                setData(JSON.parse(edit.data.ketQua));
            } catch (error) {
            }
        }
    }, [edit.data])

    return <Fragment>
        <AntCard
            title="CHỈNH SỬA BÁO CÁO"
            extra={
                <Fragment>
                    {edit.successed && <AntButton type="primary" icon={<i className="fa fa-save mr-5" />} onClick={() => form.submit()} className="mr-5">Lưu</AntButton>}
                    <AntButton type="default" icon={<i className="fa fa-reply mr-5" />} onClick={() => history.goBack()}> Quay lại</AntButton>
                </Fragment>
            }

        >
            {edit.loading && <AntAlert type="loading" message="Vui lòng chờ..." />}
            {edit.failed && <AntAlert type="error" message={edit.message} />}
            {edit.successed &&
                <Form
                    data={data}
                    setData={setData}
                    id={formBaoCao.idBaoCao}
                    form={form}
                    onSubmit={onSubmit}
                    initialValue={edit.data}
                    doanhNghiep={doanhNghiep}
                    itemKeyDN={itemKeyDN}

                />
            }
        </AntCard>
    </Fragment>
};

export default Edit;