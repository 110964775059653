import { ActionProps } from ".."
export interface FoderProps {
    len?: number,
    parent_id?: number;
    name?: string;
    description?: string;
    last_modified_date?: string;
    id?: number;
    created_date?: string;
    type?: 0 | 1;
    children?: Array<FoderProps>;
    title: string,
    key: number
}
const stateDefault: Array<FoderProps> = [
    // {
    //     id: 0,
    //     name: "ROOT",
    //     type: 0,
    //     key: -1,
    //     title: "ROOT"
    // }
]
const checkId = (list: Array<FoderProps>, folderId: any, data: Array<FoderProps>): Array<FoderProps> => {
    return list?.map((item: any) => {
        if (parseInt(item.id) === parseInt(folderId)) {
            return {
                ...item,
                children: data,
                // children: data?.map(_item => ({ ..._item, children: (item.children || []).find((it: FoderProps) => it.id === _item.id)?.children || [] }))
            }
        }
        else {
            return {
                ...item,
                children: checkId(item.children, folderId, data)
            }
        }
    })
}
const createFoder = (state: Array<FoderProps>, key: number, data: FoderProps): Array<FoderProps> => {
    if (key === 0) {
        return state = [...state, data]
    } else {
        return state?.map((item: any) => {
            if (parseInt(item.id) === key) {
                return {
                    ...item,
                    children: [...item.children || [], data]
                }
            } else {
                return {
                    ...item,
                    children: createFoder(item.children, key, data)
                }
            }
        })
    }
}
const deleteForder = (state: Array<FoderProps>, key: number): Array<FoderProps> => {
    return state.filter((item: any) => parseInt(item.id) !== key)?.map(item => ({
        ...item,
        children: deleteForder(item.children || [], key)
    }))
}
const updateForder = (state: Array<FoderProps>, key: number, data: FoderProps): Array<FoderProps> => {
    return state?.map((item: any) => {
        if (parseInt(item.id) === key) {
            return {
                ...item,
                ...data
            }
        } else {
            return {
                ...item,
                children: updateForder(item.children, key, data)
            }
        }
    })
}

const remove = (list: Array<FoderProps>, dataCheckBox: Array<FoderProps>): Array<FoderProps> => {
    return list?.filter((item: any) => dataCheckBox.findIndex(dtcb => dtcb.id === item.id) === -1)
        .map(item => ({
            ...item,
            children: remove(item.children || [], dataCheckBox)
        }));
}
const add = (list: Array<FoderProps>, target_id: number, dataCheckBox: Array<FoderProps>): Array<FoderProps> => {
    return list?.map(item => ({
        ...item,
        children: item.id === target_id ? [...(item.children || []), ...dataCheckBox] : add(item.children || [], target_id, dataCheckBox)
    }));
}
const deleteForderAndFile = (list: Array<FoderProps>, data: Array<FoderProps>): Array<FoderProps> => {
    return list.filter((item: any) => !data.includes(item.id))?.map(item => ({
        ...item,
        children: deleteForderAndFile(item.children || [], data)
    }))
}
const move = (list: Array<FoderProps>, target_id: number, dataCheckBox: Array<FoderProps>): any => {
    return add(remove(list, dataCheckBox), target_id, dataCheckBox);
}
const updateStatusSuccess = (list: Array<FoderProps>, data: Array<number>): any => {
    return list.map((v: any) => ({
        ...v,
        trang_thai: data.includes(v.id) ? 1 : 0,
        children: updateStatusSuccess(v.children || [], data),
    }))
}
export default (state: Array<FoderProps> = stateDefault, action: ActionProps): Array<FoderProps> => {
    const type = action.type;
    switch (type) {
        case "PAGE_FILE":
            const pages = action.payload as Array<FoderProps>
            return pages
        case "GET_DETAIL_FORDER-CONTENT":
            return action.payload.key === 0 ? action.payload.data : checkId(state, action.payload.key, action.payload.data)
        case "CREATE_CONTENT_SUCCESS":
            return createFoder(state, action.payload.key, action.payload.data)
        case "DELETE_CONTENT_SUCCESS":
            return deleteForder(state, action.payload)
        case "UPDATE_CONTENT_SUCCESS":
            return updateForder(state, action.payload.folder_id, action.payload)
        case "MOVE_CONTENT":
            const { target_id, dataCheckBox } = action.payload;
            return move(state, target_id, dataCheckBox)
        case "DELETE_FODER_AND_FILE_CONTENT_SUCCESS":
            return deleteForderAndFile(state, action.payload);
        case "UPDATE_STATUS_CONTENT_SUCCESS":
            return updateStatusSuccess(state, action.payload);
        default:
            return state;
    }
}
