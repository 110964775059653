import React, { Fragment, useState ,useEffect} from "react";
import { AntLayout, AntCard, CommonList, useList, AntTag, AntDrawer } from '../../../../../../../../common';
import { CommonButtonAction } from '../../../../../../../../common/c-action'
import { useHistory, useLocation } from "react-router";
import { parse, stringify } from "qs";
import moment from "moment";
import ModalUpdateOrder from './update'
import DetailOrder from './modaldetail'
const DATE_FORMAT = "DD/MM/YYYY";
const ProviewPage = () => {
    const {search}=useLocation();
    const history = useHistory();
    const qs = parse(search,{ignoreQueryPrefix:true});
    const [itemUpdate, setItemUdate] = useState({})
    const [itemDetail, setItemDetail] = useState({})
    const data = [
        {
            _id: "10603f46-272c-4b1a-9be5-674c29516e5c",
            ma_san_pham: "string",
            ten_nguoi_lien_he: "string",
            sdt_nguoi_lien_he: "string",
            email_nguoi_lien_he: "string",
            so_luong: "string",
            mo_ta: "string",
            ghi_chu: "string",
            ngay_bat_dau: "string",
            ngay_ket_thuc: "string",
            nguoi_tao: "admin",
            ngay_tao: "2021-10-01T01:49:17.037Z",
            ngay_sua: "2021-10-01T01:49:17.037Z",
            da_xoa: 0,
            trang_thai: "CHUAXULY",
            _class: "vn.unitech.gialai.sms.domain.DatHangSanPham"
          },
          {
            _id: "cf6cdb6d-177e-4862-8cc7-74308fa51895",
            ma_san_pham: "string",
            ten_nguoi_lien_he: "string",
            sdt_nguoi_lien_he: "string",
            email_nguoi_lien_he: "string",
            so_luong: "string",
            mo_ta: "string",
            ghi_chu: "string",
            ngay_bat_dau: "string",
            ngay_ket_thuc: "string",
            nguoi_tao: "admin",
            ngay_tao: "2021-10-01T01:49:18.262Z",
            ngay_sua: "2021-10-01T01:49:18.262Z",
            da_xoa: 0,
            trang_thai: "THANHCONG",
            _class: "vn.unitech.gialai.sms.domain.DatHangSanPham"
          },
          {
            _id: "eb76603b-2a4c-437e-88fb-be26df99bb00",
            ma_san_pham: "string",
            ten_nguoi_lien_he: "string",
            sdt_nguoi_lien_he: "string",
            email_nguoi_lien_he: "string",
            so_luong: "string",
            mo_ta: "string",
            ghi_chu: "string",
            ngay_bat_dau: "string",
            ngay_ket_thuc: "string",
            nguoi_tao: "admin",
            ngay_tao: "2021-10-01T01:53:58.374Z",
            ngay_sua: "2021-10-01T01:53:58.374Z",
            da_xoa: 0,
            trang_thai: "DAHUY",
            _class: "vn.unitech.gialai.sms.domain.DatHangSanPham"
          },
          {
            _id: "ff06748a-f9c6-407b-b8f3-ec664f3d228d",
            ma_san_pham: "string",
            ten_nguoi_lien_he: "string",
            sdt_nguoi_lien_he: "string",
            email_nguoi_lien_he: "dfgdfgdfgfd",
            so_luong: "string",
            mo_ta: "gdfgsfg gdfgdfg",
            ghi_chu: "string",
            ngay_bat_dau: "string",
            ngay_ket_thuc: "string",
            nguoi_tao: "admin",
            ngay_tao: "2021-10-01T01:59:01.895Z",
            ngay_sua: "2021-10-01T02:10:39.263Z",
            "nguoi_sua": "headless_cms_api",
            da_xoa: 0,
            trang_thai: "KHONGTHANHCONG",
            _class: "vn.unitech.gialai.sms.domain.DatHangSanPham"
          },
          {
            _id: "987492d7-45f4-411b-b2bf-1b69fb8ada64",
            ma_san_pham: "string",
            ten_nguoi_lien_he: "string",
            sdt_nguoi_lien_he: "string",
            email_nguoi_lien_he: "string",
            so_luong: "string",
            mo_ta: "string",
            ghi_chu: "string",
            ngay_bat_dau: "string",
            ngay_ket_thuc: "string",
            nguoi_tao: "admin",
            ngay_tao: "2021-10-01T02:11:49.854Z",
            ngay_sua: "2021-10-01T02:11:49.854Z",
            da_xoa: 0,
            trang_thai: "THANHCONG",
            _class: "vn.unitech.gialai.sms.domain.DatHangSanPham"
          },
          {
            _id: "b6c1e07b-7abd-4ef0-99a7-5cb4d19cb5ed",
            ma_san_pham: "string",
            ten_nguoi_lien_he: "string",
            sdt_nguoi_lien_he: "string",
            email_nguoi_lien_he: "string",
            so_luong: "string",
            mo_ta: "string",
            ghi_chu: "string",
            ngay_bat_dau: "string",
            ngay_ket_thuc: "string",
            nguoi_tao: "headless_cms_api",
            ngay_tao: "2021-10-01T02:15:08.038Z",
            ngay_sua: "2021-10-01T02:15:08.038Z",
            da_xoa: 0,
            trang_thai: "CHUAXULY",
            _class: "vn.unitech.gialai.sms.domain.DatHangSanPham"
          }
    ]
    useEffect(() => {
        list.setData(data)
    }, [])
    const getColorStatus = (stt: any) => {
        let color = "yellow"
        let nameStt = "Chưa xử lý"
        switch (stt) {
            case "CHUAXULY":
                color = "yellow"
                nameStt = "Chưa xử lý"
                break;
            case "THANHCONG":
                color = "green"
                nameStt = "Thành công"
                break;
            case "KHONGTHANHCONG":
                color = "red"
                nameStt = "Không thành công"
                break;
            case "DAHUY":
                color = "red"
                nameStt = "Đã hủy"
                break;
            default:
                break;
        }
        return {color, nameStt};
    }
    const list = useList();
    return <Fragment>
        <AntLayout>
            <AntCard>
                <CommonList
                list={list}
                    columns={[
                        {
                            title: 'Mã đơn hàng',
                            dataIndex: 'maDonHang',
                            ellipsis: true,
                            render: (text, item) => 
                                <span 
                                    className="link" 
                                    onClick={() =>  {
                                        history.replace({search:stringify({...qs,detailId:item._id})})
                                        setItemDetail(item)
                                    }}
                                >
                                    {item.ma_san_pham}
                                </span>
                        },
                        {
                            title: 'Trạng Thái',
                            dataIndex: 'moTa',
                            render: (_, record) => {
                                return (
                                    <AntTag
                                        color={getColorStatus(record.trang_thai).color}
                                        key={record._id}
                                    >
                                        {getColorStatus(record.trang_thai).nameStt}
                                    </AntTag>
                                );
                            },
                        },
                        {
                            title: "Ghi chú",
                            dataIndex: "ghi_chu",
                            width: 100,
                            align: "center",
                            ellipsis: true,
                            render: (value) => value
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_date",
                            width: 100,
                            align: "center",
                            ellipsis: true,
                            render: (value) => moment(value).isValid() && moment(value).format(DATE_FORMAT)
                        },
                        {
                            title: "Ngày cập nhật",
                            dataIndex: "last_modified_date",
                            width: 120,
                            align: "center",
                            ellipsis: true,
                            render: (value) => moment(value).isValid() && moment(value).format(DATE_FORMAT),
                        },
                        {
                            title: "Người cập nhật",
                            dataIndex: "last_modified_by",
                            width: 130,
                            align: "center",
                            ellipsis: true,
                            render: (_, record) => record.last_modified_by || record.created_by
                        },
                        {
                            title: 'Thao tác',
                            width: 150,
                            render: (t, item) => {
                                return (
                                    <Fragment>
                                        <CommonButtonAction
                                            type="primary"
                                            shape="circle"
                                            action_tooltip="Chi tiết"
                                            className="mr-2"
                                            onClick={() =>  {
                                                history.replace({search:stringify({...qs,detailId:item._id})})
                                                setItemDetail(item)
                                            }}
                                        >
                                            <i className="fa fa-info-circle" />
                                        </CommonButtonAction>
                                        <CommonButtonAction
                                            type="primary"
                                            shape="circle"
                                            action_tooltip="Sửa"
                                            className="mr-2"
                                            onClick={() =>  {
                                                history.replace({search:stringify({...qs,updateId:item._id})})
                                                setItemUdate(item)
                                            }}
                                        >
                                            <i className="fa fa-pencil-square-o" />
                                        </CommonButtonAction>
                                    </Fragment>
                                );
                            }
                        },
                    ]}
                />
            </AntCard>
        </AntLayout>
        <AntDrawer
            width={400}
            title="Chi tiết đơn hàng"
            visible={Boolean(qs.detailId)} 
            onClose={()=>history.replace({search:stringify({...qs,detailId:undefined})})}
        >
            <DetailOrder itemDetail = {itemDetail}/>
        </AntDrawer>
        
        <ModalUpdateOrder 
            itemUpdate = {itemUpdate}
        />
    </Fragment>
}
export default ProviewPage;