import CommonList from "./c-list";
import CommonScrollBar from "./c-scroll-bar"
import CommonForm from "./c-form";
import CommonEditor from "./c-editor";
import CommonLoadingBar from "./loading-bar";
import CommonLazyLoading from "./c-lazy-load";
import CommonDocumentPreview from "./c-preview";
/**
 * 
 * @param {*} type 
 * ex "xxxx-xx-x-xxxx-xxxxxx-xx"
 */
const uuidv4 = (type?: string) => {
    return (type && typeof type === "string" ? type : 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx').replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const uuid = (type?: string) => uuidv4(type);

export const listenerAttributeChange = (elm: any, callback: any, filter = { attributes: true }) => {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === "attributes") {
                callback(elm.attributes);
            }
            return;
        });
    });
    observer.observe(elm, filter);
}

export {
    uuid,
    CommonForm,
    CommonList,
    CommonScrollBar,
    CommonEditor,
    CommonLoadingBar,
    CommonLazyLoading,
    CommonDocumentPreview
};
export * from "./fnc";
export * from "./antd";
export * from "./c-auth";
export { default as Base64 } from "./c-base-64";
export * from "./c-cookie";
export * from "./c-hook";