import { ActionProps } from ".."

interface MenuBuilderOptionProps {
    id?: number;
    giaTri?: string;
    sapXep: number;
}

declare type HistoryProps = {
    transactionCode: string;
    formBuilderOption: Array<MenuBuilderOptionProps[]>,
}
export interface MenuProps {
    form: {
        id: number;
        name: string;
        createdDate?: string;
        createdBy?: string;
        formOptionText: string;
        delete?: boolean;
    };
    formField: Array<MenuBuilderOptionProps[]>;
    history?: {
        current?: HistoryProps,
        data: HistoryProps[]
    }
}
const stateDefault: Array<MenuProps> = []

const menus = (state: MenuProps[] = stateDefault, action: ActionProps): MenuProps[] => {
    const type = action.type;
    switch (type) {
        case 'MENU_LOAD':
            const menus = action.payload as MenuProps[]
            return menus
    }


    return state;
}


export default menus