import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { ModalProps } from "antd";
import { stringify } from "qs";
import React, { FC, Fragment, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AntButton, AntCheckbox, AntCol, AntDivider, AntForm, AntModal, AntRow, AntTabs, CommonForm } from "../../../../../../../common";

interface ILoginProps extends ModalProps {

}

declare type TabKeys = "login" | "register" | "forgot-password";
const Login: FC<ILoginProps> = ({
    title,
    visible,
    closable,
    onCancel
}) => {
    const { pathname } = useLocation();
    const history = useHistory();
    return <Fragment>
        <AntModal
            title={title}
            visible={visible}
            closable={closable}
            onCancel={onCancel}
            footer={false}
            width={450}
            style={{ top: 50 }}
            destroyOnClose
        >
            <CommonForm
                data={[
                    {
                        col: 24,
                        name: "username",
                        colClassName: "px-10",
                        formItemProps: {
                            rules: [
                                { required: true, message: "Vui lòng nhập tên đăng nhập" }
                            ]
                        },
                        fieldProps: {
                            autoComplete: "new-password",
                            size: "large",
                            placeholder: "Tên đăng nhập",
                            prefix: <UserOutlined />
                        }
                    },
                    {
                        col: 24,
                        name: "password",
                        type: "password",
                        colClassName: "mt-10 px-10",
                        formItemProps: {
                            rules: [
                                { required: true, message: "Vui lòng nhập mật khẩu" }
                            ]
                        },
                        fieldProps: {
                            autoComplete: "new-password",
                            size: "large",
                            placeholder: "Mật khẩu",
                            prefix: <LockOutlined />
                        }
                    }
                ]}
            >
                <AntRow className="mt-10">
                    <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} className="px-10 py-5">
                        <AntForm.Item name="remember" noStyle>
                            <AntCheckbox>Ghi nhớ đăng nhập</AntCheckbox>
                        </AntForm.Item>
                    </AntCol>
                    <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: "right" }} className="px-10 py-5">
                        <Link to={{
                            pathname,
                            search: stringify({ ["forgot-password"]: true })
                        }}>Quên mật khẩu</Link>
                    </AntCol>
                </AntRow>
                <AntRow className="mt-10">
                    <AntCol span={24} className="px-10">
                        <AntButton
                            size="large"
                            type="primary"
                            block
                            htmlType="submit"
                            icon={<i className="fa fa-sign-in mr-10" />}
                        >Đăng nhập</AntButton>
                    </AntCol>
                    <AntCol span={24} className="mt-10 px-10">
                        <AntButton size="large" icon={<i className="fa fa-registered  mr-5" />} block type="dashed" onClick={() => history.push({ search: stringify({ register: true }) })}>Đăng ký</AntButton>
                    </AntCol>
                </AntRow>
                <AntDivider orientation="center" className="px-10">hoặc đăng nhập với</AntDivider>
                <AntRow>
                    <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} className="px-10  py-5">
                        <AntButton
                            size="large"
                            block
                            type="primary"
                            danger
                            icon={<i className="fa fa-google mr-5" />}
                        >Google</AntButton>
                    </AntCol>
                    <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} className="px-10 py-5">
                        <AntButton
                            size="large"
                            block
                            icon={<i className="fa fa-user mr-5" />}
                        >Quản trị</AntButton>
                    </AntCol>
                </AntRow>
            </CommonForm>
        </AntModal >
    </Fragment >
}

export default Login;