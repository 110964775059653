import React, { FC, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Skeleton, Tooltip, Popconfirm, Dropdown, Button } from "antd";
import { ActionProps } from "./types";


const ExtraAction: FC<ActionProps> = ({
    action_url,
    action_tooltip,
    action_confirm,
    action_dropdown,
    action_hide,
    action_permission,
    onClick,
    href,
    className,
    disabled,
    ...props
}) => {
    const history = useHistory();
    const allow: boolean = true
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 500);
    }, [])

    return !action_hide && allow ? <Fragment>
        {
            loaded ? <Tooltip title={action_tooltip}>
                {
                    action_confirm ?
                        <Popconfirm
                            {...action_confirm}
                            disabled={disabled}
                            okText={action_confirm.okText || <Fragment><i className="fa fa-check i-btn-l" />Đồng ý</Fragment>}
                            cancelText={action_confirm.cancelText || <Fragment><i className="fa fa-times i-btn-l" />Huỷ</Fragment>}
                            onConfirm={(e) => {
                                action_confirm.onConfirm && action_confirm.onConfirm(e)
                                if (action_url) {
                                    e?.preventDefault();
                                    history.push(action_url);
                                }
                            }}
                        >
                            {
                                action_dropdown ? <Dropdown {...action_dropdown} disabled={disabled}>
                                    <Button
                                        className={`extra-action ${className || ""}`}
                                        href={action_url || href}
                                        onClick={(e) => {
                                            onClick && onClick(e);
                                            if (action_url) {
                                                e.preventDefault();
                                                history.push(action_url);
                                            }
                                        }}
                                        {...props}
                                    />
                                </Dropdown> :
                                    <Button
                                        className={`extra-action ${className || ""}`}
                                        href={action_url || href}
                                        onClick={(e) => {
                                            onClick && onClick(e);
                                            if (action_url) {
                                                e.preventDefault();
                                                history.push(action_url);
                                            }
                                        }}
                                        {...props}
                                    />
                            }
                        </Popconfirm> :
                        action_dropdown ? <Dropdown {...action_dropdown} disabled={disabled}>
                            <Button
                                className={`extra-action ${className || ""}`}
                                href={action_url || href}
                                onClick={(e) => {
                                    onClick && onClick(e);
                                    if (action_url) {
                                        e.preventDefault();
                                        history.push(action_url);
                                    }
                                }}
                                {...props}
                            />
                        </Dropdown> :
                            <Button
                                className={`extra-action ${className || ""}`}
                                href={action_url || href}
                                onClick={(e) => {
                                    onClick && onClick(e);
                                    if (action_url) {
                                        e.preventDefault();
                                        history.push(action_url);
                                    }
                                }}
                                {...props}
                            />

                }
            </Tooltip> :
                <Skeleton.Button active shape={props.shape} className="extra-action-skeleton" />}
    </Fragment> : null
}

export default ExtraAction;