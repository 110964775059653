import { parse } from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { create, getDataReferenceByDataId, _delete } from '../../../../../../data-form-builder/api';
import { AntButton, useList } from '../../../../../../../../common';

const Like = ({
    itemKeyLike, df, check, formData, edit
}) => {
    const [itemEdit, setItemEdit] = useState(false);
    const [id, setId] = useState(null);
    const [idLike, setIdLike] = useState(null);
    const list = useList()
    const auth = useSelector((state) => state.auth?.invalid)
    const localizations = useSelector((state) => state.config.localizations.data).map((el) => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const location = useLocation();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const keyUrl = check === "hardConfig" ? formData?.hardConfig : (formData?.dynamicConfig && qs[formData?.dynamicConfig]);
    const username = useSelector((state) => state.auth?.accountCurrent?.name)
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";
    const local = [...localizations].find((el) => { if (el.default) return el; })
    const [tong, setTong] = useState(0)

    useEffect(() => {
        edit.data && setTong(edit.data.data_formbuilder[`${itemKeyLike?.tongLike}`] ? parseInt(edit.data.data_formbuilder[`${itemKeyLike?.tongLike}`]) : 0)
    }, [edit.data]);
    const likeBaiViet = async () => {
        const data = {
            client: "portal",
            tinh_trang: 1,
            nguoi_tao: username,
            trang_thai: 1,
            form_id: itemKeyLike?.formLike?.toString(),
            localizations: [local],
            field_text: [],
            data_formbuilder: {
                [`${localizationSearch}_${itemKeyLike?.baiViet}`]: df,
                [`${itemKeyLike?.like}`]: "1",
                [`${localizationSearch}_${itemKeyLike?.like}`]: "1",
                [`${itemKeyLike?.baiViet}`]: df
            }
        }
        const dataDel = {
            ids: [
                id || idLike
            ]
        }
        const form_id = itemKeyLike.formLike
        if (itemEdit) {
            list.onLoading();
            const res = await _delete(dataDel, form_id);
            if (res.status) {
                setTong(c => c - 1)
                setItemEdit(false)
                list.onSuccess();
            } else {
                list.onError(res.msg);
            }
        } else {
            list.onLoading();
            const res = await create(data);
            if (res.status) {
                setIdLike(res.result.id)
                setTong(c => c + 1)
                setItemEdit(true);
                list.onSuccess();
            } else {
                list.onError(res.msg);
            }
        }
    }
    useEffect(() => {
        itemKeyLike?.formLike && onFetchAllDataFormLike();
    }, [itemKeyLike?.formLike, auth])
    const onFetchAllDataFormLike = async () => {
        let res = {}
        let data = {
            dataId: keyUrl,
            formId: itemKeyLike?.formLike,
            formFiledId: itemKeyLike?.baiViet
        }
        list.onLoading();
        res = await getDataReferenceByDataId(data)
        if (res.status) {
            let kiemTraTen = {}
            kiemTraTen = res.result?.data.find((item) => item.nguoi_tao === username);
            if (kiemTraTen) {
                setItemEdit(true);
                setId(kiemTraTen.id);
            } else {
                setItemEdit(false);
            }
        }
        else {
            setItemEdit(false);
        }
    }
    return <AntButton style={{ color: 'black' }} type="link"
        disabled={auth}
        onClick={() => likeBaiViet()}
    >
        {!auth && itemEdit ?
            <Fragment><i className="fa fa-thumbs-up mr-5 ml-5" />{tong} Bỏ thích</Fragment> :
            <Fragment><i className="fa fa-thumbs-o-up mr-5 ml-5" />{tong} Thích</Fragment>}
    </AntButton>
};

export default Like;