import React, { Fragment } from "react";
import { getAuth, removeAuth, AntModal } from "../common"
import { startLoad, HOST_NAME, endLoad, DelProps, ResponseProps, urlLogin } from ".";
import freshToken from "./.refresh";

const del = async ({
    hostName,
    url,
    data,
    showLoading = true,
    token,
    auth = true,
    controller,
    showMsg = true,
    loadingMessage
}: DelProps): Promise<ResponseProps> => {
    showLoading && startLoad(loadingMessage)
    const _token = token || getAuth().token;
    const remember = getAuth().remember;
    const response = await fetch(`${hostName || HOST_NAME}${url}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...(auth && _token ? { 'Authorization': _token } : {})
        },
        signal: controller?.signal,
        body: JSON.stringify(data || {}),
    })
        .catch(e => {
            showLoading && endLoad();
            const res: Promise<ResponseProps> = new Promise(resolve => resolve({ error: true, statusCode: 0 }));
            return {
                json: () => res,
                status: 0
            }
        })
    showLoading && endLoad()
    const _data = await response.json();
    switch (response.status) {
        case 200:
            return { error: false, statusCode: 200, ..._data };
        case 401:
            if (remember) {
                return await freshToken({ hostName, showLoading, callback: ({ token }) => del({ hostName, url, data, showLoading, token, auth, controller, showMsg }) })
            }
            return new Promise(resolve => {
                AntModal.warning({
                    title: "Phiên làm việc đã kết thúc",
                    content: "Vui lòng đăng nhập lại để tiếp tục sử dụng",
                    onOk: () => {
                        removeAuth();
                        window.location.replace(urlLogin)
                        resolve({ error: true, statusCode: response.status, ..._data })
                    },
                    onCancel: () => {
                        removeAuth();
                        window.location.replace(urlLogin)
                        resolve({ error: true, statusCode: response.status, ..._data })
                    },
                    okText: <Fragment><i className="fa fa-sign-in mr-5" />Đăng nhập</Fragment>
                })
            })
        default:
            return { error: true, statusCode: response.status, ..._data };
    }
}
export default del;