import { combineReducers, Reducer, CombinedState } from "redux";
import localizations, { ItemProps as LocalizationsProps } from "./localizations";
import danhMucDongBos, { ItemProps as DanhMucDongBosProps } from "./danhMucDongBo";
import cauHinh, { ItemProps as cauHinhProps } from "./cauHinh";
import mapProvider, { ItemProps as mapProviderProps } from "./mapProvider";
import modelDoanhNghiep, { ItemProps as modelDoanhNghiepProps } from "./modelDoanhNghiep";
import pagination, { ItemProps as paginationProps } from "./pagination";
import countNotification, { ItemProps as countNotificationProps } from "./countNotification";
import baoCaoTongHopCauHinh, { ItemProps as BaoCaoTongHopCauHinhProps } from "./baoCaoTongHop";

export default combineReducers({
    localizations,
    cauHinh,
    mapProvider,
    modelDoanhNghiep,
    pagination,
    danhMucDongBos,
    countNotification,
    baoCaoTongHopCauHinh
}) as Reducer<CombinedState<ConfigProps>>;

export interface ConfigProps {
    localizations: LocalizationsProps,
    cauHinh: cauHinhProps[],
    mapProvider: mapProviderProps,
    modelDoanhNghiep: modelDoanhNghiepProps,
    pagination: paginationProps,
    danhMucDongBos: DanhMucDongBosProps,
    countNotification: countNotificationProps,
    baoCaoTongHopCauHinh: BaoCaoTongHopCauHinhProps
}