import React, { FC, Fragment, useState, useEffect } from "react";
import { AntCol, AntImage, AntRow, useList } from "../../../../../../../../common";
import Popup360 from "./popupcamera360"
import { getDataByFromIdsAndDataIds } from '../../../../../../data-form-builder/api'
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';
interface ITitle {
    label?: string;
    hidden?: boolean
}
export interface BoxProps {
    title: string;
    bgColor: string;
    color: string;
    hidden?: boolean;
    link: any,
    data_formbuilder: any,
}
export interface GiaTriProps {
    title?: ITitle;
    listBox: BoxProps[];
    listcamera360: any;
    color: string;
    bgColor: string,
    formId: number
}
const DesignPage = ({ giaTri }: any) => {
    const _giaTri = giaTri as GiaTriProps;
    const location = useLocation();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const lang = qs.lang ? qs.lang : 'vi';
    const { listBox, listcamera360, color, bgColor, formId } = _giaTri;
    const boxLength = (listBox || []).filter(item => !item.hidden).length;
    const list = useList()
    const span = parseInt(`${boxLength > 0 ? 24 / boxLength : 0}`, 0)
    const getDataListCamera360 = async (lang: any) => {
        try {
            list.onLoading()
            const res = await getDataByFromIdsAndDataIds([{ formId: formId || 58, dataIds: listcamera360 || [] }], lang || "vi")
            list.onSuccess()
            list.setData(res?.result[0]?.data)
        } catch (error) {
            list.onError('Không tải được dữ liệu')
        }
    }
    useEffect(() => {
        getDataListCamera360(lang)
    }, [lang])
    return <Fragment>
        <div className="px-10">
            <AntRow
                gutter={[24, 16]}
                className="py-10"
            >
                {
                    (list.getData())
                        .filter(item => !item.hidden)
                        .map((item, i) => {
                            return <ItemType1 box={item} key={i} span={span} color={color} bgcolor={bgColor} lang={lang} />
                        })
                }
            </AntRow>
        </div>
    </Fragment>
}
const ItemType1: FC<{ box: BoxProps, span: number, color: string, bgcolor: string, lang: any }> = ({ box, lang, color, bgcolor }) => {
    const [open, setOpen] = useState(false)
    const checkLang = (lang: string, box: any) => {
        switch (lang) {
            case 'vi':
                return `${box?.data_formbuilder?.vi_236}`
            case 'en':
                return `${box?.data_formbuilder?.en_236}`
            default:
                return `${box.box?.data_formbuilder['236']}`
        }
    }
    return <AntCol md={{ span: 6 }} sm={{ span: 8 }} xs={{ span: 12 }}>
        <div style={{ height: '100%', textAlign: 'center', background: bgcolor ? bgcolor : "white", padding: 5, color: color, backgroundColor: bgcolor ? bgcolor : "white", borderRadius: 5, width: '100%', cursor: 'pointer' }} className="p-20" onClick={() => setOpen(true)}>
            <div style={{ height: 180, overflow: 'hidden' }}>
                <AntImage
                    width='auto'
                    height='auto'
                    src={box?.data_formbuilder['238'] ? box?.data_formbuilder['238'] : "error"}
                    preview={false}
                    style={{ maxWidth: '100%', objectFit: 'revert', display: 'block' }}
                />
            </div>
            <div style={{ textAlign: "center", fontSize: 15, height: 30, padding: '5px 0' }}>
                <span style={{ color: color ? color : "#0000000" }}>{checkLang(lang, box)}</span>
            </div>
        </div>
        {open ? <Popup360 open={open} setOpen={setOpen} box={box} lang={lang} /> : null}
    </AntCol>
}
export default DesignPage;