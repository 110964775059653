import { RouteProps } from "./../";

const routes: Array<RouteProps> = [
    {
        path: "he-thong-cong",
        children: [
            {
                path: "nguoi-dung",
                children: [
                    {
                        path: ["them-moi", "cap-nhat/:username"],
                        pageTitle: "Người dùng",
                        componentPath: "components/system-portal/account",
                    },
                ]
            },
            {
                path: "nhom-nguoi-dung",
                children: [
                    {
                        path: ["them-moi", "cap-nhat/:id", "phan-quyen/:groupcode", "thanh-vien/:id"],
                        pageTitle: "Nhóm người dùng",
                        componentPath: "components/system-portal/account-group",
                    },
                ]
            }
        ]
    }
]
export default routes;