import { ActionProps } from ".."
import { LayoutName } from "../../components/builder/page-builder/builder/sider/layouts/components";

interface FormBuilderOptionProps {
    // id?: number;
    // giaTri?: string;
    // ma: ComponentRenderMa;
    // sapXep: number;
}

declare type HistoryProps = {
    transactionCode: string;
    layout: LayoutName,
    formBuilderOption: Array<FormBuilderOptionProps[]>,
}
export interface FormProps {
    form: {
        id: number;
        name: string;
        createdDate?: string;
        createdBy?: string;
        formOptionText: string;
        delete?: boolean;
    };
    formField: Array<FormBuilderOptionProps[]>;
    history?: {
        current?: HistoryProps,
        data: HistoryProps[]
    }
}
const stateDefault: Array<FormProps> = []

const UPDATE_FORM = (list: FormProps[], update: FormProps): FormProps[] => {
    return list.map(value => {
        return value.form.id === update.form.id ? { ...value, ...update } : value
    })
}

const forms = (state: FormProps[] = stateDefault, action: ActionProps): FormProps[] => {
    const type = action.type;
    switch (type) {
        case 'FORM_LOAD':
            const forms = action.payload as FormProps[]
            return forms;
        case 'CREATE_FORM':
            const form = action.payload as FormProps;
            return [form, ...(state || [])]
        case 'UPDATE_FORM':
            const update = action.payload as FormProps;
            return UPDATE_FORM(state, update)


        default:
            return state;
    }

}
export default forms