import React, { FC, Fragment, ReactNode } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-bookmark-o fa-2x" />}
            message={title || "Navigation"}
        />
    </Fragment>
}

export default DesigSider;