import React, { Fragment, CSSProperties } from "react";
import { AntLayout, AntCard, CommonForm, useList, AntRow, AntCol, AntForm, AntDatePicker, AntSelect, AntButton } from '../../../../../../../../common';
import { CommonButtonAction } from '../../../../../../../../common/c-action'
import moment from "moment";
import { DateFormat } from "../../../../../../../../common/c-form";
const contentStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: "#FFFFFF"
};
export const DATE_FORMAT = "DD/MM/YYYY";
const DesignPage = () => {
    const list = useList();
    return <Fragment>
        <AntLayout style={contentStyle}>
            <AntCard
                title={"Báo cáo thống kê"}
                extra={[
                    <AntButton type="primary" icon={<i className="fa fa-download mr-5"/>} >Tải file excel</AntButton>
                ]}
            >
            <CommonForm
                initialValues={{
                }}
                onFinish={(values) => {}}
                >
                    <AntRow gutter={[16,16]}>
                        <AntCol span={8}>
                            <AntForm.Item
                                    name="ngay"
                                >
                                <AntDatePicker.RangePicker format={DateFormat}/>
                            </AntForm.Item>
                        </AntCol>
                        <AntCol span={6}>
                            <AntForm.Item
                                    name="maDonViCungCapDichVu"
                                    noStyle
                                >
                                <AntSelect
                                    placeholder="Đơn vị cung cấp"
                                    style={{width: "100%"}}
                                >
                                    <AntSelect.Option key={""} value={""}>Tất cả</AntSelect.Option>
                                </AntSelect>
                            </AntForm.Item>
                        </AntCol>
                        <AntCol span={6}>
                            <AntForm.Item
                                    name="maLoaiSanPham"
                                    noStyle
                                >
                                <AntSelect
                                    placeholder="Loại sản phẩm"
                                    style={{width: "100%"}}
                                >
                                    <AntSelect.Option key={""} value={""}>Tất cả</AntSelect.Option>
                                </AntSelect>
                            </AntForm.Item>
                        </AntCol>
                    </AntRow>
                </CommonForm>
            </AntCard>
        </AntLayout>
    </Fragment>
}
export default DesignPage;