import _ from "lodash";
import { urlPreviewFile } from "../../../../../../data-form-builder/api";

export const getListFormIdReference = (formItem: string[], lang: string) => {
    let listForm: any[] = [];
    formItem.map(fm => {
        try {
            const fmArr = fm.split("_");
            const jsonFormReference = JSON.parse(fmArr[0]);
            if (!_.isEmpty(jsonFormReference)) {
                if (listForm.find(el => (el.formId + "" === jsonFormReference.formId + ""))) {
                    for (let i = 0; i < listForm.length; i++) {
                        if (listForm[i].formId + "" === jsonFormReference.formId + "") {
                            listForm[i].formFields = [...listForm[i].formFields, Number(fmArr[1])];
                            //if (listForm[i].formReferenceId.indexOf(Number(jsonFormReference.id)) === -1) {
                            //    listForm[i].formReferenceId = [...listForm[i].formReferenceId,];
                            //}
                        }
                    }
                } else {
                    listForm.push({
                        formReferenceId: [Number(jsonFormReference.id)],
                        type: jsonFormReference.type,
                        localization: lang,
                        formId: Number(jsonFormReference.formId),
                        formFields: [Number(fmArr[1])],
                        trang_thai: 1
                    })
                }
            }
        } catch (error) { }
    })
    return listForm;
}

export const getFormFieldsMap = (result: any): any[] => {
    return (result?.formFields || []).filter((el: any) => el.formFieldFieldType === "map");
}

export const creeateCategory = (result: any, lang: string): any[] => {
    let arr: any[] = [];
    const formFields = result?.formFields || [];
    const dataReference = result?.dataReference || [];
    try {
        formFields.map((ff: any) => {
            const fieldOptionText = JSON.parse(ff.formFieldFieldOptionText);
            if (fieldOptionText.settings?.options?.type === "manual") {
                fieldOptionText.settings.options.select.map((mp: any) => {
                    arr.push({ value: `${ff.formId}_${ff.formFieldId}_${mp.id}`, label: mp.value });
                })
            } else if (fieldOptionText.settings?.options?.type === "reference") {
                const id_reference = fieldOptionText.settings.options?.id_reference || 0;
                const dataReferenceByFormId = dataReference.find((el: any) => el.formId === id_reference) || {};
                const value = fieldOptionText.settings.options?.options_reference?.key || "";
                const label = fieldOptionText.settings.options?.options_reference?.value || "";
                (dataReferenceByFormId?.data || []).map((mp: any) => {
                    const valueValue = getValue(mp, lang, [value]);
                    const labelValue = getValue(mp, lang, [label]);
                    arr.push({ value: `${ff.formId}_${ff.formFieldId}_${valueValue}`, label: labelValue });
                })
            }
        })
    } catch (error) { }
    return arr;
}

export const getValue = (item: any, lang: string, keys: string[]): any => {
    if (item) {
        const localizations = item.localizations || [];
        const langDefault = localizations.find((lang: any) => lang.default)?.id;
        const data = item.data_formbuilder;

        return (data && data[`${lang}_${keys.find(k => data[`${lang}_${k}`])}`])
            || (langDefault && (data && data[`${langDefault}_${keys.find(k => data[`${langDefault}_${k}`])}`]))
            || "";
    }
    return "";
}

export const getImageUrl = (item: any, lang: string, keys: string[]): string | undefined => {
    const localizations = (item && item.localizations) || [];
    const langDefault = localizations.find((lang: any) => lang.default)?.id;
    const data = item && item.data_formbuilder;
    if (item) {
        const id = (
            (data && data[`${lang}_${keys.find(k => data[`${lang}_${k}`])}`])
            || (data && langDefault && data[`${langDefault}_${keys.find(k => data[`${langDefault}_${k}`])}`])
            || {}
        ).id;
        return id && urlPreviewFile(id);
    }
    return undefined;
}

export const changeTab = (activeKey: any, data: any[], lang: string): any[] => {
    const partsTab = activeKey && activeKey.split("_") || [];
    if (partsTab.length < 3) {
        return [];
    }
    const formIdTab = partsTab[0];
    const fieldTab = partsTab[1];
    const keyValueTab = partsTab[2];
    let arr: any[] = [];
    data.map((el: any) => {
        if (el.formId === Number(formIdTab)) {
            (el.data || []).map((mp: any) => {
                if (getValue(mp, lang, [fieldTab]) + "" === keyValueTab + "") {
                    arr.push(mp);
                }
            })
        }
    })
    return arr;
}

export const changeSelect = (activeKey: string, data: any[], lang: string, selectKey: any): any[] => {
    const partSelects = selectKey && selectKey.split("_") || [];
    if (partSelects.length < 3) {
        return [];
    }

    const partsTab = activeKey && activeKey.split("_") || [];
    if (partsTab.length < 3) {
        return [];
    }
    // Select
    const formSelectId = partSelects[0];
    const fieldSelect = partSelects[1];
    const keyValueSelect = partSelects[2];
    // Tab
    const formIdTab = partsTab[0];
    const fieldTab = partsTab[1];
    const keyValueTab = partsTab[2];

    let arr: any[] = [];
    data.map((el: any) => {
        if (formSelectId === formIdTab && el.formId === Number(formSelectId)) {
            (el.data || []).map((mp: any) => {
                if ((getValue(mp, lang, [fieldSelect]) + "" === keyValueSelect + "") && (getValue(mp, lang, [fieldTab]) + "" === keyValueTab + "")) {
                    arr.push(mp);
                }
            })
        }
    })
    return arr;
}

export const getListMap = (activeKey: string, itemKey: string[], lang: string, data: any[]): any[] => {
    const tabSplit = activeKey && activeKey.split("_") || [];
    if (tabSplit.length < 3) {
        return [];
    }
    const formIdTab = tabSplit[0];
    const fieldTab = tabSplit[1];
    const keyValueTab = tabSplit[2];

    let keyArr: string[] = [];
    itemKey.map((mp: any) => {
        const mpSplit = mp.split("_");
        const mpParse = JSON.parse(mpSplit[0]);
        if (Number(formIdTab) === Number(mpParse.formId)) {
            keyArr.push(mpSplit[1]);
        }
    });

    let result: any[] = [];
    const dataFilter = data.find((dt: any) => Number(dt.formId) === Number(tabSplit[0]));
    (dataFilter?.data || []).map((dt: any) => {
        if (getValue(dt, lang, [fieldTab]) + "" === keyValueTab + "") {
            const valueMap = getValue(dt, lang, keyArr);
            if (valueMap !== "" && !_.isEmpty(valueMap)) {
                result.push({ id: dt.id, value: valueMap });
            }
        }
    });
    return result;
}