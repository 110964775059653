import React, { FC, Fragment } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "..";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-newspaper-o fa-2x" />}
            message={title || "News Detail"}
        />
    </Fragment>
}

export default DesigSider;