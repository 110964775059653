import React, { CSSProperties, FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { IHref, useUrlConfig } from "./";

interface IProps {
    href?: IHref;
    children?: ReactNode;
    style?: CSSProperties;
    className?: string;
}
const UrlConfigRender: FC<IProps> = ({ href, children, style, className }) => {
    const _url = useUrlConfig({ href });
    return <Link to={_url.path || ""} replace={_url.replace} target={_url.target} style={style} className={className}>
        {children}
    </Link>
}

export default UrlConfigRender;