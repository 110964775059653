import React, { Fragment } from 'react';
import { AntLayout } from '../../../../../../../../common';

const Component = () => {
    return <Fragment>
    <div style={{ background: "green", height: 300 }}>

    </div>
    </Fragment>
};

export default Component;