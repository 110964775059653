
import React, { Fragment, useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { AntAlert, AntCard, AntRate, uuid, useList, AntImage } from "./../../../../../../../../common";
import { parse } from 'qs';
import { getAllDataFormbuilder, getDataByFromIdsAndDataIds } from "./../../../../../../data-form-builder/api/index";
import { RenderProps } from "../index"
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { HOST_NAME } from './../../../../../../../../.fetch'

interface ItemKeyForm {
    formId: number,
    name: number,
    hotline: number,
    website: number,
    phone: number,
    map: number,
    description: number,
    rate: number,
    img: number,
}
interface ItemKeyPrice {
    name: number,
    price: number,
}
interface Id {
    hard?: string;
    dynamic: string;
}

export interface GiaTriProps {
    itemKeyForm?: ItemKeyForm;
    itemKeyPrice?: ItemKeyPrice;
    id?: Id;
    check: "hardConfig" | "dynamicConfig";
}

const PreviewPage: FC<RenderProps> = ({ giaTri, lang = "vi" }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { itemKeyForm, itemKeyPrice, id, check, } = _giaTri;
    const location = useLocation();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const keyUrl = check === "hardConfig" ? id?.hard : (id?.dynamic && qs[id?.dynamic]);
    const [iD] = useState<string>(uuid());
    const [size] = useState<number>(16);
    const [read] = useState<string | null>(null)
    const [price, setPrice] = useState(null);
    const [center, setCenter] = useState([0, 0])
    const [editData, setEditData] = useState<any>([])
    const idBaiViet = keyUrl as string;
    const list = useList();

    useEffect(() => {
        const getData = async (lang: string) => {
            if (itemKeyForm?.formId && idBaiViet) {
                list.onLoading()
                const res = await getDataByFromIdsAndDataIds([{ formId: itemKeyForm?.formId, dataIds: [idBaiViet] }], lang);
                let data: any[] = [];
                if (res.status) {
                    (res.result || []).map((item: any) => { data = [...data, ...(item.data || [])] });
                    setEditData(data[0]);
                    setCenter([data[0][`data_formbuilder`][`${itemKeyForm?.map}`][`lat`], data[0][`data_formbuilder`][`${itemKeyForm?.map}`][`lng`]]);
                    list.onSuccess()
                }
                else {
                    list.onError(res.msg)
                }
            }
        }
        getData(lang);
    }, [])

    useEffect(() => {
        editData && editData.data_formbuilder && getPrice(editData.data_formbuilder[`${itemKeyPrice?.price}`])
    }, [editData, itemKeyPrice?.price])

    const getPrice = async (keys: any) => {
        const formId = keys.map((item: any) => item.id)
        const data = {
            formId: Number(formId),
            localization: lang
        }
        const res = await getAllDataFormbuilder(data)
        if (res.status) {
            const data = res.result.data.filter((item: any) => item.id === editData?.data_formbuilder[`${itemKeyPrice?.price}`][0][`data`][0]);
            setPrice(data[0][`data_formbuilder`][`${itemKeyPrice?.name}`])
        }
    }

    return <Fragment>
        <AntCard
            className="preview-page-news mb-10"
            bordered={false}
            size="small"
        >
            {list.loading && <AntAlert type="loading" message="Vui lòng chờ..." />}
            {list.error && <AntAlert type="error" message={list.error || "Lỗi"} />}
            {list.successed && <Fragment>
                <div style={{ fontSize: 25, color: "#186b23" }}>
                    <b >{editData?.data_formbuilder[`${itemKeyForm?.name}`]}</b>
                </div>
                {
                    editData?.data_formbuilder[`${itemKeyForm?.img}`] && <AntImage width={"100%"} src={`${HOST_NAME}/cms/cmis/document/preview/${editData?.data_formbuilder[`${itemKeyForm?.img}`][`id`]}`} />
                }
                <AntAlert
                    banner
                    type="warning"
                    showIcon={false}
                    className="mb-10"
                    message={<Fragment>
                        <div style={{ display: "flex" }}>
                            <div style={{ textAlign: "left" }}>
                                <div className="mt-5">
                                    Khoản giá : {price}
                                </div>

                            </div>
                            <div style={{ flex: 1, textAlign: "right" }}>
                                <div className="mr-10">
                                    Lượt đánh giá : <AntRate allowHalf disabled defaultValue={editData.data_formbuilder[`${itemKeyForm?.rate}`]} />
                                </div>
                            </div>
                        </div>
                    </Fragment>}
                />
                {
                    read && <div style={{ textAlign: "center" }}>
                        <audio controls>
                            <source src={`https://translate.google.com/translate_tts?client=tw-ob&tl=vi&q=tuy%E1%BB%87t+v%E1%BB%9Di${read}`} />
                        </audio>
                    </div>
                }
                <div
                    id={iD}
                    className="news-detail-content"
                    style={{ textAlign: "justify", fontSize: size, overflow: "hidden" }}
                    dangerouslySetInnerHTML={{ __html: editData.data_formbuilder[`${itemKeyForm?.description}`] || editData.data_formbuilder[`vi_${itemKeyForm?.description}`] || "" }}
                />
                <MapContainer
                    style={{ height: "200px" }}
                    zoom={8}
                    center={center}
                    boundsOptions={{ padding: [13, 13] }}
                    scrollWheelZoom={false}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={center}>
                        <Popup>
                            {editData.data_formbuilder[`${itemKeyForm?.name}`]}
                        </Popup>
                    </Marker>
                </MapContainer>
            </Fragment>}
        </AntCard>
    </Fragment >
}
export default PreviewPage;