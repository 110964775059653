import React, { Fragment, } from 'react';
import { useHistory } from 'react-router';
import { AntButton, AntForm, AntSelect, CommonForm, AntTable, AntInputNumber } from '../../../../../../../../common';
import { optionsBaoCao } from "./preview.page";

const Form = ({
    doanhNghiep = [],
    id,
    form,
    initialValue = {},
    onSubmit,
    itemKeyDN = {},
    data = {},
    setData
}) => {
    const temp = optionsBaoCao.find(item => item.value === id)?.temp;
    const history = useHistory();
    const trangThai = [
        {
            value: "dadongbo",
            label: "Đã đồng bộ"
        },
        {
            value: "taomoi",
            label: "Tạo mới"
        }
    ]
    const render = (colType, item, text, row, index) => {
        switch (colType) {
            case "sum":
                const sumValue = (row.sum && Array.isArray(row.sum[item.dataIndex]) ? row.sum[item.dataIndex].reduce((c = 0, v) => c + parseInt(data[v] || "0"), 0) : 0);
                sumValue !== data[`${item.dataIndex}-${index}`] && setData(d => ({
                    ...d,
                    [`${item.dataIndex}-${index}`]: sumValue
                }))
                return sumValue;
            case "input":
                return <AntInputNumber
                    className="bckq-input"
                    onBlur={(e) => setData(d => ({
                        ...d,
                        [`${item.dataIndex}-${index}`]: isNaN(e.target.value) ? 0 : parseInt(e.target.value)
                    }))}
                    min={0}
                    defaultValue={data[`${item.dataIndex}-${index}`] || 0}
                    key={data[`${item.dataIndex}-${index}`] || 0}
                    style={{ width: "100%" }}
                />;
            case "select":
                return <AntSelect
                    style={{ width: "100%" }}
                    placeholder="Chọn quốc tịch"
                    options={[]}
                />;
            default:
                return <div style={{ fontWeight: row.bold ? "bold" : undefined, fontStyle: row.italic ? "italic" : undefined, color: row.color }}>
                    {text}
                </div>;
        }
    }

    return <Fragment>
        <CommonForm
            form={form}
            onFinish={onSubmit}
            initialValues={initialValue}
            focusFirstField={false}
            data={[
                {
                    col: 12,
                    type: "date",
                    name: "ngayLapBaoCao",
                    formItemProps: {
                        label: "Ngày lập báo cáo"
                    }
                },
                {
                    col: 12,
                    name: "doanhNghiep",
                    type: "select",
                    formItemProps: {
                        label: "Doanh nghiệp"
                    },
                    fieldProps: {
                        disabled: initialValue.doanhNghiep ? true : false,
                        options: doanhNghiep.map(li => ({ label: li.data_formbuilder[`${itemKeyDN.ten}`], value: li.id }))
                    },
                },
                {
                    col: 12,
                    name: "status",
                    type: "select",
                    formItemProps: {
                        label: "Trạng thái"
                    },
                    fieldProps: {
                        options: trangThai
                    }
                },
            ]}
        >
            <AntForm.Item>
                <AntTable
                    bordered
                    size="small"
                    defaultExpandAllRows
                    expandIcon={({ record }) => null}
                    columns={temp && temp.data.columns.map(item => {
                        return {
                            ...item,
                            render: (text, row, index) => {
                                const colType = row.colType && row.colType[item.dataIndex];
                                return {
                                    children: render(colType, item, text, row, index),
                                    props: {
                                        colSpan: row.colSpan && row.colSpan[item.dataIndex],
                                    },
                                };
                            }
                        }
                    })}
                    dataSource={temp && temp.data.body.map((item, index) => ({ ...item, index }))}
                    pagination={false}
                />
            </AntForm.Item>
            <AntForm.Item style={{ textAlign: "center" }}>
                <AntButton style={{ width: "100px" }} icon={initialValue ? <i className="fa fa-floppy-o mr-5" /> : <i className="fa fa-plus mr-5" />} className="mr-5" type="primary" htmlType="submit">{initialValue ? "Lưu" : "Thêm mới"}</AntButton>
                <AntButton style={{ width: "100px" }} type="default" icon={<i className="fa fa-reply mr-5" />} onClick={() => history.goBack()}>Hủy</AntButton>
            </AntForm.Item>
        </CommonForm>

    </Fragment>
};

export default Form;