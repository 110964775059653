import React, { Fragment, FC } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-filter fa-2x" aria-hidden="true"></i>}
            message={title || "Option danh mục"}
        />
    </Fragment>
}
export default DesigSider;