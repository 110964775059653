import React from "react";
import Component from "./component";
import DesignSider from "./design.sider";
import DesignPage from "./design.page";
import PreviewPage from "./preview.page";
import { ComponentDataProps } from "..";

const Componenthtml: ComponentDataProps = {
    title: "ComponentHtml",
    ma: "componenthtml",
    siderLabel: DesignSider,
    pageLabel: DesignPage,
    previewPage: PreviewPage,
    component: <Component />,
    View: Component,
    showProperties: true
}
export default Componenthtml;