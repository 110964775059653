import React, { CSSProperties, Fragment, useState, useEffect } from 'react';
import { AntLayout, AntCard, CommonList, useList } from '../../../../../../../../common';
import { CommonButtonAction } from '../../../../../../../../common/c-action'
const contentStyle: CSSProperties = {
    backgroundColor: "#FFFFFF"
};
const PreviewPage = () => {
    const list = useList();
    return <Fragment>

    </Fragment>
};

export default PreviewPage