import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { create, getDataReferenceByDataId, update } from '../../../../../../data-form-builder/api';
import { AntButton, useList } from '../../../../../../../../common';

const View = ({
    itemKeyView, df, check, formData, edit, name_database
}) => {
    const localizations = useSelector((state) => state.config.localizations.data).map((el) => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const location = useLocation();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const keyUrl = check === "hardConfig" ? formData?.hardConfig : (formData?.dynamicConfig && qs[formData?.dynamicConfig]);
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";
    const local = [...localizations].find((el) => { if (el.default) return el; })
    const [number, setNumber] = useState(0)
    const list = useList();
    useEffect(() => {
        edit.data && setNumber(edit.data.data_formbuilder[`${itemKeyView?.tongView}`] ? parseInt(edit.data.data_formbuilder[`${itemKeyView?.tongView}`]) : 0)
    }, [edit.data])
    const postData = async (number) => {
        const data = {
            client: "portal",
            tinh_trang: 1,
            created_date: new Date(),
            trang_thai: 1,
            form_id: itemKeyView?.formView?.toString(),
            localizations: [local],
            name_database,
            field_text: [itemKeyView?.formView?.toString()],
            data_formbuilder: {
                [`${localizationSearch}_${itemKeyView?.baiViet}`]: df,
                [`${itemKeyView?.view}`]: number.toString(),
                [`${localizationSearch}_${itemKeyView?.view}`]: number.toString(),
                [`${itemKeyView?.baiViet}`]: df
            }
        }
        list.onLoading();
        const res = await create(data);
        if (res.status) {
            setNumber(res.result.data_formbuilder[`${itemKeyView.view}`])
            list.onSuccess();
        } else {
            list.onError(res.msg);
        }
    }
    const putData = async (item) => {
        list.onLoading();
        const res = await update(item);
        if (res.status) {
            setNumber(res.result.data_formbuilder[`${itemKeyView.view}`])
            list.onSuccess();
        } else {
            list.onError(res.msg);
        }
    }

    useEffect(() => {
        itemKeyView?.formView && df.length > 0 && onFetchAllDataFormView();
    }, [itemKeyView?.formView, df.length])

    const onFetchAllDataFormView = async () => {
        let res = {}
        let data = {
            dataId: keyUrl,
            formId: itemKeyView?.formView,
            formFiledId: itemKeyView?.baiViet,
        }
        list.onLoading();
        res = await getDataReferenceByDataId(data)
        if (res.status && res.result && Array.isArray(res.result.data) && res.result.data.length === 1) {
            // đã có dữ liệu -> update
            let item = res.result.data[0];
            const view = parseInt(item.data_formbuilder[`vi_${itemKeyView?.view}`]) + 1 || 1;
            item.data_formbuilder[`vi_${itemKeyView?.view}`] = view
            item.data_formbuilder[`${itemKeyView?.view}`] = view
            item.data_id = item.id;
            item.form_id = itemKeyView?.formView?.toString();
            item.field_text = [itemKeyView?.formView?.toString()]
            item.tinh_trang = 1
            item.trang_thai = 1
            putData(item);
        }
        else {
            // create
            setNumber(1);
            postData(1);
        }
    }

    return <AntButton
        style={{ color: 'black' }}
        type={"link"}
        icon={<i className="fa fa-eye mr-5 ml-5" />}
    >
        {number} Lượt xem
    </AntButton>
};

export default View;