import removeVietnameseTones from "./remove-vietnamese-tones";

declare type TypeProps = "like"
const isMatch = (string1?: string, string2?: string, type: TypeProps = "like"): boolean => {
    if (string1 && string2) {
        switch (type) {
            default:
                return removeVietnameseTones(string1).toLowerCase().includes(removeVietnameseTones(string2).toLowerCase());
        }
    }
    return true;
}

export default isMatch;