
import React, { FC, Fragment, Suspense, useEffect, useState } from 'react';
import { AntAlert, AntButton, AntCol, AntForm, AntList, AntRow, AntSkeleton, CommonScrollBar, unique, useList } from "../../../../../../../../common";
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { getDataSearchByKeyAndEnum } from '../../../../../../data-form-builder/api';
import MapUnitily from './map';
import DisplayList from './display_list';
import DisplayGrid from './display_grid';
import { RenderProps } from '..';

export interface GiaTriProps {
    [key: string]: any
}

const Unitily: FC<RenderProps> = ({ giaTri, lang }) => {
    const history = useHistory()
    const { search } = useLocation();
    const qs = parse(search, { ignoreQueryPrefix: true });
    const _giaTri: GiaTriProps = giaTri || {};
    const { itemKeyVanChuyen, itemKeyXe, itemKeyYTe, itemKeyATM, itemKeyLuuTru, itemKeyDiemDen, itemKeyAmThuc, itemKeyGiaiTri, itemKeyMuaSam } = _giaTri;
    const [itemKey, setItemKey]: any = useState(null);
    const list = useList()
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState(true);
    const [type, setType] = useState<boolean>(true)
    const [formId, setFormId] = useState<number>(itemKeyDiemDen?.formDiemDen)
    const [keyItem, setkeyItem]: any = useState("itemKeyDiemDen");
    const [s, setS]: any = useState(null)
    const [select, setSelect] = useState<[]>(itemKeyDiemDen?.fieldSelect);

    useEffect(() => {
        list.setData([])
        getAllFormBuilder(formId, keyItem, page, s, select)
    }, [formId, s])

    const getAllFormBuilder = async (formId: number, key: any, _page: number, search: string, select: []) => {
        const item = key.key || key
        const data = {
            field_selects: select || [],
            formId,
            list_data_search: [],
            key_search: search || "",
            localization: lang,
            pagination: {
                currentPage: _page || page,
                pageSize: 10,
            },
            trangThai: 1
        }
        list.onLoading();
        const res = await getDataSearchByKeyAndEnum([data])
        if (res.status) {
            setItemKey(_giaTri[`${item}`])
            if (res.result[0]) {
                const data = res.result[0].data
                list.setData(c => (unique([...c, ...data], (i1: any, i2: any) => i1.id === i2.id)))
                const { currentPage, pageSize, total } = res.result[0].pagination;
                list.setSelectedRows(total)
                setHasMore(total - currentPage * pageSize > 0)
                list.onSuccess();
            } else {
                list.setData([])
                list.setSelectedRows([0])
                setHasMore(false)
                list.onSuccess()
            }
        } else {
            list.setSelectedRows([0])
            list.setData([])
            setPage(1)
            list.onError(res.msg)
        }
    }

    const onscroll = (event: any, formId: any) => {
        const clientHeight = event.target.clientHeight
        const scrollHeight = event.target.scrollHeight
        const scrollTop = event.target.scrollTop
        const scrollBottom = scrollHeight - scrollTop - clientHeight;
        if (!list.loading && hasMore && scrollBottom <= 25) {
            setPage((page: number) => {
                getAllFormBuilder(formId, keyItem, page + 1, s, select);
                return page + 1
            })
        }
    }

    return <Fragment>
        <AntRow gutter={16}>
            <AntCol className="col-lg-7 col-md-6 col-xs-12 no-padding" >
                <Suspense fallback={<AntAlert type="loading" message="" />}>
                    <MapUnitily itemKey={itemKey} list={list} lang={lang} />
                </Suspense>
            </AntCol>
            <AntCol className="col-lg-5 col-md-6 col-xs-12" style={{ backgroundColor: "#FFFFFF", padding: "0 26px 0 12px" }}>
                <Fragment >
                    <div className="map-item-filter" style={{ marginBottom: "-27px" }}>
                        <AntForm
                            onFinish={(e) => {
                                setS(e.search)
                                setPage(1)
                            }}
                        >
                            <div className='form-row'>
                                <div className='col-6'>
                                    <AntForm.Item name="search"
                                        rules={[
                                            { required: true, message: 'Vui lòng không nhập khoảng trắng đầu tiên', transform: (value) => value ? value.trim() : 'Vui lòng nhập ' }]}
                                    >
                                        <input
                                            onFocus={() => document.getElementById("unitily-container")?.scrollIntoView()}
                                            placeholder="Nhập từ khóa tìm kiếm"
                                            type="text"
                                            className="form-control"
                                            onChange={() => setPage(1)}
                                        />
                                    </AntForm.Item>
                                </div>
                                <div className='col-4'>
                                    <AntForm.Item name="item">
                                        <select
                                            className='form-control'
                                            defaultValue={`${itemKeyDiemDen?.formDiemDen}`}
                                            placeholder="Vui lòng chọn các mục"
                                            onChange={(e) => {
                                                const value = e.target.value.split(",")[0]
                                                const key = e.target.value.split(",")[1]
                                                setSelect(_giaTri[key].fieldSelect)
                                                list.setData([]);
                                                setS(null)
                                                setPage(1)
                                                setFormId(parseInt(value))
                                                setkeyItem(key)
                                                history.replace({
                                                    search: stringify({
                                                        ...qs,
                                                        zoom: undefined,
                                                        lat: undefined,
                                                        lng: undefined,
                                                        openPopup: undefined
                                                    })
                                                })
                                            }}
                                        >
                                            <option value={[`${itemKeyDiemDen?.formDiemDen}`, `itemKeyDiemDen`]}>{itemKeyDiemDen?.tenHienThi}</option>
                                            <option value={[`${itemKeyLuuTru?.formLuuTru}`, `itemKeyLuuTru`]}>{itemKeyLuuTru?.tenHienThi}</option>
                                            <option value={[`${itemKeyAmThuc?.formAmThuc}`, `itemKeyAmThuc`]}>{itemKeyAmThuc?.tenHienThi}</option>
                                            <option value={[`${itemKeyMuaSam?.formMuaSam}`, `itemKeyMuaSam`]}>{itemKeyMuaSam?.tenHienThi}</option>
                                            <option value={[`${itemKeyGiaiTri?.formGiaiTri}`, `itemKeyGiaiTri`]} >{itemKeyGiaiTri?.tenHienThi}</option>
                                            <option value={[`${itemKeyYTe?.formYte}`, `itemKeyYTe`]}>{itemKeyYTe?.tenHienThi}</option>
                                            <option value={[`${itemKeyATM?.formATM}`, `itemKeyATM`]}>{itemKeyATM?.tenHienThi}</option>
                                            <option value={[`${itemKeyXe?.formXe}`, `itemKeyXe`]} >{itemKeyXe?.tenHienThi}</option>
                                            <option value={[`${itemKeyVanChuyen?.formVanChuyen}`, `itemKeyVanChuyen`]}>{itemKeyVanChuyen?.tenHienThi}</option>
                                        </select>
                                    </AntForm.Item>
                                </div>
                                <div className='col-2'>
                                    <AntForm.Item>
                                        <AntButton
                                            className="btn btn-success"
                                            onClick={() => history.replace({
                                                search: stringify({
                                                    ...qs,
                                                    zoom: undefined,
                                                    lat: undefined,
                                                    lng: undefined
                                                })
                                            })}
                                            htmlType='submit'
                                            style={{
                                                width: "42px", height: "38px", backgroundColor: "#55ad0f", borderColor: "#55ad0f", cursor: "pointer", color: "#ffffff", borderRadius: "5px"
                                            }}
                                            size="large"
                                            icon={<i className="bi bi-search " />}
                                        />
                                    </AntForm.Item>
                                </div>
                            </div>
                        </AntForm>
                    </div>
                    <div className="map-item-count d-flex justify-content-between" style={{ marginBottom: "-17px" }}>
                        <p className="item-no" style={{ float: "left", fontSize: "16px", fontFamily: "Nunito, Arial" }}>Tìm thấy <b>{list.getSelectedRows()}</b> địa điểm</p>
                        <p className="view-type" style={{ float: "right", fontSize: "16px", fontFamily: "Nunito, Arial" }}>Xem kiểu
                            <a className={`${type ? 'active' : ""}`} href='#' onClick={() => {
                                setType(true)
                            }}><i className="bi bi-grid-3x3-gap" /></a>
                            <a href='#' onClick={() => {
                                setType(false)
                            }}><i className="bi bi-card-list" /></a>
                        </p>
                    </div>
                    {list.getData().length > 0 && <CommonScrollBar height="calc(100vh - 270px)" onScroll={(e) => onscroll(e, formId)}>
                        <AntList
                            bordered={false}
                            dataSource={list.getData().filter((v: any) => v.data_formbuilder)}
                            renderItem={(item: any) => type ? <DisplayList item={item} itemKey={itemKey} lang={lang} /> :
                                <DisplayGrid item={item} itemKey={itemKey} lang={lang} />}
                        />
                        {list.loading && <AntList style={{ border: "none", width: "100%" }}>
                            {[0, 1, 2].map(item => <AntList.Item key={item}>
                                <AntSkeleton
                                    className="skeleton-data-virtual"
                                    paragraph={{ rows: 3 }}
                                    active
                                />
                            </AntList.Item>)}
                        </AntList>}
                    </CommonScrollBar>}
                    {list.loading && list.getData().length === 0 && <AntList style={{ border: "none", width: "100%" }}>
                        {[0, 1, 2].map(item => <AntList.Item key={item}>
                            <AntSkeleton
                                className="skeleton-data-virtual"
                                paragraph={{ rows: 3 }}
                                active
                            />
                        </AntList.Item>)}
                    </AntList>}
                </Fragment>
            </AntCol >
        </AntRow >
    </Fragment >
};
export default Unitily;