import { combineReducers, Reducer, CombinedState } from "redux";
import dataFormBuilders, {
  formBuilders,
  formBuilder,
  formFields,
  fileSelected,
  map,
  dataRef,
  dataRefParentChild,
  DataFormBuilderProps as DataFormBuilderStateProps,
  FormWithConfigProps as FormWithConfigStateProps,
  FormBuilderProps as FormBuilderStateProps,
  FormFieldProps as FormFieldStateProps,
  FileSelectedProps as FileSelectedStateProps,
  MapProps as MapStateProps,
  DataRefProps as DataRefStateProps,
  DataRefParentChildProps as DataRefParentChildStateProps,
} from "./dataformbuilders";

export default combineReducers({
  dataFormBuilders,
  formBuilders,
  formBuilder,
  formFields,
  fileSelected,
  map,
  dataRef,
  dataRefParentChild
}) as Reducer<CombinedState<DataFormBuilderState>>;

export interface DataFormBuilderState {
  dataFormBuilders: DataFormBuilderStateProps[];
  formBuilders: FormWithConfigStateProps;
  formBuilder: FormBuilderStateProps;
  formFields: FormFieldStateProps[];
  fileSelected: FileSelectedStateProps;
  map: MapStateProps[]; 
  dataRef: DataRefStateProps[];
  dataRefParentChild: DataRefParentChildStateProps[];
}
