import { ActionProps } from "..";

export interface ItemProps {
    loaded: boolean;
    count: number;
}

const stateDefault: ItemProps = {
    loaded: false,
    count: 0,
};

const countNotification = (
    state: ItemProps = stateDefault,
    action: ActionProps
): ItemProps => {
    const type = action.type;
    switch (type) {
        case "COUNT_NOTIFICATION":
            const data = action.payload as number;
            return {
                loaded: true,
                count: data,
            };
        default:
            return state;
    }
};

export default countNotification;
