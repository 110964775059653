import React from "react";
import Component from "./component";
import DesignSider from "./design.sider";
import DesignPage from "./design.page";
import PreviewPage from "./preview.page";
import { ComponentDataProps } from "..";

const ComponentForm: ComponentDataProps = {
    title: "Lộ trình du lịch",
    ma: "travel_itinerary",
    siderLabel: DesignSider,
    pageLabel: DesignPage,
    previewPage: PreviewPage,
    component: <Component />,
    View: Component
}
export default ComponentForm;