import removeVietnameseTones from "./remove-vietnamese-tones";
import unique from "./unique";
import groupBy from "./group-by";
import countLevel from "./count-level";
import isMatch from "./is-match";
import copyToClipboard from "./copyToClipboard";
import listenAttributeChanged from "./listen-attribute-changed";
import getPageCurrentDel from "./getPageCurrentDel";
export {
    copyToClipboard,
    removeVietnameseTones,
    unique,
    groupBy,
    countLevel,
    isMatch,
    listenAttributeChanged,
    getPageCurrentDel
}