import React from "react";
import { StyleProps } from "."
const style: StyleProps = {
    container: {
        height: 100,
    },
    content: {
        height: 100,
        display: "flex",
        flexDirection: "column",
        padding: "0 2px",
    },
    row: {
        margin: 2,
        flex: 1,
        background: "#f5f5f5",
    }
}

const DesignSider = () => {
    return <div style={style.container}>
        <div style={style.content}>
            <div style={style.row}></div>
            <div style={style.row}></div>
        </div>
    </div>
}

export default DesignSider;
