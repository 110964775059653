import {
	get, post, put, del, ResponseProps
} from "../../../../.fetch"

const apiUrl: string = "/uaa/permission";
const api: string = "/uaa/group";
export interface ItemProps {
	id: number;
	name?: string;
	permission?: string;
	note?: string
	url?: string;
	isEnable?: 0 | 1;
	sort?: number;
	idParent?: number;
	type?: number;
	icon?: string;
	htmlId?: string;
	menuTop?: boolean;
	idChucNang?: string;
	children?: ItemProps[]
}

interface ListOutputProps extends Omit<ResponseProps, "result"> { result?: ItemProps[] }
interface ItemOutputProps extends Omit<ResponseProps, "result"> { result?: ItemProps[] }

interface FetchAllInputProps {
	idParent?: number;
	isTree?: boolean
}



export const fetchAll = async (data: FetchAllInputProps): Promise<ListOutputProps> => await get({
	url: apiUrl,
	data
})

export const findById = async (id: number): Promise<ItemOutputProps> => await get({
	url: `${apiUrl}/${id}`
})
export const findByGroup = async (groupCode: string) => await get({
	url: `${api}/${groupCode}`
})

export const update = async (data: ItemProps): Promise<ItemOutputProps> => await put({
	url: apiUrl,
	data
})

export const updateGroup = async (data: any) => await put({
	url: `${api}/permission`,
	data
})

export const create = async (data: ItemProps): Promise<ItemOutputProps> => await post({
	url: apiUrl,
	data
})

export const _delete = async (data: string[]) => await del({
	url: apiUrl,
	data
})

export const fetchAllByAccount = async (token?: string): Promise<ListOutputProps> => await get({
	url: `/uaa/permission/user`,
	token
})