import React, { Fragment } from "react";
import { AntLayout } from "../../../../../../../../common"

const Component = () => {
    return <Fragment>
        <AntLayout.Header>

        </AntLayout.Header>
    </Fragment>
}

export default Component;