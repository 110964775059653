import React, { Fragment } from "react";
import { getAuth, removeAuth } from "../common"
import { Modal } from "antd";
import { startLoad, DownloadProps, HOST_NAME, urlLogin, endLoad } from ".";
import freshToken from "./.refresh";

function downloadPost({
    hostName,
    url,
    data,
    showLoading = true,
    token,
    auth = true,
    controller,
    showMsg = true,
    loadingMessage,
    responseType,
    formData,
}: DownloadProps): Promise<any> {
    showLoading && startLoad(loadingMessage)
    const _token = token || getAuth().token;
    let strFileName: string = "", type: string = "";
    const remember = getAuth().remember;
    return fetch(`${hostName || HOST_NAME}${url}`, {
        method: 'POST',
        headers: {
            ...(formData ? {} : { ['Content-Type']: 'application/json' }),
            ...(auth && _token ? { 'Authorization': _token } : {})
        },
        signal: controller?.signal,
        body: formData ? formData : JSON.stringify(data || {}),
    })
        .then((response: any) => {
            showLoading && endLoad();
            switch (response.status) {
                case 200:
                    const arr = response.headers.get("Content-Disposition")?.split("filename=");
                    type = response.headers.get("Content-Type");
                    strFileName = arr && arr.length >= 2 ? arr[1] : "filename";
                    return response.blob();
                case 401:
                    if (remember) {
                        return freshToken({ hostName, callback: ({ token }) => downloadPost({ hostName, url, data, showLoading, token, auth, controller, showMsg }) })
                    }
                    return new Promise(resolve => {
                        Modal.warning({
                            title: "Phiên làm việc đã kết thúc",
                            content: "Vui lòng đăng nhập lại để tiếp tục sử dụng",
                            onOk: () => {
                                removeAuth();
                                window.location.replace(urlLogin)
                                resolve({ error: true, statusCode: response.status })
                            },
                            onCancel: () => {
                                removeAuth();
                                window.location.replace(urlLogin)
                                resolve({ error: true, statusCode: response.status })
                            },
                            okText: <Fragment><i className="fa fa-sign-in mr-5" />Đăng nhập</Fragment>
                        })
                    })
                default:
                    return { error: true, statusCode: response.status };
            }
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob], { type }));
            if (responseType !== "onlyFileInfo") {
                const link = document.createElement('a');
                link.href = url;
                link.download = strFileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                link.remove();
            }
            return {
                error: false,
                statusCode: 200,
                fileInfo: {
                    url,
                    type,
                    fileName: strFileName
                }
            };
        })
};
export default downloadPost;