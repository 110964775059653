import React, { FC, Fragment } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-camera fa-2x" />}
            message={title || "Camera 360"}
        />
    </Fragment>
}
export default DesigSider;