export const getPageCurrentDel = (
    page_current: number,
    page_size: number,
    page_total: number,
    numRowSelect: number
) => {
    let pageRangeDisplayed = 1;
    const pageTotalNew = page_total - numRowSelect;
    if (page_size && null !== page_size && page_size > 0) {
        pageRangeDisplayed = pageTotalNew / page_size;
        if (pageTotalNew % page_size > 0) {
            pageRangeDisplayed = pageTotalNew / page_size + 1;
        }
    }
    return page_current > pageRangeDisplayed ? 1 : page_current;
};
export default getPageCurrentDel;
