import React, { Fragment, useState, useEffect } from 'react';
import { AntButton, AntRow, AntCol, AntCollapse, AntForm, AntCheckbox, AntSelect, AntMessage } from '../../../../../../../../common';
import { getDataByFromIds, searchDataByFormBuiler } from '../../../../../../data-form-builder/api/index';
import { useLocation, useHistory } from "react-router-dom";
import { parse, stringify } from "qs";
import { getAll } from '../../../../../../form-builder/form/api/index';
import { useDispatch, useSelector } from "react-redux";

const PreviewPage = ({ giaTri }: any) => {
   const dispatch = useDispatch();
   const location = useLocation();
   const history = useHistory();
   const qs = parse(location.search, { ignoreQueryPrefix: true });
   const [searchDataEn, setSearchDataEn] = useState([]);
   const [searchDataVi, setSearchDataVi] = useState({
      formId: 0,
      data: []
   });
   const [dataDestinationVi, setDataDestinationVi] = useState({
      formId: 0,
      data: []
   })
   const [filterFormData, setFilterFormData] = useState([]);
   const fieldsData = useSelector((state: any) => state.modelContentField.fileds);
   const formItem = fieldsData.find((item: any) => item.form.id == searchDataVi.formId)
   const referenceField = giaTri?.filter.reference.map((value: any) => {
      return formItem?.formField.find((fv: any) => value === fv.id)
   });

   const handleFilter = () => {
      return referenceField.map((m: any, mI: any) => {
         const fieldOptionText = m ? JSON.parse(m?.fieldOptionText) : '';
         const formItemReference = fieldsData.find((item: any) => item.form.id == fieldOptionText.settings?.reference_field?.relation_ship.model[0]?.id)
         return <AntCollapse.Panel showArrow={false} header={fieldOptionText.settings?.displayName} key={m?.id}>
            {handleFilterChild(formItemReference)}
         </AntCollapse.Panel>
      })
   }
   const handleFilterChild = (fieldChild: any) => {
      return fieldChild?.formField.map((m: any) => {
         const fieldOptionText = m ? JSON.parse(m?.fieldOptionText) : '';
         return <div className="destination-checkbox">
            <AntForm.Item
               name={`${fieldOptionText.settings?.apiId}_${m.formId}`}
               valuePropName="checked"
            >
               <AntCheckbox>{fieldOptionText.settings?.displayName}</AntCheckbox>
            </AntForm.Item>
         </div>
      })
   }

   useEffect(() => {
      const fetchAllForm = async () => {
         const res = await getAll();
         if (res.status) {
            dispatch({
               type: "FIELD_LOAD",
               payload: res.result || []
            })
         }
         else {
            AntMessage.error(res.msg || "Lấy dữ liệu thất bại");
         }

      }
      fetchAllForm();
      if (giaTri && parseInt(giaTri.formId) !== 0 && parseInt(giaTri.formId)) {
         const propertiesValueVi = async () => {
            const res = await getDataByFromIds(
               {
                  localization: "vi",
                  formData: [
                     parseInt(giaTri.formId)
                  ],
               }
            );
            if (res && res.status) {
               setSearchDataVi(res.result[0]);
               setDataDestinationVi(res.result[0]);
            }
         }
         propertiesValueVi();

         const handleFilterForm = async () => {
            const res = await getDataByFromIds(
               {
                  localization: "vi",
                  formData: giaTri.filter.reference,
               }
            );
            if (res && res.status) {
               setFilterFormData(res.result[0])
            }
         }
         handleFilterForm();

         const propertiesValueEn = async () => {
            const res = await getDataByFromIds(
               {
                  localization: "en",
                  formData: [
                     parseInt(giaTri.formId)
                  ],
               }
            );
            if (res && res.status) {
               setSearchDataEn(res.result);
            }
         }
         propertiesValueEn();
      }
   }, [giaTri]);
   const handleSearch = async (value: any) => {
      const res = await searchDataByFormBuiler({
         formData: [
            parseInt(giaTri.formId)
         ],
         keyword: value,
         searchText: [giaTri.itemKey?.title],
         localization: 'vi',
      });
      if (res && res.status) {
         setSearchDataVi(res.result[0]);
      }
      if (qs && value !== '') {
         history.replace({ search: stringify({ ...qs, q: value }) })
      } else {
         history.replace({ search: stringify({ ...qs, q: undefined }) })
      }
   }

   const handleRenderTour = () => {
      return searchDataVi?.data.map((value: any) => {
         return <AntCol className="gutter-row" span={8}>
            <div
               className="destination-box-container"
               style={{ height: '255px', marginBottom: "15px", borderRadius: "6px", border: "1px solid rgb(203 203 203)" }}>
               <div>
                  <div>
                     <div
                        style={{
                           // backgroundImage: "url(" + `http://192.168.2.206/cms/cmis/document/preview/${value.data_formbuilder[giaTri?.itemKey?.image].id}` + ")",
                           borderRadius: "6px 6px 0px 0px",
                           backgroundColor: "#aaf8facc",
                           backgroundPosition: 'center',
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                           minHeight: "180px",
                           marginBottom: '4px',
                        }}
                     >
                     </div>
                  </div>
                  <div style={{ padding: "0 10px" }}>
                     <div className="destination-box-title">
                        <h2>{value.data_formbuilder[giaTri.itemKey?.title]}</h2>
                     </div>
                     <div>
                        {
                           value.data_formbuilder[giaTri.itemKey?.address] !== '' &&
                           value.data_formbuilder[giaTri.itemKey?.address] !== undefined &&
                           <div style={{ padding: '4px', borderRadius: '4px', backgroundColor: "#3fbfdf" }}>
                              <p style={{ color: "white", margin: "0px", height: "21px", overflow: "hidden" }}>{value.data_formbuilder[giaTri.itemKey?.address]}</p>
                           </div>
                        }
                     </div>
                  </div>
               </div>

               <div className="destination-box-overlay">
                  <div className="destination-box-overlay-description" >
                     <div>
                        <h2>{value.data_formbuilder[giaTri.itemKey?.title]}</h2>
                     </div>
                     <div>
                        <p>{value.data_formbuilder[giaTri.itemKey?.address]}</p>
                     </div>
                     {/* <div dangerouslySetInnerHTML={{__html:purify.sanitize(data) }} /> */}
                  </div>
                  <div className="destination-box-overlay-action"  >
                     <div className="action-button">
                        <div style={{ display: "flex" }}>
                           <AntForm.Item className="button-create" style={{ marginRight: "5px" }}>
                              <AntButton type="primary" htmlType="button">
                                 TẠO TOUR MỚI
                              </AntButton>
                           </AntForm.Item>
                           <AntForm.Item className="button-add">
                              <AntButton type="primary" htmlType="button">
                                 THÊM VÀO TOUR
                              </AntButton>
                           </AntForm.Item>
                        </div>

                        <div style={{ width: "190px" }}>
                           <AntForm.Item style={{ marginRight: "5px" }}>
                              <AntButton block type="primary" htmlType="button">
                                 CHI TIẾT
                              </AntButton>
                           </AntForm.Item>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </AntCol> || ""
      })
   }
   return <Fragment>
      <div style={{ marginTop: "15px" }}>
         <div style={{ width: "1170px", margin: "0px auto", display: "flex", }}>
            <div
               className="destination-collapse"
               style={{ width: "26%", backgroundColor: "white", minHeight: "calc(100vh)", padding: "15px" }}>
               <AntForm>
                  <AntCollapse defaultActiveKey={giaTri?.filter.reference} >
                     {handleFilter()}
                  </AntCollapse>
               </AntForm>
            </div>
            <div style={{ width: "74%", backgroundColor: "white" }}>
               <AntRow>
                  <AntCol span={24}>
                     <AntRow style={{ padding: "15px" }}>
                        <AntCol span={24}>
                           <AntRow>
                              <AntCol span={12}>
                                 <AntSelect
                                    showSearch
                                    // value={search}
                                    placeholder="Tìm kiếm"
                                    // style={this.props.style}
                                    style={{ marginBottom: "15px", width: "100%" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={(e) => handleSearch(e)}
                                    onChange={(e) => alert("Vui lòng nhấn OK để đi tới chi tiết tour!")}
                                    notFoundContent={null}
                                    options={searchDataVi?.data && searchDataVi?.data.map((k: any) => ({ value: k.data_formbuilder[giaTri.itemKey?.title], label: k.data_formbuilder[giaTri.itemKey?.title] }))}
                                 >
                                 </AntSelect>
                              </AntCol>
                           </AntRow>
                           <AntRow>
                              <AntCol span={24}>
                                 <AntRow gutter={16}>
                                    {handleRenderTour()}
                                 </AntRow>
                              </AntCol>
                           </AntRow>
                        </AntCol>
                     </AntRow>
                  </AntCol>
               </AntRow>
            </div>
         </div>
      </div>
   </Fragment>
};
export default PreviewPage;