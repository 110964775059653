import React, { Fragment,FC } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";

const DesigSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-bar-chart fa-2x" />}
            message={title||"Biểu đồ"}
        />
    </Fragment>
}
export default DesigSider;