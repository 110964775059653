import React, { FC, Fragment, useEffect, useState } from "react";
import { FCSearchProps } from "./types";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "qs";
import { useForm } from "antd/lib/form/Form";
import CommonForm from "../c-form";
import { AntButton, AntDivider, AntRow, AntCol, AntTooltip, AntDropdown, AntInput } from "../antd";

const Search: FC<FCSearchProps> = ({ search, placeholder = "Nhập nội dung tìm kiếm...", list }) => {
    const location = useLocation();
    const history = useHistory();
    const qs = parse(location.search, { ignoreQueryPrefix: true }) as { q: string, s_reload: string, [key: string]: string };
    const s_reload: number = qs.s_reload && !isNaN(parseInt(qs.s_reload, 0)) ? parseInt(qs.s_reload, 0) : 0;
    const qs_page = qs.page as string;
    const page = !qs_page ? undefined : "1";
    const [formSearch] = useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [q, setQ] = useState<string | undefined>(qs.q);
    useEffect(() => {
        !list?.getPagination() && list?.setQ(q);
    }, [q, list?.getPagination])
    const onSearch = () => {
        if (q === qs.q) {
            history.replace({ search: stringify({ ...qs, page, s_reload: s_reload + 1 }), hash: location.hash })
        }
        else {
            history.push({ search: stringify({ ...qs, page, q }), hash: location.hash })
        }
    }

    const initialValues: { [key: string]: string | undefined } = {};
    (search?.data || []).filter(item => item.type !== "divider" && item.type !== "group").map(item => {
        if (item.type !== "divider" && item.type !== "group") {
            initialValues[item.name] = qs[item.name]
        }
    })

    const layout: 'horizontal' | 'inline' | 'vertical' | undefined = search?.layout || "horizontal";
    return <Fragment>
        <AntDropdown
            trigger={["click"]}
            visible={visible}
            onVisibleChange={(v) => !v && setVisible(false)}
            overlayClassName="search-overlay-dropdown"
            overlayStyle={{ width: 400 }}
            overlay={<div>
                <AntDivider orientation="center">Tìm kiếm nâng cao</AntDivider>
                <CommonForm
                    form={formSearch}
                    {...search}
                    layout={layout}
                    data={search?.data || []}
                    initialValues={initialValues}
                    onFinish={(value: { [key: string]: string | number | undefined }) => {
                        const search: { [key: string]: string | undefined } = {};
                        Object.keys(value).map(key => {
                            search[key] = value[key] && value[key]?.toString().trim() !== "" ? `${value[key]}` : undefined;
                        })
                        setQ(decodeURIComponent(stringify(search)));
                        history.push({
                            search: stringify({
                                ...qs,
                                page,
                                ...search,
                                q: undefined,
                            }),
                            hash: location.hash
                        })
                        setVisible(false);
                    }}
                    key={JSON.stringify(initialValues)}
                >
                    <AntRow style={{ marginTop: 10 }}>
                            <AntCol span={24} style={{ textAlign: "right", padding: layout === "vertical" ? "0 10px" : undefined }}>
                                <div style={{ padding: "10px" }}>
                                    <AntButton type="primary" htmlType="submit">
                                        Tìm kiếm
                                    </AntButton>
                                </div>
                            </AntCol>
                    </AntRow>
                </CommonForm>
            </div>}
            placement="bottomLeft"
        >
            <AntInput
                key={JSON.stringify(initialValues)}
                value={q}
                onChange={e => {
                    setQ(e.target.value)
                    // searchWhenChange && history.replace({ search: stringify({ ...qs, q: e.target.value }) })
                }}
                onKeyDown={(e) => e.keyCode === 13 && onSearch()}
                placeholder={placeholder}
                allowClear
                prefix={<AntTooltip title="Tìm kiếm"><SearchOutlined style={{ cursor: "pointer" }} onClick={() => onSearch()} /></AntTooltip>}
                suffix={<Fragment>
                    {search && <AntTooltip title="Nâng cao">
                        <i
                            className="fa fa-sliders mr-5 ml-5"
                            onClick={() => setVisible(v => !v)}
                            style={{ cursor: "pointer", opacity: visible ? 0 : 1, transition: "opacity .3s" }}
                        />
                    </AntTooltip>}
                </Fragment>}
            />
        </AntDropdown>
    </Fragment>
}
export default Search;