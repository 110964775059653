import { get, post, ResponseProps } from '../../../../.fetch'
const api: string = '/cms/cauHinh';
const apifetch: string = '/cms/cauHinhs';

interface ItemProps {
    id?: number;
    giaTri?: string;
    moTa?: string;
    tenThamSo?: string;
    typeCauHinh?: string;
    isDelete?: number;
}

interface FetchAllResponseProps extends Omit<ResponseProps, 'result'> {
    result?: ItemProps[]
}

export const fetchAll = async (): Promise<FetchAllResponseProps> => await get({
    url: `${apifetch}`
})
interface SaveResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps[]
}

export const save = async (data: ItemProps): Promise<SaveResponseProps> => await post({
    url: `${api}`,
    data
})

interface FindByNameResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps
}

export const findByTenThamSo = async (tenThamSo: string): Promise<FindByNameResponseProps> => await get({
    url: `${api}/${tenThamSo}`,
})

interface FetchAllLocalesResponseProps extends Omit<ResponseProps, "result"> {
    result?: ItemProps
}
export const fetchAllLocales = async (): Promise<FetchAllLocalesResponseProps> => await get({
    url: `${api}/Locales`,
})

export const getTitlePage = async (): Promise<string> => {
    const res = await findByTenThamSo("TitlePage")
    if (res.status && res.result && res.result.giaTri) {
        return res.result.giaTri;
    }
    return "Tiêu đề trang web";
}

