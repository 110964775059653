import React, { Fragment, useEffect, useState } from "react";
import { AntMenu, AntDropdown, AntButton, AntCard } from "../../../../../../../../common";
import { useParams } from "react-router-dom";
import { getDataByFromIds } from '../../../../../../data-form-builder/api/index';

const PreviewPage = ({ giaTri }: any) => {
    const { lang } = useParams() as { lang: string };
    const [dataLinkVi, setDataLinkVi] = useState(
        {
            formId: 0,
            data: []
        }
    )
    useEffect(() => {
        const propertiesValueVi = async () => {
            const res = await getDataByFromIds(
                {
                    localization: lang || "vi",
                    formData: [
                        parseInt(giaTri.formId)
                    ],
                }
            );
            if (res && res.status) {
                setDataLinkVi(res.result[0]);
            }
        }
        propertiesValueVi();
    }, []);

    const handleMenu = () => {
        return dataLinkVi?.data.map((value: any) => {
            return <AntMenu.Item>
                <a href={value.data_formbuilder["vi_" + giaTri?.keysName?.urlName]}
                    style={{
                        display: "block",
                        color: `${giaTri.setting?.color ? giaTri.setting?.color : 'black'}`,
                        fontSize: `${giaTri.setting?.fontSize ? giaTri.setting?.fontSize : '16'}px`
                    }}>
                    {value.data_formbuilder["vi_" + giaTri?.keysName?.titleName]}
                </a>
            </AntMenu.Item>
        })
    }
    const menu = (
        <AntMenu style={{ width: "100%" }}>
            {handleMenu()}
        </AntMenu>
    );
    return <Fragment>
        <div className="site-card-border-less-wrapper" style={{ marginBottom: "10px" }}>
            <AntCard title="Liên Kết Website" bordered={false} style={{ color: "rgb(31, 105, 44)", }}>
                <div className="container-antCard">
                    <AntDropdown overlay={menu} placement="bottomCenter" arrow>
                        <AntButton style={{ width: "100%", textAlign: "inherit" }}>Liên Kết Website</AntButton>
                    </AntDropdown>
                </div>
            </AntCard>
        </div>
    </Fragment>
}
export default PreviewPage;