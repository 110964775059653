import { RouteProps } from "./../";

const routes: Array<RouteProps> = [
    {
        path: "dashboard",
        componentPath: "components/system/dashboard",

    },
    {
        path: "tro-giup",
        componentPath: "components/system/help",

    },
    {
        path: "ca-nhan",
        componentPath: "components/system/profile",

    },
    {
        path: "doi-mat-khau",
        componentPath: "components/system/modify-password",

    },
    {
        path: "thong-bao",
        componentPath: "components/system/notification",
    },
    {
        path: "he-thong",
        children: [
            {
                path: "nguoi-dung",
                children: [
                    {
                        path: ["them-moi", "cap-nhat/:username"],
                        pageTitle: "Người dùng",
                        componentPath: "components/system/account",
                    },
                ]
            },
            {
                path: "nhom-nguoi-dung",
                children: [
                    {
                        path: ["them-moi", "cap-nhat/:id", "phan-quyen/:groupcode", "thanh-vien/:id"],
                        pageTitle: "Nhóm người dùng",
                        componentPath: "components/system/account-group",
                    },
                ]
            },
            {
                path: "quyen",
                children: [
                    {
                        path: [":idCha", ":idCha/them-moi", ":idCha/cap-nhat/:id"],
                        pageTitle: "Quyền",
                        componentPath: "components/system/permission",
                    },
                ]
            },
        ]
    }
]
export default routes;