import AbortController from "abort-controller";
import { TokenProps } from "../../../common";
import { get, login as fetchLogin, LoginSSODataProps, ResponseLoginProps, ResponseProps, fetchLoginSSO } from "../../../.fetch"
export interface LoginResponseProps {
    error?: boolean;
    token?: TokenProps | null;
    accountCurrent?: any
};
interface LoginDataProps {
    username: string;
    password: string
    remember?: boolean;
}

export const login = async ({ username, password, remember }: LoginDataProps, controller?: AbortController) => {
    const res = await fetchLogin({
        data: {
            username,
            password,
            remember
        },
        controller
    });
    return {
        error: res.error,
        token: res.token
    };
}

export const checkToken = async () => await get({ url: `/accounts/current` })

export const loginSSO = async ({ code, session_state }: LoginSSODataProps, controller?: AbortController) => {
    const res = await fetchLoginSSO({
        data: {
            code,
            session_state
        },
        controller
    });
    return {
        error: (res.error && !res.status),
        token: res.token
    };
}