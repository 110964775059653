import React, { FC, Fragment } from 'react';
import { AntAlert } from '../../../../../../../../common';
import { DesignSiderProps } from "./../";

const DesignSider: FC<DesignSiderProps> = ({ title }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-wrench fa-2x" />}
            message={title || "Tiện ích"}
        />
    </Fragment>
};

export default DesignSider;