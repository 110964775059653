import React, { CSSProperties, Fragment } from 'react';
import { AntLayout } from '../../../../../../../../common';

const contentStyle: CSSProperties = {
    height: '64px',
    color: '#fff',
    lineHeight: '64px',
    textAlign: 'center',
    background: '#6ab04c',
};
const itemStyle: CSSProperties = {
    backgroundColor: "#f5f5f5",
    height: 30,
    margin: "10px 5px",
    borderRadius: 3,
    width: 80,
    display: "inline-block"
}
const DesignPage = () => {
    return <Fragment>
        <AntLayout>
            {/* <p style={contentStyle}>MenuNav</p> */}

            <AntLayout.Header style={{ background: "#fff", height: 50 }}>
                <span style={itemStyle}></span>
                <span style={itemStyle}></span>
                <span style={itemStyle}></span>
                <span style={itemStyle}></span>
            </AntLayout.Header>
        </AntLayout>
    </Fragment>
};

export default DesignPage;