import { useState } from 'react';

const useModal = () => {
    const [state, setState] = useState({
        visible: false,
        data: null,
        onCancel: () => setState(state => ({ ...state, visible: false, data: null })),
        onOk: () => setState(state => ({ ...state, visible: false, data: null })),
        onShow: (data: any) => setState(state => ({ ...state, visible: false, data }))
    })

    return state;
}

export default useModal;