import React, { Fragment } from 'react';
import { AntAlert } from '../../../../../../../../common';

const DesignSider = () => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-map-marker fa-2x" />}
            message="Thông tin sản phẩm"
        />
    </Fragment>
};
export default DesignSider;