import { useForm } from 'antd/lib/form/Form';
import { parse } from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AntAvatar, AntButton, AntComment, AntForm, AntInput, AntList, AntSpin, CommonForm, unique, useList, uuid } from '../../../../../../../../common';
import { create, getDataReferenceByDataIdWithAvatarByDataId, getDetailFormBuilder } from '../../../../../../data-form-builder/api';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const Commnent = ({ formId, name_database, folder_name, setFolder_name, itemKeyBaiViet, df, setDf, page, check, formData, setName_database, setTongBinhLuan }) => {
    const [form] = useForm();
    const username = useSelector((state) => state.auth?.accountCurrent?.name)
    const auth = useSelector((state) => state.auth?.invalid)
    const location = useLocation();
    const list = useList();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const keyUrl = check === "hardConfig" ? formData?.hardConfig : (formData?.dynamicConfig && qs[formData?.dynamicConfig]);
    const accountCurrent = useSelector((state) => state.auth.accountCurrent);
    const localizations = useSelector((state) => state.config.localizations.data).map((el) => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const [pages, setPages] = useState(1)
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";
    const local = [...localizations].find((el) => { if (el.default) return el; })
    const [uid, setUid] = useState(uuid());
    useEffect(() => {
        itemKeyBaiViet?.formBaiViet && onFetchAllDataFormBuilder();
    }, [itemKeyBaiViet?.formBaiViet, pages])

    const [load, setLoad] = useState(false)
    const onFetchAllDataFormBuilder = async () => {
        let res = {}
        let data = {
            dataId: keyUrl,
            formId: itemKeyBaiViet?.formBaiViet,
            formFiledId: itemKeyBaiViet?.baiViet,
            pagination: {
                currentPage: pages || 1,
                pageSize: page || 5,
            },
            localization: localizationSearch,
            trangThai: "1",

        }
        list.onLoading();
        res = await getDataReferenceByDataIdWithAvatarByDataId(data)
        if (res.status) {
            const KT = page ? page * pages : 5 * pages
            KT < res.result.pagination.total ? setLoad(true) : setLoad(false)
            list.setPagination({ total: res.result.pagination.total })
            list.setData(c => unique([...c, ...(res.result.data || [])], (i1, i2) => i1.id === i2.id))
            res.result.data.map((item) => setDf(item.data_formbuilder[`${itemKeyBaiViet?.baiViet}`]))
            list.onSuccess();
        } else {
            setLoad(false)
            list.onError(res.msg);
        }
    }

    useEffect(() => {
        itemKeyBaiViet?.formBaiViet && getData()
    }, [itemKeyBaiViet?.formBaiViet])

    const getData = async () => {
        list.onLoading();
        const res = await getDetailFormBuilder(Number(itemKeyBaiViet?.formBaiViet));
        if (res.status) {
            const { formField } = res.result;
            const fieldOptionTextStr = (formField || []).find((item) => item.id === itemKeyBaiViet?.baiViet)?.fieldOptionText;
            try {
                const fieldOptionText = JSON.parse(fieldOptionTextStr);
                const keys = []
                fieldOptionText.settings.reference_field.relation_ship.model.map((v) => {
                    keys.push({
                        id: v.id,
                        name: v.name,
                        data: [keyUrl]
                    })
                })
                setDf(keys)
            }
            catch (e) {
            }
            setFolder_name(res.result.form.name)
            setName_database(res.result.form.nameDatabase)
            list.onSuccess()
        }
        else {
            list.onError()
        }
    }
    const onclick = () => {
        setPages(c => c + 1)
    }
    const onfinish = async (v) => {
        const data = {
            client: "portal",
            tinh_trang: 1,
            created_date: new Date(),
            nguoi_tao: username,
            trang_thai: 1,
            form_id: itemKeyBaiViet?.formBaiViet,
            localizations: [local],
            folder_name,
            name_database,
            field_text: [itemKeyBaiViet?.binhLuan?.toString()],
            data_formbuilder: {
                [`${localizationSearch}_${itemKeyBaiViet?.baiViet}`]: df,
                [`${itemKeyBaiViet?.binhLuan}`]: v.comment,
                [`${localizationSearch}_${itemKeyBaiViet?.binhLuan}`]: v.comment,
                [`${itemKeyBaiViet?.baiViet}`]: df
            }
        }
        list.onLoading();
        const res = await create(data);
        if (res.status) {
            list.getPagination()?.total ?
                list.setPagination({ total: Number(list.getPagination()?.total) + 1 }) :
                list.setPagination({ total: 1 })
            list.setData(data => data.map(item => item.uid === uid ? { ...item, status: "success" } : item))
            setTongBinhLuan(c => c + 1)
            setUid(uuid());
            list.onSuccess()
        } else {
            list.setData(data => data.map(item => item.uid === uid ? { ...item, status: "error" } : item))
            list.onError(res.msg || "Bình luận thất bại")
        }
        form.resetFields()
    }
    return <Fragment>
        {list.getData().length ?
            <Fragment>
                <AntList
                    size="small"
                    style={{ backgroundColor: "#FFFFFF", }}
                    itemLayout="horizontal"
                    dataSource={list.getData()}
                    renderItem={item => (
                        < AntList.Item id={`cmt-${item.uid || item.id}`}>
                            <CommonForm>
                                {<AntSpin
                                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                                    spinning={item.status === "loading"}>
                                    <AntComment
                                        avatar={
                                            <AntAvatar
                                                src={item.avatar || `/static/img/avatar-default.png`}
                                                alt={item.nguoi_tao}
                                            />
                                        }
                                        author={item.nguoi_tao}
                                        datetime={new Date(item.created_date).toLocaleString()}
                                        content={item?.data_formbuilder[`${itemKeyBaiViet?.binhLuan}`]}
                                    />
                                </AntSpin>}
                                {item.status === "error" &&
                                    <AntButton
                                        htmlType="submit"
                                        onClick={() => { onfinish(item?.data_formbuilder[`${itemKeyBaiViet?.binhLuan}`]) }}
                                        type="link"
                                    >Gửi lại</AntButton>}
                            </CommonForm>
                        </AntList.Item>
                    )}
                />
                {load ? <AntList.Item>
                    <AntButton type="link" onClick={() => onclick()}>Xem thêm các bình luận </AntButton>
                </AntList.Item> : undefined}
            </Fragment> : undefined}
        {
            !auth && <AntComment
                className="mx-10"
                content={
                    <CommonForm
                        form={form}
                        onFinish={(values) => {
                            onfinish(values)
                            list.setData(c => [{
                                uid,
                                nguoi_tao: username,
                                tinh_trang: 1,
                                created_date: new Date(),
                                created_by: username,
                                trang_thai: 1,
                                form_id: formId?.toString(),
                                localizations: [local],
                                folder_name,
                                name_database,
                                field_text: [itemKeyBaiViet?.binhLuan?.toString()],
                                data_formbuilder: {
                                    [`${localizationSearch}_${itemKeyBaiViet?.baiViet}`]: df,
                                    [`${itemKeyBaiViet?.binhLuan}`]: values.comment,
                                    [`${localizationSearch}_${itemKeyBaiViet?.binhLuan}`]: values.comment,
                                    [`${itemKeyBaiViet?.baiViet}`]: df
                                },
                                avatar: accountCurrent?.avatar || `/static/img/avatar-default.png`,
                                status: "loading"
                            }, ...c])
                            document.getElementById(`cmt-${uid}`)?.scrollIntoView();
                        }}
                    >
                        <AntForm.Item name="comment"
                            rules={[
                                { validator: (_, value, callback) => callback(value && value.trim().length > 0 ? undefined : "Vui lòng nhập bình luận") }
                            ]}
                        >
                            <AntInput.TextArea placeholder="Vui lòng nhập nội dung bình luận" />
                        </AntForm.Item>
                        <AntForm.Item>
                            <AntButton
                                type="primary"
                                htmlType="submit"
                                icon={<i className="fa fa-commenting-o mr-5" />}
                            >Bình luận</AntButton>
                        </AntForm.Item>
                    </CommonForm>}
            />
        }
    </Fragment>
};

export default Commnent;