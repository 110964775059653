import React, { Fragment } from "react";

const Component = () => {
   return <Fragment>
      <div style={{ background: "green", height: 300 }}>

      </div>
   </Fragment>
}

export default Component;