import React, { CSSProperties, Fragment, useEffect, useState } from "react";
const contentStyle: CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
const PreviewPage = ({ giaTri }: any) => {
    const [values, setValues] = useState(null)

    useEffect(() => {
        if (giaTri?.htmlContent) {
            setValues(giaTri.htmlContent || "");
        } else {
            if (giaTri) {
                const values = JSON.parse(giaTri)
                setValues(values.htmlContent)
            }
        }
    }, [giaTri])

    return <Fragment>
        <div dangerouslySetInnerHTML={{ __html: values || "" }}></div>
    </Fragment>
}

export default PreviewPage;