import { ActionProps } from "..";

export interface ItemProps {
    loaded: boolean,
    id: string,
    ma: string,
    ten: string
}

const stateDefault: ItemProps = {
    loaded: false,
    id: '',
    ma: '',
    ten: ''
}

const modelDoanhNghiep = (state: ItemProps = stateDefault, action: ActionProps): ItemProps => {
    const type = action.type;
    switch (type) {
        case 'MODEL_DOANH_NGHIEP':
            const data = action.payload as ItemProps;
            return {
                loaded: true,
                id: data.id,
                ma: data.ma,
                ten: data.ten
            };
        default:
            return state;
    }
}

export default modelDoanhNghiep;