import React, { Fragment, FC } from 'react';
import { AntAlert } from '../../../../../../../../common';
import { DesignSiderProps } from "./../";

const DesignSider: FC<DesignSiderProps> = ({ title, giaTri }) => {
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-map-marker fa-2x" />}
            message={`${title || "Destinations"} ${giaTri?.layout?.title ? ` - ${giaTri.layout.title}` : ""}`}
        />
    </Fragment>
}
export default DesignSider;