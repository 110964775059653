import React, { FC, Fragment } from "react";
import { AntCol, AntImage, AntRow, AntSkeleton } from "../../../../../../../../common";
import { GiaTriProps, BoxProps } from "./preview.page";
const DesignPage = ({ giaTri = {} }: any) => {
    const _giaTri = giaTri as GiaTriProps;
    const { listBox, listcamera360 } = _giaTri;
    const boxLength = (listBox || []).filter(item => !item.hidden).length;
    const span = parseInt(`${boxLength > 0 ? 24 / boxLength : 0}`, 0)
    return <Fragment>
        <div className="px-10">
            <AntRow
                className="py-10"
                gutter={[24, 16]}
            >
                {
                    (listcamera360 || [1, 2, 3, 4]).map((item: any) => {
                        return <ItemType1 box={item} span={span} />
                    })
                }
            </AntRow>
        </div>
    </Fragment >
}
const ItemType1: FC<{ box: BoxProps, span: number }> = ({ box, span }) => {
    return <AntCol md={{ span: 6 }} sm={{ span: 8 }} xs={{ span: 12 }}>
        <div style={{ height: '100%', textAlign: 'center', background: "white", padding: 5, backgroundColor: box.bgColor ? box.bgColor : "white" }}>
            <div>
                <AntImage
                    height='auto'
                    width="auto"
                    src={"error"}
                    preview={false}
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover', display: 'block' }}
                />
            </div>
            <div style={{ textAlign: 'center' }}>
                <AntSkeleton paragraph={{ rows: 1 }} />
            </div>
        </div>
    </AntCol >
}
export default DesignPage;