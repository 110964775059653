import React, { FC, Fragment } from "react";
import { AntAlert } from "../../../../../../../../common"
import { DesignSiderProps } from "./../";
import { GiaTriProps } from "./preview.page";

const DesigSider: FC<DesignSiderProps> = ({ title, giaTri }) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { name } = _giaTri;
    return <Fragment>
        <AntAlert
            icon={<i className="fa fa-square-o fa-2x" />}
            message={`${title || "Modal"} ${name ? ` - ${name}` : ""}`}
        />
    </Fragment>
}

export default DesigSider;