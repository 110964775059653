import React, { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import vi_VN from "antd/lib/locale-provider/vi_VN";
import moment from "moment";
import { BrowserRouter, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import routes, { flattenRoute } from "./.routes";
import { createStore } from "./reducers";
import indexReducer from "./reducers";
import { checkToken } from './.layout/.login/api';
import { getAuth, setAcc, AntConfigProvider, AntSpin, useBrowserContextCommunication } from './common';
import 'moment/locale/vi';
import "./.static/scss/size.scss";
import "./.static/scss/theme-menu.scss";
import "./.static/scss/theme-header.scss";
import "./.static/scss/theme.scss";
import "./.static/scss/form.scss";
import "./.static/scss/graphcms.scss";
import { fetchAll } from './components/builder/settings/api';
import { fetchAllByAccount } from './components/system/permission/api';

moment.locale('vi');

const Layout = lazy(() => import(`./.layout`))
const View = lazy(() => import(`./view`))
const Login = lazy(() => import(`./.layout/`).then(({ Login }) => ({ default: Login })))
const LoginSSO = lazy(() => import(`./.layout/`).then(({ LoginSSO }) => ({ default: LoginSSO })))

const _r = flattenRoute(routes);
const RouterRender = () => {
    const dispatch = useDispatch();
    const [communicationState] = useBrowserContextCommunication("session");
    const auth = useSelector(state => state.auth);
    useEffect(() => {
        if (communicationState.lastMessage) {
            const { data, callback } = communicationState.lastMessage;
            if (data.uuid !== auth.uuid) {
                dispatch({ type: "AUTH_CHANGED", payload: data })
                callback && callback();
            }
        }
    }, [communicationState.lastMessage, dispatch, auth.uuid])

    useEffect(() => {
        const getData = async () => {
            const res = await fetchAll();
            if (res.status) {
                let giaTriLocales = [];
                let giaTriMapProvider = [];
                let giaTriModelDoanhNghiep = {};
                let giaTriPagination = 10;
                let giaTriBaoCaoTongHop = {};
                try {
                    res.result.map(el => {
                        switch (el.tenThamSo) {
                            case "Locales":
                                giaTriLocales = JSON.parse(el.giaTri);
                                break;
                            case "MapProvider":
                                giaTriMapProvider = JSON.parse(el.giaTri);
                                break;
                            case "ModelDoanhNghiep":
                                giaTriModelDoanhNghiep = JSON.parse(el.giaTri);
                                break;
                            case "Pagination":
                                giaTriPagination = Number(el.giaTri);
                                break;
                            case "baocao_tonghop":
                                giaTriBaoCaoTongHop = JSON.parse(el.giaTri);
                                break;
                            default:
                                break;
                        }
                    })
                }
                catch (e) { }
                dispatch({
                    type: "LOCAL_LOAD",
                    payload: giaTriLocales || []
                })
                dispatch({
                    type: "MAP_PROVIDER_LOAD",
                    payload: giaTriMapProvider || []
                })
                dispatch({
                    type: "MODEL_DOANH_NGHIEP",
                    payload: giaTriModelDoanhNghiep || {}
                })
                dispatch({
                    type: "PAGINATION",
                    payload: giaTriPagination || {}
                })
                dispatch({
                    type: "CAUHINH_LOAD",
                    payload: res?.result || []
                })
                dispatch({
                    type: "BAO_CAO_TONG_HOP_CAU_HINH",
                    payload: giaTriBaoCaoTongHop || {}
                })
            }
        }
        !auth.invalid && getData();
    }, [auth.invalid])

    return <Switch>
        <Route exact path="/login-sso">
            <Suspense fallback={<Fragment>
                <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0 }}>
                    <div classname="loader loading" style={{ position: 'relative', margin: '0 auto', width: '120px', height: '100vh', display: 'flex', WebkitFlexDirection: 'column', msFlexDirection: 'column', flexDirection: 'column', WebkitFlexWrap: 'nowrap', msFlexWrap: 'nowrap', flexWrap: 'nowrap', WebkitJustifyContent: 'center', msJustifyContent: 'center', justifyContent: 'center' }}>
                        <img src=" /static/images/loader.svg" alt="Vui lòng chờ..." />
                    </div>
                </div>
            </Fragment >
            }>
                <LoginSSO />
            </Suspense>
        </Route>
        <Route exact path="/dang-nhap.html">
            {!auth.invalid && getAuth().token && <Redirect to="/" />}
            <Suspense fallback={<Fragment>
                <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0 }}>
                    <div classname="loader loading" style={{ position: 'relative', margin: '0 auto', width: '120px', height: '100vh', display: 'flex', WebkitFlexDirection: 'column', msFlexDirection: 'column', flexDirection: 'column', WebkitFlexWrap: 'nowrap', msFlexWrap: 'nowrap', flexWrap: 'nowrap', WebkitJustifyContent: 'center', msJustifyContent: 'center', justifyContent: 'center' }}>
                        <img src=" /static/images/loader.svg" alt="Vui lòng chờ..." />
                    </div>
                </div>
            </Fragment >
            }>
                <Login />
            </Suspense>
        </Route>
        <Route exact path="/quen-mat-khau.html">
            Đây là trang quên mật khẩu
        </Route>
        <Route path={["/quan-tri", "/"]} exact={false}>
            {auth.invalid ? <Redirect to="/dang-nhap.html" /> :
                <CheckInvalidToken>
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to="/quan-tri/dashboard.html" />
                        </Route>
                        {/* Route-quan-tri */}
                        <Route path="/quan-tri" exact>
                            {auth.invalid ? <Redirect to="/dang-nhap.html" /> :
                                <Suspense fallback="Loading">
                                    <Layout routes={[{
                                        path: "/quan-tri",
                                        componentPath: "components/system/home",
                                        hidePageTitle: true

                                    }]} />
                                </Suspense>
                            }
                        </Route>
                        <Route>
                            <Suspense fallback={<Fragment>
                                <AntSpin
                                    spinning
                                    indicator={<span> Loading...</span>}
                                    style={{ height: "100vh" }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: document.body.innerHTML }} />
                                </AntSpin>
                            </Fragment >
                            }>
                                <Layout routes={_r} />
                            </Suspense>
                        </Route>
                    </Switch>
                </CheckInvalidToken>}
        </Route>

        <Route path={["/:lang/:pathname", "/:pathname"]}>
            <Suspense fallback={<Fragment>
                <AntSpin
                    spinning
                    indicator={<span> Loading...</span>}
                    style={{ height: "100vh" }}
                >
                    <div dangerouslySetInnerHTML={{ __html: document.body.innerHTML }} />
                </AntSpin>
            </Fragment >
            }>
                <View />
            </Suspense>
        </Route>
    </Switch >
}

const CheckInvalidToken = ({
    children
}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const history = useHistory();
    const [checked, setChecked] = useState(auth.accountCurrent ? true : false);
    const [communicationState, postMessage] = useBrowserContextCommunication("session");
    useEffect(() => {
        const c = async () => {
            const res = await checkToken();
            if (!res.error) {
                /**
                 * 	Save account info browser
                 */
                setAcc({
                    fullName: res.fullName,
                    username: res.name,
                    avatar: res.avatar,
                });
                const resPermission = await fetchAllByAccount();
                if (resPermission.status) {
                    dispatch({
                        type: "AUTH_PERMISSION_LOAD",
                        payload: resPermission.result
                    })
                }
                dispatch({
                    type: "LOGIN_CHECKED",
                    payload: { accountCurrent: res },
                    callback: data => postMessage(data)
                });
                setChecked(true);
            }
            else {
                dispatch({
                    type: "LOGOUT",
                    callback: data => postMessage(data)
                });
            }
        }
        !auth.accountCurrent && c();
    }, [auth])

    return checked ? children : <AntSpin
        spinning
        indicator={<span> Loading...</span>}
        style={{ height: "100vh" }}
    >
        <div dangerouslySetInnerHTML={{ __html: document.body.innerHTML }} />
    </AntSpin>;
}

ReactDOM.render(<Provider store={createStore(indexReducer)} key="AUTH">
    <AntConfigProvider locale={vi_VN}>
        <BrowserRouter>
            <RouterRender />
        </BrowserRouter>
    </AntConfigProvider>
</Provider >, document.getElementById('root')
);


reportWebVitals();
