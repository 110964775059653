import {
  get,
  post,
  put,
  del,
  download,
  HOST_NAME,
  downloadPost,
} from "../../../../.fetch";

const apiSync: string = "/ss/datasyncconfiguration";
const apiSync2: string = "/cms/dataFormBuilder/reindexDataById";
const apiCreateNotificationSync: string =
  "/cms/notification/createNotificationSync";

const apiFormBuilderUrl: string = "/cms/formbuilder/getallform";
const apiDetailFormBuilderUrl: string = "/cms/formbuilder/getform";
const apiGetFormFieldsByFormIds: string =
  "/cms/formbuilder/getFormBuilderByFormIds";

const apiGetDataFormBuilderSpecifiedField: string =
  "/cms/dataFormBuilder/getDataFormBuilderSpecifiedField";
const apiGetDataReferenceByDataId: string =
  "/cms/dataFormBuilder/getDataReferenceByDataId";
const apiGetAllDataFormbuilder: string =
  "/cms/dataFormBuilder/getDataFormBuilderServiceSearch";
const apiGetDataReferenceSpecifiedFieldByFormReference: string =
  "/cms/dataFormBuilder/getDataReferenceSpecifiedFieldByFormReference";
const apiGetDetailDataFormbuilder: string = "/cms/dataFormBuilder/getDetail";
const apiGetDetailRefDataFormbuilder: string =
  "/cms/dataFormBuilder/getDataReference";
const apiDataFormBuilder: string = "/cms/dataFormBuilder/data";
const apiPheDuyet: string = "/cms/dataFormBuilder/pheduyet";
const apiGetAllDataArrayList: string =
  "/cms/dataFormBuilder/getAllDataArrayList";
const apiAddArrayList: string = "/cms/dataFormBuilder/addArrayList";
const apiEditArrayList: string = "/cms/dataFormBuilder/editArrayList";
const apiDeleteArrayList: string = "/cms/dataFormBuilder/deleteArrayList";
const apiExportDataFormbuilder: string =
  "/cms/dataFormBuilder/exportDataFormBuilderServiceSearch";
const apiGetDataFieldSelectByIds =
  "/cms/dataFormBuilder/getDataFieldSelectByIds";
const apiGetDataByFormIds = "/cms/dataFormBuilder/getDataFormBuilderByIds";
const apiGetDataByFormIds_DataIds =
  "/cms/dataFormBuilder/getDataFormBuilderByObjects";
const apiSearchDataByFormBuiler = "/cms/dataFormBuilder/searchDataByFormBuiler";

const apiUploadMultiFile: string =
  "/cms/cmis/document/createMultiDocWithFolderAuto";
const apiUploadFile: string = "/cms/cmis/document/createdocwithfolderauto";
const apiThayDoiTrangThaiFile: string = "/cms/cmis/document/thayDoiTrangThai";
const apiPreviewFile: string = "/cms/cmis/document/preview";
const apiDownloadFile: string = "/cms/cmis/document/download";
const apiGetAllFiles: string = "/cms/cmis/getalldocument";

const apiGetAllVersionContent: string =
  "/cms/content/posts/versions/dataformbuilder";

const apiGetMaChucNangByUsername: string =
  "/cms/formChucNang/getMaChucNangByUsername";

const apigetDataReferenceByDataIdWithAvatarByDataId: string =
  "/headlessCms/api/public/getDataReferenceWithAvatarByDataId";

export const reindexallbyformid = async (formid: any, data: any) =>
  await post({
    url: `${apiSync}/reindexallbyformid/${formid}`,
    data,
  });
export const reindexAllbyformidDetail = async (formid: any, data: any) => {
  return await post({
    url: `${apiSync2}/${formid}`,
    data,
  });
};
export const getAllFormbuilder = async (data?: any) =>
  await get({
    url: `${apiFormBuilderUrl}`,
    data,
  });
export const getDataReferenceByDataId = async (data: any) =>
  await post({
    url: `${apiGetDataReferenceByDataId}`,
    data,
  });
export const getAllDataFormbuilder = async (data: any) =>
  await post({
    url: `${apiGetAllDataFormbuilder}`,
    data,
  });
export const getDataFormBuilderSpecifiedField = async (data: any) =>
  await post({
    url: `${apiGetDataFormBuilderSpecifiedField}`,
    data,
  });
export const getDataReferenceSpecifiedFieldByFormReference = async (
  data: any
) =>
  await post({
    url: `${apiGetDataReferenceSpecifiedFieldByFormReference}`,
    data,
  });
export const getDetailFormBuilder = async (formId: number) =>
  await get({
    url: `${apiDetailFormBuilderUrl}/${formId}`,
  });
export const getDetailDataFormbuilder = async (data: any) =>
  await post({
    url: `${apiGetDetailDataFormbuilder}`,
    data,
  });
export const getDetailRefDataFormbuilder = async (data: any) =>
  await post({
    url: `${apiGetDetailRefDataFormbuilder}`,
    data,
  });
export const _delete = async (data: any, formId: number) =>
  await del({
    url: `${apiDataFormBuilder}/${formId}`,
    data,
  });
export const create = async (data: any) =>
  await post({
    url: `${apiDataFormBuilder}`,
    data,
  });
export const update = async (data: any) =>
  await put({
    url: `${apiDataFormBuilder}`,
    data,
  });

export const createFile = async (formData: any) =>
  await post({
    url: `${apiUploadFile}`,
    formData,
  });
export const thayDoiTrangThaiFile = async (data: any) =>
  await put({
    url: `${apiThayDoiTrangThaiFile}`,
    data,
  });
export const createFileMulti = async (formData: any) =>
  await post({
    url: `${apiUploadMultiFile}`,
    formData,
  });
export const previewFile = async (documentId: number) =>
  await get({
    url: `${apiPreviewFile}/${documentId}`,
  });
export const downloadFile = async (documentId: number) =>
  await download({
    url: `${apiDownloadFile}/${documentId}`,
  });
export const getAllFiles = async (data: any) =>
  await get({
    url: `${apiGetAllFiles}`,
    data,
  });

export const getAllVersionContent = async (documentId: string) =>
  await get({
    url: `${apiGetAllVersionContent}/${documentId}`,
  });

export const getDataReferenceByDataIdWithAvatarByDataId = async (data: any) =>
  await post({
    url: `${apigetDataReferenceByDataIdWithAvatarByDataId}`,
    data,
  });
/**
 *
 * @param data
 * @returns
 */
interface DataByFromIdsProps {
  nguoi_tao?: string;
  localization: string | "other";
  formData: number[];
}
export const getDataByFromIds = async (data: DataByFromIdsProps) =>
  await post({
    url: `${apiGetDataByFormIds}`,
    data,
  });
export const getDataFieldSelectByIds = async (data: any) =>
  await post({
    url: `${apiGetDataFieldSelectByIds}`,
    data,
  });
/**
 *
 * @param data
 * @returns
 */
interface IGetDataByFromIdsAndDataIds {
  formId: number;
  dataIds: string[];
}
export const getDataByFromIdsAndDataIds = async (
  data: IGetDataByFromIdsAndDataIds[],
  localization?: string
) =>
  await post({
    url: `${apiGetDataByFormIds_DataIds}/${localization}`,
    data,
  });

export const urlPreviewFile = (documentId: number) =>
  `${HOST_NAME}${apiPreviewFile}/${documentId}`;

export const searchDataByFormBuiler = async (data: any) =>
  await post({
    url: `${apiSearchDataByFormBuiler}`,
    data,
  });

export const getFormFieldsByFormIds = async (data: number[]) =>
  await post({
    url: `${apiGetFormFieldsByFormIds}`,
    data,
  });

export const pheDuyet = async (data: any, formId: number | string) =>
  await post({
    url: `${apiPheDuyet}/${formId}`,
    data,
  });

export const getAllDataArrayList = async (data: any) =>
  await post({
    url: `${apiGetAllDataArrayList}`,
    data,
  });
export const addArrayList = async (data: any) =>
  await post({
    url: `${apiAddArrayList}`,
    data,
  });
export const editArrayList = async (data: any) =>
  await post({
    url: `${apiEditArrayList}`,
    data,
  });
export const deleteArrayList = async (data: any) =>
  await post({
    url: `${apiDeleteArrayList}`,
    data,
  });
export const exportDataFormbuilder = async (data: any) =>
  await downloadPost({
    url: `${apiExportDataFormbuilder}`,
    data,
  });
export const runSyncFormId = async (data: any) =>
  await get({
    url: `${apiSync}/run`,
    data,
  });
export const createNotificationSync = async (data: any) =>
  await post({
    url: `${apiCreateNotificationSync}`,
    data,
  });
export const getMaChucNangByUsername = async (data: any) =>
  await get({
    url: `${apiGetMaChucNangByUsername}/${data}`,
  });

const apiGetDataSearchByKeyAndEnum =
  "/headlessCms/api/public/getDataSearchByKeyAndEnum";
export const getDataSearchByKeyAndEnum = async (data: any) =>
  await post({
    url: `${apiGetDataSearchByKeyAndEnum}`,
    data,
  });

interface InputPheDuyetALlArrayListProps {
  formId: number;
  ids: string[];
  form_field_id: number;
}
export const pheDuyetALlArrayList = async ({
  formId,
  form_field_id,
  ids,
}: InputPheDuyetALlArrayListProps) => {
  return await post({
    url: `/cms/dataFormBuilder/pheduyet/${formId}`,
    data: { form_field_id, ids },
  });
};
