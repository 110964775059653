import React, { FC, Fragment } from "react";
import ContainerDrag from "../../container.drag";
import { DesignPageProps } from "./../"
import { AntCol, AntLayout, AntRow } from "../../../../../../../../common";

const DesignPage: FC<DesignPageProps> = () => {
    return <Fragment>
        <AntRow className="px-5">
            <AntCol span={24}>
                <ContainerDrag index={0} key="header" />
            </AntCol>
        </AntRow>
        <AntRow className="py-10">
            <AntCol span={24} className="px-5">
                <AntLayout>
                    <AntLayout.Sider width={300} style={{ background: "none" }}>
                        <ContainerDrag index={1} key="row1" />
                    </AntLayout.Sider>
                    <AntLayout.Content>
                        <ContainerDrag index={2} key="row2" />
                    </AntLayout.Content>
                </AntLayout>
            </AntCol>
        </AntRow>
        <AntRow className="px-5">
            <AntCol span={24}>
                <ContainerDrag index={3} key="footer" />
            </AntCol>
        </AntRow>
    </Fragment >
}
export default DesignPage;
