
import { parse } from 'qs';
import React, { CSSProperties, FC, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AntButton, AntTabs, useEdit, AntCard } from '../../../../../../../../common';
import { getDataByFromIdsAndDataIds } from '../../../../../../data-form-builder/api';
import { Alert } from 'antd';
import { RenderProps } from '..';
import Rate from './rate';
import DataComment from './comment';
import Like from './like';
import View from './view';

const contentStyle: CSSProperties = {
    backgroundColor: "#FFFFFF",
};
const style: CSSProperties = {
    color: 'black'
};

interface FormData {
    hardConfig?: string;
    dynamicConfig?: string;
}
interface ItemKeyBaiViet {
    noiDung?: number,
    tieuDe?: number,
    moTa?: number,
    anhTieuDe?: number,
    binhLuan?: number,
    baiViet?: number,
    formBaiViet?: number,
    tongBinhLuan?: number,
    labelKey: null,
    switchKey: number
}

interface ItemKeyDanhGia {
    noiDung?: number,
    tieuDe?: number,
    moTa?: number,
    anhTieuDe?: number,
    baiViet?: number,
    danhGia?: number,
    formDanhGia?: number,
    tongDanhGia?: number,
    labelKey: null,
    switchKey: number
}
interface ItemKeyLike {
    noiDung?: number,
    tieuDe?: number,
    moTa?: number,
    anhTieuDe?: number,
    baiViet?: number,
    like?: number,
    formLike?: number,
    tongLike?: number,
    labelKey: null,
    switchKey: number
}
interface ItemKeyView {
    noiDung?: number,
    tieuDe?: number,
    moTa?: number,
    anhTieuDe?: number,
    baiViet?: number,
    view?: number,
    formView?: number,
    tongView?: number,
    labelKey: null,
    switchKey: number
}
export interface GiaTriProps {
    formData?: FormData;
    itemKeyBaiViet?: ItemKeyBaiViet;
    itemKeyDanhGia?: ItemKeyDanhGia;
    itemKeyLike?: ItemKeyLike;
    itemKeyView?: ItemKeyView;
    page?: number;
    formId?: any;
    check: "hardConfig" | "dynamicConfig";
}

const Comment: FC<RenderProps> = ({ giaTri }: any) => {
    const _giaTri: GiaTriProps = giaTri || {};
    const { itemKeyBaiViet, itemKeyDanhGia, formId, formData, page, check, itemKeyLike, itemKeyView } = _giaTri;
    const edit = useEdit();
    const [folder_name, setFolder_name] = useState(undefined)
    const [name_database, setName_database] = useState(undefined)
    const [df, setDf]: any[] = useState([])
    const auth = useSelector((state: any) => state.auth?.invalid)
    const location = useLocation();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const keyUrl = check === "hardConfig" ? formData?.hardConfig : (formData?.dynamicConfig && qs[formData?.dynamicConfig]);
    const [tongDanhGia, setTongDanhGia] = useState(0)
    const [tongBinhLuan, setTongBinhLuan] = useState(0)
    useEffect(() => {
        df && df[0] && getDataNews("vi")
    }, [df, tongDanhGia])

    const getDataNews = async (lang: string) => {
        if (df[0] && df[0].id && keyUrl) {
            const res = await getDataByFromIdsAndDataIds([{ formId: df[0].id, dataIds: [keyUrl.toString()] }], lang);
            if (res.status) {
                let data: any[] = [];
                (res.result || []).map((item: any) => { data = [...data, ...(item.data || [])] });
                if (data.length === 1) {
                    edit.onFinish(data[0]);
                }
                else {
                    edit.onError();
                }
            }
            else {
                edit.onError();
            }
        }
    }

    useEffect(() => {
        edit.data && setTongBinhLuan(edit.data.data_formbuilder[`${itemKeyBaiViet?.tongBinhLuan}`] ? parseInt(edit.data.data_formbuilder[`${itemKeyBaiViet?.tongBinhLuan}`]) : 0)
    }, [edit.data])
    useEffect(() => {
        edit.data && setTongDanhGia(edit.data.data_formbuilder[`${itemKeyDanhGia?.tongDanhGia}`] ? parseFloat(edit.data.data_formbuilder[`${itemKeyDanhGia?.tongDanhGia}`]) : 0)
    }, [edit.data])
    useEffect(() => {
        if (keyUrl && (location.hash === `#${keyUrl}-rate` || location.hash === `#${keyUrl}-cmt`)) {
            document.getElementById(`${keyUrl}-container`)?.scrollIntoView();
            if (location.hash === `#${keyUrl}-rate`) {
                setActiveKey("rate");
            }
            if (location.hash === `#${keyUrl}-cmt`) {
                setActiveKey("cmt");
            }
        }
    }, [keyUrl, location.hash])

    const [activeKey, setActiveKey] = useState("cmt");
    return <Fragment>
        <AntCard bodyStyle={{ padding: 0 }} className="mt-10">
            {auth && <Alert message="Đăng nhập để có thể thao tác" type="warning" banner className="m-10" />}
            <AntTabs
                style={contentStyle}
                id={`${keyUrl}-container`}
                activeKey={activeKey}
                onChange={(k) => (k === "cmt" || k === "rate") && setActiveKey(k)}
            >
                {/* LIKE */}
                {itemKeyLike?.switchKey === 1 && <AntTabs.TabPane
                    style={contentStyle}
                    tab={
                        <Like edit={edit} itemKeyLike={itemKeyLike} df={df} check={check} formData={formData} />
                    }
                    key="like"
                />}
                {/* Xem */}
                {itemKeyView?.switchKey === 1 && <AntTabs.TabPane
                    style={contentStyle}
                    tab={<View name_database={name_database} edit={edit} itemKeyView={itemKeyView} df={df} check={check} formData={formData} />}
                    key="view"
                />}
                {/* Comment */}
                {itemKeyBaiViet?.switchKey === 1 && <AntTabs.TabPane
                    style={contentStyle}
                    tab={<AntButton style={style} type="link" icon={<i className="fa fa-commenting-o mr-5 ml-5" />}>{tongBinhLuan} {itemKeyBaiViet?.labelKey}</AntButton>}
                    key="cmt"
                >
                    <DataComment formId={formId} name_database={name_database} folder_name={folder_name} setFolder_name={setFolder_name} setName_database={setName_database} itemKeyBaiViet={itemKeyBaiViet} df={df} setDf={setDf} page={page} check={check} formData={formData} setTongBinhLuan={setTongBinhLuan} />
                </AntTabs.TabPane>}
                {/* Rate */}
                {itemKeyDanhGia?.switchKey === 1 && <AntTabs.TabPane
                    tab={
                        <Fragment>
                            <AntButton icon={<i className="fa fa-star-half-o mr-5" />} style={style} type="link">
                                {tongDanhGia} {itemKeyDanhGia?.labelKey}
                            </AntButton>
                        </Fragment>
                    }
                    key="rate"
                >
                    <Rate itemKeyDanhGia={itemKeyDanhGia} name_database={name_database} df={df} page={page} check={check} folder_name={folder_name} formData={formData} setTongDanhGia={setTongDanhGia} />
                </AntTabs.TabPane>}
            </AntTabs>
        </AntCard >
    </Fragment >
};
export default Comment;