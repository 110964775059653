import { AccountCurrent } from ".";
import { setCookie, Base64 } from "./../";

export default (value = {}) => {
    const authString = JSON.stringify(value);
    setCookie(`app-au`, Base64.encode(authString));
}

export const acc = (value: AccountCurrent) => {
    const accString = JSON.stringify(value);
    localStorage.setItem(`app-acc`, Base64.encode(accString));
}