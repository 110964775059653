import { ReactNode } from "react";
import { ComponentRenderMa } from "../../layouts";
import CarouseData from "./carouse";
import NavigationData from "./navigation";
import Componenthtml from "./component_html";
import MenuNav from "./menu-nav";
import News from "./news";
import Footer from "./footer";
import Modal from "./modal";
import Dropdown from "./dropdown";
import View from "./view";
import Comment from "./comment";
import GroupBox from "./group-box";
import Camera360 from "./camera360";
import LinkWeb from "./link";
import TouristDestination from "./thongtinsanpham";
import Drawer from "./drawer";
import Destinations from "./destinations";
import DestinationsDetail from "./destinations_detail";
import NewsDetail from "./news_detail";
import Orders from "./orders";
import InfoPromotion from "./thongtinkhuyenmai";
import TravelItinerary from "./travel_itinerary";
import TravelItinerary_Detail from "./travel_itinerary_detail";
import Travel_CoTheBanQuanTam from "./travel_maybe_care";
import LinkIcon from "./link_icon";
import BaoCao from "./baocao";
import Unility from "./unility";
import Themmoigopy from "./themmoigopy";
import Char from "./char";
import BaoCaoKetQua from "./baocaoketqua";
import Optiondanhmuc from "./optiondanhmucloaisanpham";
import Danhmucsanpham from "./danhmucsanpham";
import Thongtindatphong from "./thongtindatphong";
import Thongtinnhanphong from "./thongtinnhanphong";
import Thongtindoankhach from "./thongtindoankhach";
import Support from "./support";
import Notification from "./notification";
import Lotrinhdiemlancan from "./lotrinhdiadiemlancan";
import ThongTinKhuyenMaiDuLich from "./thongtinkhuyenmaidulich"
import ThongTinKhuyenMaiDuLichDetail from "./thongtinkhuyenmaidulich_detail"
export interface ComponentDataProps {
  title: ReactNode;
  ma: ComponentRenderMa;
  showProperties?: boolean;
  siderLabel: any;
  pageLabel: any;
  previewPage: any;
  component: any;
  View: any;
}

export const data = [
  CarouseData,
  NavigationData,
  Componenthtml,
  MenuNav,
  News,
  Footer,
  Dropdown,
  Modal,
  View,
  Comment,
  GroupBox,
  Camera360,
  GroupBox,
  LinkWeb,
  TouristDestination,
  Drawer,
  Destinations,
  NewsDetail,
  Orders,
  InfoPromotion,
  TravelItinerary,
  TravelItinerary_Detail,
  Travel_CoTheBanQuanTam,
  Unility,
  BaoCao,
  Themmoigopy,
  Char,
  DestinationsDetail,
  BaoCaoKetQua,
  Optiondanhmuc,
  Danhmucsanpham,
  Thongtindatphong,
  Thongtinnhanphong,
  Thongtindoankhach,
  LinkIcon,
  Support,
  Notification,
  Lotrinhdiemlancan,
  ThongTinKhuyenMaiDuLich,
  ThongTinKhuyenMaiDuLichDetail
];
export interface DesignSiderProps {
  title?: ReactNode;
  giaTri?: any;
}
export interface RenderProps {
  giaTri: { [key: string]: any };
  lang: string;
}
