import React from "react";
import { StyleProps } from "."
const style: StyleProps = {
    container: {
        height: 100,
    },
    header: {
        margin: "0 2px",
        height: 23,
        background: "#f5f5f5",
        marginBottom: 2
    },
    footer: {
        margin: "0 2px",
        height: 23,
        background: "#f5f5f5"
    },
    content: {
        height: 50,
        display: "flex",
        marginBottom: 2
    },
    row1: {
        margin: 2,
        flex: 3,
        background: "#f5f5f5",
    },
    row2: {
        margin: 2,
        flex: 1,
        background: "#f5f5f5",
    }
}

const DesignSider = () => {
    return <div style={style.container}>
        <div style={style.header}></div>
        <div style={style.content}>
            <div style={style.row1}></div>
            <div style={style.row2}></div>
        </div>
        <div style={style.header}></div>
    </div>
}

export default DesignSider;
