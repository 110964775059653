import { get, post, put, del, ResponseProps } from '../../../../../.fetch'
const api: string = '/cms/formbuilder';
const apifetch: string = '/cms/cauHinhs';
const apiSync: string = '/ss/datasyncconfiguration';
const apiFunc: string = '/cms/formChucNang';
const apiAcc: string = '/accounts/';

interface ItemProps {
    id?: number;
    giaTri?: string;
    moTa?: string;
    tenThamSo?: string;
    typeCauHinh?: string;
    isDelete?: number;
}
export const create = async (data: any) => await post({
    url: `${api}/createform`,
    data,
    auth: true
})
export const edit = async (data: any) => await put({
    url: `${api}/updateform`,
    data,
    auth: true
})

export const update = async (data: any) => await put({
    url: `${api}/updateform`,
    data,
    auth: true
})

export const updateListField = async (data: any[]) => await put({
    url: `${api}/updatefieldsort`,
    data,
    auth: true
})

export const findbyId = async (id: number) => await get({
    url: `${api}/getform/${id}`,
    auth: true
})
export const getAll = async () => await get({
    url: `${api}/getallform`,
    auth: true
})
export const deleteId = async (data: number[]) => await del({
    url: `${api}/deleteform`,
    data,
    auth: true
})

const apiFormField: string = '/cms/formbuilder';
export const createFormField = async (data: any) => await post({
    url: `${apiFormField}/createfield`,
    data,
    auth: true
})
export const deleteFormField = async (data: number[]) => await del({
    url: `${apiFormField}/deletefield`,
    data,
    auth: true
})
export const editFormField = async (data: any) => await put({
    url: `${apiFormField}/updatefield`,
    data,
    auth: true
})
export const createFormFields = async (data: any) => await post({
    url: `${api}/createformfields`,
    data
})
//cauhinh
interface FetchAllResponseProps extends Omit<ResponseProps, 'result'> {
    result?: ItemProps[]
}

export const fetchAllConfig = async (): Promise<FetchAllResponseProps> => await get({
    url: `${apifetch}`
})
export const sortForm = async (data: any) =>
  await put({
    url: `${api}/updateFormSort`,
    data,
  });
export const getAllFieldKichBan = async (data: any) =>
    await get({
        url: `${api}/formFields/kichBan`,
        data,
    });
export const getFormFieldBuilders = async (data: any) =>
    await get({
        url: `${api}/getFormFieldBuilders`,
        data,
    });
export const updateTrangThaiKichBan = async (data: any) =>
    await post({
      url: `${api}/updateTrangThaiKichBan`,
      data,
    });
export const getFormFieldGroupByKichBan = async (data: any) =>
    await get({
      url: `${api}/formFields/groupByKichBan`,
      data,
    });
export const getFormMetadatas = async (data: any) =>
    await get({
        url: `${apiSync}/getmetadata`,
        data,
    });
export const findByFormIdDSC = async (data: any) =>
    await get({
        url: `${apiSync}/findbyformid/${data}`
    });
export const createFormDSC = async (data: any) =>
    await post({
      url: `${apiSync}/create`,
      data,
    });
export const getsynccodelist = async () =>
    await get({
        url: `${apiSync}/getsynccodelist`
    });
export const getFormFunc = async (data: any) =>
    await get({
        url: `${apiFunc}/getChucNangNguoiDung/${data}`
    });
export const updateChucNangNguoiDung = async (formId: number, chucNangId: number, type: string, data: any) =>
    await post({
        url: `${apiFunc}/updateChucNangNguoiDung/${formId}/${chucNangId}/${type}`,
        data,
    });
export const fetchAllAcc = async (data: any) => await get({
        url: apiAcc,
        data
    })