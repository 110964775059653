
import moment from 'moment';
import { parse } from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AntCard, AntButton, AntTable, AntSelect, AntDescriptions, AntTag, useEdit, AntAlert } from '../../../../../../../../common';
import { DateFormat } from '../../../../../../../../common/c-form';
import { getDetailDataFormbuilder } from '../../../../../../data-form-builder/api';
import { optionsBaoCao } from "./preview.page";

const Modal = ({
    formBaoCao,
    itemKeys = {}
}) => {
    const history = useHistory()
    const location = useLocation()
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const temp = optionsBaoCao.find(item => item.value === formBaoCao.idBaoCao)?.temp;
    const [data, setData] = useState({});
    const edit = useEdit();
    const localizations = useSelector(state => state.config.localizations.data).map(el => {
        el.show = el.default ? true : false;
        el.activate = el.default ? true : false;
        return el;
    });
    const localizationSearch = [...localizations].find((el) => { if (el.default) return el; })?.id || "";

    useEffect(() => {
        if (edit.data && edit.data.ketQua) {
            try {
                setData(JSON.parse(edit.data.ketQua));
            } catch (error) {
            }
        }
    }, [edit.data])

    useEffect(() => {
        formBaoCao.formId && getAllData()
    }, [formBaoCao.formId])
    const getAllData = async () => {
        const data = {
            dataId: qs.id,
            formId: formBaoCao.formId,
            localization: localizationSearch,
            trangThai: 1
        }
        edit.onStart();
        const res = await getDetailDataFormbuilder(data)
        if (res.status) {
            edit.onFinish({
                ngayLapBaoCao: res.data.data_formbuilder[`${itemKeys.ngayLapBaoCao}`],
                ketQua: res.data.data_formbuilder[`${itemKeys.ketQua}`],
                doanhNghiep: res.data.data_formbuilder[`${itemKeys.doanhNghiep}`][0].data,
                status: res.data.data_formbuilder[`uni_hidden_select_vi_${itemKeys.trangThai}`],
                id: res.data.id
            })
        } else {
            edit.onError(res.msg)
        }
    }

    const render = (colType, item, text, row, index) => {
        switch (colType) {
            case "sum":
                const sumValue = (row.sum && Array.isArray(row.sum[item.dataIndex]) ? row.sum[item.dataIndex].reduce((c = 0, v) => c + parseInt(data[v] || "0"), 0) : 0);
                sumValue !== data[`${item.dataIndex}-${index}`] && setData(d => ({
                    ...d,
                    [`${item.dataIndex}-${index}`]: sumValue
                }))
                return sumValue;
            case "input":
                return data[`${item.dataIndex}-${index}`] || 0

            case "select":
                return <AntSelect
                    style={{ width: "100%" }}
                    placeholder="Chọn quốc tịch"
                    options={[]}
                />;
            default:
                return <div style={{ fontWeight: row.bold ? "bold" : undefined, fontStyle: row.italic ? "italic" : undefined, color: row.color }}>
                    {text}
                </div>;
        }
    }
    return <AntCard
        title="BÁO CÁO KẾT QUẢ"
        extra={
            <Fragment>
                <AntButton type="default" icon={<i className="fa fa-reply mr-5" />} onClick={() => history.goBack()}> Quay lại</AntButton>
            </Fragment>
        }
    >
        {edit.loading && <AntAlert type="loading" message="Vui lòng chờ..." />}
        {edit.failed && <AntAlert type="error" message={edit.message} />}
        {edit.successed && <Fragment>
            <AntDescriptions>
                <AntDescriptions.Item label="Ngày lập báo cáo"><AntTag color="purple">{(moment(edit.data.ngayLapBaoCao).format(DateFormat))}</AntTag></AntDescriptions.Item>
                <AntDescriptions.Item label="Doanh nghiệp"> <AntTag color="green">{edit.data.doanhNghiep}</AntTag></AntDescriptions.Item>
                <AntDescriptions.Item label="Trạng thái">{edit.data.status === "Đã đồng bộ" ? <AntTag color="geekblue">{edit.data.status}</AntTag> : <AntTag color="orange">{edit.data.status}</AntTag>}</AntDescriptions.Item>
            </AntDescriptions>
            <AntTable
                bordered
                size="small"
                defaultExpandAllRows
                expandIcon={({ record }) => null}
                columns={temp && temp.data.columns.map(item => {
                    return {
                        ...item,
                        render: (text, row, index) => {
                            const colType = row.colType && row.colType[item.dataIndex];
                            return {
                                children: render(colType, item, text, row, index),
                                props: {
                                    colSpan: row.colSpan && row.colSpan[item.dataIndex],
                                },
                            };
                        }
                    }
                })}
                dataSource={temp && temp.data.body.map((item, index) => ({ ...item, index }))}
                pagination={false}
            />
        </Fragment>}
    </AntCard>
};

export default Modal;