import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import { AntCarousel } from "../../../../../../../../common";
import { getDataByFromIds } from '../../../../../../data-form-builder/api/index';
import { Link } from 'react-router-dom';
const contentStyle: CSSProperties = {
    minHeight: '160px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#364d79',
    marginBottom: '15px'
};
const PreviewPage = ({ giaTri }: any) => {
    const [dataCarouselVi, setDataCarouselVi] = useState({
        formId: 0,
        data: []
    });
    const [dataCarouselEn, setDataCarouselEn] = useState([]);
    const totalIdData: Array<string> = giaTri.dataSliders ? [...giaTri.dataSliders.nameDataVi, ...giaTri.dataSliders.nameDataEn] : [];
    const dataCarousel = totalIdData.map(value => {
        return dataCarouselVi.data.find((valueVi: any) => value == valueVi.id)
    })

    useEffect(() => {
        if (giaTri && giaTri.dataSliders && giaTri.dataSliders.nameForm) {
            const propertiesValueVi = async () => {
                const res = await getDataByFromIds(
                    {
                        localization: "vi",
                        formData: [
                            parseInt(giaTri.dataSliders.nameForm)
                        ],
                    }
                );
                if (res && res.status) {
                    setDataCarouselVi(res.result[0]);
                }
            }
            propertiesValueVi();
            const propertiesValueEn = async () => {
                const res = await getDataByFromIds(
                    {
                        localization: "en",
                        formData: [
                            parseInt(giaTri.dataSliders.nameForm)
                        ],
                    }
                );
                if (res && res.status) {
                    setDataCarouselEn(res.result);
                }
            }
            propertiesValueEn();
        }
    }, []);

    return <Fragment>
        {
            dataCarousel && dataCarousel.length > 0 ?
                <AntCarousel style={{ width: "100%" }} autoplay>
                    {
                        dataCarousel.map(
                            (itemTitle: any) => {
                                const localizationsKey = itemTitle ? itemTitle.localizations[0].id : 'vi';
                                const titleKey = giaTri.dataSliders.nameKeys[0] || '';
                                const articlesKey = giaTri.dataSliders.nameKeys[1];
                                const imageKey = giaTri.dataSliders.nameKeys[2] || '';
                                const urlKey = giaTri.dataSliders.nameKeys[3];
                                const descriptionArticles = itemTitle ? itemTitle.data_formbuilder[localizationsKey + '_' + articlesKey] ? itemTitle.data_formbuilder[localizationsKey + '_' + articlesKey] : '' : '';
                                const titleValue = itemTitle ? itemTitle.data_formbuilder[localizationsKey + '_' + titleKey] ? itemTitle.data_formbuilder[localizationsKey + '_' + titleKey] : 'không có nội dung' : 'không có nội dung';
                                const imageValue = itemTitle ? itemTitle.data_formbuilder[localizationsKey + '_' + imageKey] ? itemTitle.data_formbuilder[localizationsKey + '_' + imageKey].id : 555 : 555;
                                const urlValue = itemTitle ? itemTitle.data_formbuilder[localizationsKey + '_' + urlKey] ? itemTitle.data_formbuilder[localizationsKey + '_' + urlKey] : undefined : undefined;
                                return <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundImage: "url(" + `http://192.168.2.206/cms/cmis/document/preview/${imageValue}` + ")",
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            minHeight: "600px",
                                            marginBottom: '15px',
                                        }}
                                    >
                                        <Link to={urlValue}>
                                            <h3 style={{
                                                color: "#fff",
                                                fontSize: "30px"
                                            }}>{titleValue}
                                            </h3>
                                        </Link>
                                        <p
                                            style={{
                                                color: "#fff",
                                                fontSize: "25px"
                                            }}
                                        >{descriptionArticles}</p>
                                    </div>
                                </div>
                            }
                        )
                    }
                </AntCarousel> :
                <AntCarousel style={{ width: "100%" }} autoplay>
                    <div>
                        <div style={contentStyle}>
                            <h3 >1</h3>
                            <p>Bài Viết</p>
                        </div>
                    </div>
                    <div>
                        <div style={contentStyle}>
                            <h3 >2</h3>
                            <p>Bài Viết</p>
                        </div>
                    </div>
                    <div>
                        <div style={contentStyle}>
                            <h3 >3</h3>
                            <p>Bài Viết</p>
                        </div>
                    </div>
                    <div>
                        <div style={contentStyle}>
                            <h3 >4</h3>
                            <p>Bài Viết</p>
                        </div>
                    </div>
                </AntCarousel>
        }
    </Fragment>
}
export default PreviewPage;