import { parse, stringify } from "qs";
import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AntDrawer, AntLayout, AntMenu, AntModal } from "../../../../../../../../common";
import { DrawerKeys, drawers } from "../../forms";

interface ITitle {
    label?: string;
    hidden?: boolean
}
export interface GiaTriProps {
    name?: string;
    drawer: DrawerKeys;
    title?: ITitle;
    visible?: {
        key: string;
        value: string;
    }
}

const DesignPage = ({ giaTri = {} }: any) => {
    const { pathname, search, hash } = useLocation();
    const history = useHistory();
    const qs = parse(search, { ignoreQueryPrefix: true }) as { [key: string]: string };
    const _giaTri: GiaTriProps = giaTri || {};
    const { visible, title, drawer } = _giaTri;
    const [body, setBody] = useState<any>(null);
    useEffect(() => {
        const Body = drawers.find(f => f.key === drawer);
        setBody(Body ?
            <Body.component
                title={!title?.hidden && title?.label}
                closable={!Boolean(title?.hidden)}
                visible={Boolean(visible?.key && visible?.value && qs[visible?.key] === visible.value)}
                onClose={() => {
                    visible?.key && visible?.value && history.replace({ search: stringify({ ...qs, [visible.key]: undefined }) })
                }}
            /> :
            null);
    }, [drawer, visible, title, (visible?.key && qs[visible?.key])])
    return <Fragment>
        {
            body ?
                body :
                <AntDrawer
                    title={!title?.hidden && title?.label}
                    closable={!Boolean(title?.hidden)}
                    visible={Boolean(visible?.key && visible?.value && qs[visible?.key] === visible.value)}
                    onClose={() => {
                        visible?.key && visible?.value && history.replace({ search: stringify({ ...qs, [visible.key]: undefined }) })
                    }}
                >
                    {body}
                </AntDrawer>
        }
    </Fragment>
}

export default DesignPage;