import React, { Fragment, CSSProperties } from "react";
import { AntLayout, AntCard, CommonList, useList } from '../../../../../../../../common';
import { CommonButtonAction } from '../../../../../../../../common/c-action'
import moment from "moment";
const contentStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: "#FFFFFF"
};
export const DATE_FORMAT = "DD/MM/YYYY";
const DesignPage = () => {
    return <Fragment>
        <AntLayout style={contentStyle}>
            <AntCard>
                
            </AntCard>
        </AntLayout>
    </Fragment>
}
export default DesignPage;