import React, { Fragment } from "react";
import ContainerDrag from "../../container.drag";
import { AntCol, AntRow } from "../../../../../../../../common";

const DesignPage = () => {
    return <Fragment>
        <AntRow >
            <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <ContainerDrag index={0} key="row1" />
            </AntCol>
            <AntCol md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <ContainerDrag index={1} key="row2" />
            </AntCol>
        </AntRow>
    </Fragment>
}
export default DesignPage;
