import { ActionProps } from "..";
interface DataProps {
    ma_danh_muc: string;
    ten_danh_muc: string;
}
export interface ItemProps {
    loaded: boolean,
    data: DataProps[]
}

const stateDefault: ItemProps = {
    loaded: false,
    data: []
}

const danhMucDongBos = (state: ItemProps = stateDefault, action: ActionProps): ItemProps => {
    const type = action.type;
    switch (type) {
        case 'DONGBO_DANHMUC_LOAD':
            const data = action.payload as DataProps[];
            return {
                loaded: true,
                data
            };
        default:
            return state;
    }
}

export default danhMucDongBos;