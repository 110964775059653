import React, { Fragment, CSSProperties } from "react";
import { AntLayout, AntCard, CommonList, useList } from '../../../../../../../../common';
import { CommonButtonAction } from '../../../../../../../../common/c-action'
import moment from "moment";
const contentStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: "#FFFFFF"
};
export const DATE_FORMAT = "DD/MM/YYYY";
const DesignPage = () => {
    const list = useList()
    return <Fragment>
        <AntLayout style={contentStyle}>
        <AntCard>
                <CommonList
                    list={list}
                    columns={[
                        {
                            title: 'Tên',
                            dataIndex: 'ten',
                            ellipsis: true,
                        },
                        {
                            title: 'Trạng Thái',
                            dataIndex: 'moTa',
                            render: (t, item) => <a><i className="fa fa-check" aria-hidden="true" style={{ marginRight: 5, color: item?.status ? "" : "#a79f9f" }} />Chưa Phê Duyệt</a>
                        },
                        {
                            title: "Số điện thoại",
                            dataIndex: "soDienThoai",
                            width: 100,
                            align: "center",
                            ellipsis: true,
                        },
                        {
                            title: "Số CMND",
                            dataIndex: "soCMND",
                            width: 120,
                            align: "center",
                            ellipsis: true,
                        },
                        {
                            title: "email",
                            dataIndex: "email",
                            width: 100,
                            align: "center",
                            ellipsis: true,
                        },
                        {
                            title: "Từ ngày",
                            dataIndex: "tuNgay",
                            width: 120,
                            align: "center",
                            ellipsis: true,
                            render: (value) => moment(value).isValid() && moment(value).format(DATE_FORMAT),
                        },
                        {
                            title: "Đến ngày",
                            dataIndex: "denNgay",
                            width: 120,
                            align: "center",
                            ellipsis: true,
                            render: (value) => moment(value).isValid() && moment(value).format(DATE_FORMAT),
                        },
                        {
                            title: 'Thao tác',
                            width: 150,
                            render: (t, item) => {
                                return (
                                    <Fragment>
                                        <CommonButtonAction
                                            type="primary"
                                            shape="circle"
                                            action_tooltip="Sửa"
                                            className="mr-2"
                                        >
                                            <i className="fa fa-pencil-square-o" />
                                        </CommonButtonAction>
                                    </Fragment>
                                );
                            }
                        },
                    ]}
                />
            </AntCard>
        </AntLayout>
    </Fragment>
}
export default DesignPage;