import { parse, stringify } from "qs";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Container, Draggable } from "react-smooth-dnd";
import { AntDropdown, AntMenu, AntMessage, Base64, uuid } from "../../../../../../common";
import { RootState } from "../../../../../../reducers";
import { findPage } from "../../../../../../reducers/page-builder";
import { ComponentRenderProps, ContainerProps } from ".";
import { data } from "../components/components"

const ContainerDrag: FC<ContainerProps> = ({ index }) => {
    const location = useLocation();
    const history = useHistory();
    const qs = parse(location.search, { ignoreQueryPrefix: true });
    const [active, setActive] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);
    const [transactionCode, setTransactionCode] = useState<string>(uuid());
    const [containerId] = useState<string>(uuid());
    const dispatch = useDispatch();
    const { page_id } = useParams() as { page_id?: string };
    const pages = useSelector((state: RootState) => state.pageBuilder.pages);
    const page = findPage(pages, parseInt(`${page_id}`, 0));
    const modules = page?.pageModules || [];
    const builder = page?.history?.current?.pageBuilderContent || page?.pageBuilderContent || [];
    const _p = builder[index] || [];
    const [itemCopy, setItemCopy] = useState(null);
    const getItemCopyInLocalStorage = () => {
        const componentCopy = localStorage.getItem("component_copy");
        if (componentCopy) {
            try {
                const item = JSON.parse(Base64.decode(componentCopy));
                return item;
            }
            catch (e) { }
        }
        return null;
    }
    const findTitle = (ma?: string): string | undefined => modules.find(item => item.optionId === ma)?.name


    return <div
        className={`
            page-builder-component 
            page-builder-component-design 
            ${active ? "page-builder-component-dragging" : ""} 
            ${hover ? "page-builder-component-hover" : ""} 
            ${qs.view_component === "false" ? "" : "page-builder-component-view-component"}
        `}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        id={containerId}
    >
        <Container
            groupName="page-builder-component"
            onDrop={({ addedIndex, removedIndex, payload }) => {
                (addedIndex !== null || removedIndex !== null) && dispatch({
                    type: "PAGE_BUILDER_COMPONENT_CHANGE",
                    payload: {
                        addedIndex,
                        removedIndex,
                        index,
                        transactionCode: payload.transactionCode,
                        ma: payload.ma,
                        giaTri: payload.giaTri,
                        page_id: page?.page.id
                    }
                })
            }}
            getChildPayload={(i) => ({
                transactionCode,
                ma: _p[i]?.ma,
                giaTri: _p[i]?.giaTri
            })}
            onDragStart={() => setTransactionCode(uuid())}
            dragClass="page-builder-component-drag"
            dropPlaceholder={{
                animationDuration: 150,
                showOnTop: true,
                className: `page-builder-component-drop-preview`
            }}
            onDragEnter={() => setActive(true)}
            onDragLeave={() => setActive(false)}
            onDragEnd={() => setActive(false)}
        >
            {
                (_p || []).map((item, i) => {
                    const tempItem = data.find(d => d.ma === item.ma);
                    return <AntDropdown
                        trigger={["contextMenu"]}
                        onVisibleChange={v => {
                            if (v) {
                                setItemCopy(getItemCopyInLocalStorage());
                            }
                        }}
                        overlay={<AntMenu
                            style={{ minWidth: 200 }}
                            onClick={({ key }) => {
                                const newTransactionCode = uuid()
                                setTransactionCode(newTransactionCode);
                                switch (key) {
                                    case "properties":
                                        history.replace({ search: stringify({ ...qs, properties: item.uuid, index }) })
                                        break;
                                    case "delete":
                                        dispatch({
                                            type: "PAGE_BUILDER_COMPONENT_CHANGE",
                                            payload: {
                                                addedIndex: null,
                                                removedIndex: i,
                                                index,
                                                transactionCode: newTransactionCode,
                                                ma: item.ma,
                                                cauHinh: item.cauHinh,
                                                page_id: page?.page.id
                                            }
                                        });
                                        break;
                                    case "clone":
                                        dispatch({
                                            type: "PAGE_BUILDER_COMPONENT_CHANGE",
                                            payload: {
                                                addedIndex: i + 1,
                                                removedIndex: null,
                                                index,
                                                transactionCode: newTransactionCode,
                                                ma: item.ma,
                                                cauHinh: item.cauHinh,
                                                giaTri: item.giaTri,
                                                page_id: page?.page.id
                                            }
                                        });
                                        break;
                                    case "component_html":
                                        history.replace({ search: stringify({ ...qs, componentHtml: item.uuid, index }) })
                                        break;
                                    case "copy":
                                        localStorage.setItem("component_copy", Base64.encode(JSON.stringify({
                                            ...item,
                                            uuid: uuid(),
                                        })))
                                        AntMessage.success("Sao chép thành công");
                                        break;
                                    case "paste-down":
                                        itemCopy && dispatch({
                                            type: "PAGE_BUILDER_COMPONENT_CHANGE",
                                            payload: {
                                                addedIndex: i + 1,
                                                removedIndex: null,
                                                index,
                                                transactionCode: newTransactionCode,
                                                ...(itemCopy || {}),
                                                page_id: page?.page.id
                                            }
                                        });
                                        localStorage.removeItem("component_copy")
                                        break;
                                    case "paste-up":
                                        itemCopy && dispatch({
                                            type: "PAGE_BUILDER_COMPONENT_CHANGE",
                                            payload: {
                                                addedIndex: i,
                                                removedIndex: null,
                                                index,
                                                transactionCode: newTransactionCode,
                                                ...(itemCopy || {}),
                                                page_id: page?.page.id
                                            }
                                        });
                                        localStorage.removeItem("component_copy")
                                        break;
                                    case "replace":
                                        itemCopy && dispatch({
                                            type: "PAGE_BUILDER_COMPONENT_CHANGE",
                                            payload: {
                                                addedIndex: null,
                                                removedIndex: null,
                                                replaceIndex: i,
                                                index,
                                                transactionCode: newTransactionCode,
                                                ...(itemCopy || {}),
                                                page_id: page?.page.id
                                            }
                                        });
                                        localStorage.removeItem("component_copy")
                                        break;
                                    default:
                                        break;
                                }
                                setHover(false)
                            }}
                        >
                            <AntMenu.Item key="clone"><i className="fa fa-copy" /> Nhân đôi</AntMenu.Item>
                            <AntMenu.Item danger key="delete"><i className="fa fa-trash" /> Xoá</AntMenu.Item>
                            {item.ma === "componenthtml" ? <AntMenu.Item key="component_html"><i className="fa fa-code" /> Mã HTML</AntMenu.Item> : null}
                            <AntMenu.Divider />
                            <AntMenu.Item key="copy"><i className="fa fa-copy" /> Sao chép</AntMenu.Item>
                            <AntMenu.Item disabled={!itemCopy} key="replace"><i className="fa fa-retweet  " /> Thay thế</AntMenu.Item>
                            <AntMenu.Item disabled={!itemCopy} key="paste-up"><i className="fa fa-upload " /> Dán bên trên</AntMenu.Item>
                            <AntMenu.Item disabled={!itemCopy} key="paste-down"><i className="fa fa-download " /> Dán bên dưới</AntMenu.Item>
                            <AntMenu.Divider />
                            <AntMenu.Item disabled={tempItem?.showProperties === false} key="properties"><i className="fa fa-css3" /> Thuộc tính</AntMenu.Item>
                        </AntMenu>}
                        getPopupContainer={() => document.getElementById(containerId) || document.body}
                    >
                        <Draggable key={item.ma} className="page-builder-component-draggable">
                            <ComponentRender
                                containerId={containerId}
                                index={i}
                                layoutIndex={index}
                                ma={item.ma}
                                giaTri={item.giaTri}
                                title={findTitle(item.ma)}
                            />
                        </Draggable>
                    </AntDropdown>
                })
            }

        </Container>
    </div>
}
export default ContainerDrag;

const ComponentRender: FC<ComponentRenderProps> = ({ ma, giaTri, title, layoutIndex, index, containerId }) => {
    const item = data.find(d => d.ma === ma);
    const { search } = useLocation();
    const history = useHistory();
    const qs = parse(search, { ignoreQueryPrefix: true });
    const module_selected = (qs.module_selected || []) as string[];
    return <Fragment>
        <AntDropdown
            placement="topLeft"
            overlay={<div className="design-sider-label">{title || item?.title}</div>}
            overlayClassName="design-sider-label-overlay"
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            getPopupContainer={() => (containerId && document.getElementById(containerId)) || document.body}
            // onVisibleChange={v => console.log(v)}
        >
            <div
                onClick={(e) => {
                    if (e.ctrlKey) {
                        history.replace({
                            search: stringify({
                                ...qs,
                                module_selected: module_selected.includes(`${layoutIndex}_${index}`) ?
                                    module_selected.filter(k => k !== `${layoutIndex}_${index}`)
                                    : [...module_selected, `${layoutIndex}_${index}`]
                            })
                        })
                    }
                    else {
                        history.replace({
                            search: stringify({
                                ...qs,
                                module_selected: module_selected.includes(`${layoutIndex}_${index}`) && module_selected.length === 1 ? [] : [`${layoutIndex}_${index}`]
                            })
                        })
                    }
                }}
                className={`design-sider-label-item ${module_selected.includes(`${layoutIndex}_${index}`) ? "design-sider-label-item-selected" : ""}`}
            >
                {item && item.pageLabel && <item.siderLabel title={title} giaTri={giaTri} />}
            </div>
        </AntDropdown>
    </Fragment>
}