import React, { useEffect, useState } from "react";
import { AntMessage, AntModal, useEdit, CommonForm } from "../../../../../../../../common";
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "qs";
import { useForm } from "antd/lib/form/Form";

const UpdateOrder = ({itemUpdate}) => {
    const {search}=useLocation();
    const history = useHistory();
    const qs = parse(search,{ignoreQueryPrefix:true});
    const edit = useEdit();
    const onCancel = () => history.replace({ search: stringify({ ...qs,updateId:undefined }) })
    const [form] = useForm();
    const optionStatus = [
        {value : "CHUAXULY", label : "Chưa xử lý"},
        {value : "THANHCONG", label : "Thành công"},
        {value : "KHONGTHANHCONG", label : "Không thành công"},
        {value : "DAHUY", label : "Đã hủy"}
    ]
    useEffect( () => {
        const formatData= {
            daXoa: itemUpdate.da_xoa,
            emailNguoiLienHe: itemUpdate.email_nguoi_lien_he,
            ghiChu: itemUpdate.ghi_chu,
            id: itemUpdate._id,
            maSanPham: itemUpdate.ma_san_pham,
            moTa: itemUpdate.mo_ta,
            ngayBatDau: itemUpdate.ngay_bat_dau,
            ngayKetThuc: itemUpdate.ngay_ket_thuc,
            sdtNguoiLienHe: itemUpdate.sdt_nguoi_lien_he,
            soLuong: itemUpdate.so_luong,
            tenNguoiLienHe: itemUpdate.ten_nguoi_lien_he,
            trangThai: itemUpdate.trang_thai,
        }
        edit.onFinish(formatData)
    }, [qs.updateId])

    const onSubmit = async (values) => {
        onCancel();
    }
    return (
        <AntModal
            title="Cập nhật đơn hàng"
            visible={Boolean(qs.updateId)}
            onOk={form.submit}
            onCancel={onCancel}
            width={700}
            okButtonProps={{ icon: <i className="fa fa-save mr-5" /> }}
            cancelButtonProps={{ icon: <i className="fa fa-times mr-5" /> }}
            cancelText="Hủy"
            okText="Cập Nhật"
        >
            <CommonForm
                onFinish={onSubmit}
                form={form}
                initialValues={edit.data}
                layout="vertical"
                data={[
                {
                    col: 24,
                    name: "trangThai",
                    type: "select",
                    formItemProps: {
                        label: "Trạng thái",
                        rules: [{ required: true, message: 'Vui lòng chọn trạng thái' }]
                    },
                    fieldProps: {
                        options: optionStatus
                    }
                    
                },
                {
                    col: 24,
                    name: "ghiChu",
                    type: "textarea",
                    formItemProps: {
                        label: "Ghi chú"
                    }
                }
            ]}
            >
           </CommonForm>
        </AntModal>
    );
}
export default UpdateOrder;