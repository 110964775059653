import LoginForm from "./Login";
import RegisterForm from "./Register";
import ForgotPassword from "./ForgotPassword";
import DrawerSearch from "./DrawerSearch";
import Cart from "./Cart"

export declare type FormKeys = "login" | "register" | "forgot-password" | "cart";
export declare type DrawerKeys = "search";
interface FormProps {
    key: FormKeys;
    name: string;
    component: any
}
interface DrawerProps {
    key: DrawerKeys;
    name: string;
    component: any
}
export const forms: FormProps[] = [
    {
        key: "login",
        name: "Đăng nhập",
        component: LoginForm
    },
    {
        key: "register",
        name: "Đăng ký",
        component: RegisterForm
    },
    {
        key: "forgot-password",
        name: "Quên mật khẩu",
        component: ForgotPassword
    },
    {
        key: "cart",
        name: "Giỏ hàng",
        component: Cart
    }
]

export const drawers: DrawerProps[] = [
    {
        key: "search",
        name: "Tìm kiếm",
        component: DrawerSearch
    }
]