import React, { CSSProperties, Fragment, useEffect } from 'react';
import { AntLayout, useList, AntRow, AntSkeleton, AntCol } from '../../../../../../../../common';
const contentStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: "#FFFFFF",
};
const DesignPage = ({ giaTri }: any) => {
    return <Fragment>
        <AntLayout style={contentStyle}>
            <AntRow style={{ textAlign: "center" }}>
                <AntCol span={24}>
                    <AntSkeleton.Input active={false} size="large" style={{ width: 200 }} />
                </AntCol>
            </AntRow>
            <AntRow>
                <AntCol span={24} style={{ marginRight: "60px" }}>
                    <AntSkeleton />
                </AntCol>
            </AntRow>
        </AntLayout>
    </Fragment>
};

export default DesignPage;