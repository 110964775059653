import { LoadingOutlined } from '@ant-design/icons';
import React, { CSSProperties, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AntAlert, AntMenu, useEdit } from '../../../../../../../../common';
import { findMenuById } from '../../../../../../menu/api';

const PreviewPage = ({ giaTri }: any) => {
    const edit = useEdit()
    useEffect(() => {
        const getData = async () => {
            edit.onStart();
            if (giaTri.menuId) {
                const res = await findMenuById(giaTri.menuId)
                if (res?.result?.data?.listMenuItem) {
                    edit.onFinish(res.result.data.listMenuItem)
                } else {
                    edit.onError(res.msg || "Lỗi")
                }
            } else {
                edit.onError("Không có giá trị")
            }
        }
        getData()
    }, [giaTri])

    const dataMenu = (value: any, level: number = 1) => {
        return value.map((v: any) => v.listMenuItemChildren.length > 0 ?
            <AntMenu.SubMenu
                key={v.id}
                title={<Fragment>{v.name}{level === 1 && <i className="fa fa-caret-down ml-10" />}</Fragment>}
                popupClassName="page-menu-submenu-popup"
            >
                {dataMenu(v.listMenuItemChildren, level + 1)}
            </AntMenu.SubMenu> :
            v.url ?
                <AntMenu.Item key={v.id}   >
                    <Link to={v.url}>{v.name}</Link>
                </AntMenu.Item> :
                v.pageId ?
                    <AntMenu.Item key={v.id}>
                        <Link to={v.menuItemPage.url}>{v.name}</Link>
                    </AntMenu.Item> :
                    <AntMenu.Item key={v.id}>
                        {v.name}
                    </AntMenu.Item>
        )
    }

    return <Fragment>
        {edit.loading}
        {edit.loading && <AntAlert type='info' message='Vui lòng chờ...' icon={<LoadingOutlined />} />}
        {edit.failed && <AntAlert type='warning' message={edit.message} />}
        {edit.successed && <AntMenu mode={giaTri.general && giaTri.general.mode} className="page-menu" >
            {dataMenu(edit.data)}
        </AntMenu>}
    </Fragment>
};

export default PreviewPage;