import React, { Fragment } from "react";
import { AntRow, AntCol, AntSkeleton } from "../../../../../../../../common";
import { GiaTriProps } from "./preview.page";

const DesignPage = ({ giaTri = {} }: any) => {
  const _giaTri: GiaTriProps = giaTri || {};
  return (
    <Fragment>
      <AntRow>
        <AntCol span={15}>
          <AntSkeleton paragraph={{ rows: 3 }} loading round/>
        </AntCol>
        <AntCol span={7}></AntCol>
        <AntCol span={1}>
          <AntSkeleton paragraph={{ rows: 0 }} round loading />
        </AntCol>
        <AntCol span={1}>
          <AntSkeleton paragraph={{ rows: 0 }} round loading />
        </AntCol>
      </AntRow>
    </Fragment>
  );
};

export default DesignPage;
