import { parse, stringify } from 'qs';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HOST_NAME } from '../../../../../../../../.fetch';
import { AntList, AntRate } from '../../../../../../../../common';
import { UrlConfigRender } from '../../../../common/url-config';

const DisplayList = ({ item, itemKey, lang }) => {
    const history = useHistory()
    const { search } = useLocation();
    const qs = parse(search, { ignoreQueryPrefix: true });

    return <div className="map-item-result">
        <AntList.Item
            key={item.id}
            className='listing-map-item'
            style={{ margin: 0 }}
            onMouseEnter={() => {
                history.replace({
                    search: stringify({
                        ...qs,
                        zoom: item?.data_formbuilder[`${itemKey.toaDo}`] ? Object.values(item?.data_formbuilder[`${itemKey.toaDo}`]).length > 0 ? 16 : undefined : undefined,
                        lat: item?.data_formbuilder[`${itemKey.toaDo}`] ? Object.values(item?.data_formbuilder[`${itemKey.toaDo}`]).length > 0 && item?.data_formbuilder[`${itemKey.toaDo}`]["lat"] !== 0 ? item?.data_formbuilder[`${itemKey.toaDo}`]["lat"] : undefined : undefined,
                        lng: item?.data_formbuilder[`${itemKey.toaDo}`] ? Object.values(item?.data_formbuilder[`${itemKey.toaDo}`]).length > 0 && item?.data_formbuilder[`${itemKey.toaDo}`]["lng"] !== 0 ? item?.data_formbuilder[`${itemKey.toaDo}`]["lng"] : undefined : undefined,
                        openPopup: item?.id ? item.id : undefined
                    })
                })
            }}
        >
            <UrlConfigRender
                style={{ width: "100%" }}
                className="map-item-img"
                href={itemKey?.href}
                search={{ [itemKey?.detail || "id"]: item.id }}
            />
            <div
                className="map-item-photo img-displaylist"
                style={{ margin: 0, padding: 0, width: "100%" }} >
                <img
                    className="map-item-image "
                    style={{ objectFit: 'cover' }}
                    src={(item.data_formbuilder !== null && (item?.data_formbuilder[`${itemKey.img}`]) || item.data_formbuilder[`${lang}_${itemKey.img}`]) ? (
                        `${HOST_NAME}/headlessCms/api/public/document/preview/${(item?.data_formbuilder[`${itemKey.img}`].id || item.data_formbuilder[`${lang}_${itemKey.img}`].id)}`
                    ) : `/static/img/no-image.jpg`}
                />
            </div>
            <div className="map-item-top">
                <div className="open-status float-right">{item.trang_thai === 1 ? "Đang mở" : "Dừng hoạt động"}</div>
            </div>
            <div className="map-item-bottom">
                <p className="map-item-rating">
                    <AntRate
                        style={{ color: "#ff6a00", fontSize: 14 }}
                        disabled
                        allowHalf
                        value={item?.data_formbuilder[`${itemKey.danhGia}`] || 0}
                    />
                    ({item?.data_formbuilder[`${itemKey.danhGia}`] || 0}/5 đánh giá)
                </p>
                <h3 style={{ color: "#ffffff" }}>
                    {item.data_formbuilder[`${itemKey.ten}`] || item.data_formbuilder[`${lang}_${itemKey.ten}`]}
                </h3>
                {(item.data_formbuilder[`${itemKey.diaChi}`] || item.data_formbuilder[`${lang}_${itemKey.diaChi}`]) && <p className="map-item-address">
                    <i className="fa fa-map-marker mr-5" />
                    {item.data_formbuilder[`${itemKey.diaChi}`] || item.data_formbuilder[`${lang}_${itemKey.diaChi}`]}
                </p>}
                {(item.data_formbuilder[`${itemKey.sdt}`] || item.data_formbuilder[`${lang}_${itemKey.sdt}`]) && <p className="map-item-phone">
                    <i className="fa fa-phone mr-5" />
                    {item.data_formbuilder[`${itemKey.sdt}`] || item.data_formbuilder[`${lang}_${itemKey.sdt}`]}
                </p>}
            </div>
        </AntList.Item>
    </div >
};

export default DisplayList;